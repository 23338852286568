import { ContentState } from '../brochureware-types';

export const TermsActionTypes = {
  FETCH_TERMS_SUCCESS: 'FETCH_TERMS_SUCCESS',
  FETCH_TERMS_FAILURE: 'FETCH_TERMS_FAILURE',
};

export const TermsActions = {
  fetchTermsSuccess: (content: ContentState) => ({
    type: TermsActionTypes.FETCH_TERMS_SUCCESS,
    payload: { content },
  }),
  fetchTermsFailure: (error: ContentState) => ({
    type: TermsActionTypes.FETCH_TERMS_FAILURE,
    payload: { error },
  }),
};
