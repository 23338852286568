export const HomeActionTypes = {
  FETCH_HOME_CONTENT: 'FETCH_HOME_CONTENT',
  FETCH_HOME_CONTENT_SUCCESS: 'FETCH_HOME_CONTENT_SUCCESS',
  FETCH_HOME_CONTENT_FAILURE: 'FETCH_HOME_CONTENT_FAILURE',
};

export const HomeActions = {
  fetchHomeContent: () => ({ type: HomeActionTypes.FETCH_HOME_CONTENT }),
  fetchHomeContentSuccess: (content) => ({
    type: HomeActionTypes.FETCH_HOME_CONTENT_SUCCESS,
    payload: { content },
  }),
  fetchHomeContentFailure: (error) => ({
    type: HomeActionTypes.FETCH_HOME_CONTENT_FAILURE,
    payload: { error },
  }),
};
