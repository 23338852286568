import React from 'react';
import { get, isEmpty } from 'lodash';
import urljoin from 'url-join';
import { AEM_BASE_URL } from '../../constants/env';
import { PageMetaData } from '../../components/PageMetaData';

const aemMediaUrl = (mediaUrl: string): string => urljoin(AEM_BASE_URL, mediaUrl);

export const getAemMediaUrl = (mediaUrl: string): string =>
  !isEmpty(mediaUrl) ? aemMediaUrl(mediaUrl) : '';

export const getAemMediaUrlRaw = (mediaUrl: string): string => (!isEmpty(mediaUrl) ? mediaUrl : '');

export const anchorMarkdownConversion = (data: Record<string, string>): Record<string, string> => {
  // For performance only check html elements and content containing the anchor= format
  if (data && data.value && data[':type'] === 'text/html' && data.value.includes('anchor=')) {
    // Replace custom markdown [anchor=the-id] with <div id="the-id" />
    data.value = data.value.replace(
      /\[anchor=(.*?)]/g, // Find [anchor=$1]
      '<div id="$1" style="visibility:hidden; width:0;" aria-hidden="true"></div>' // Create div with id $1
    );
  }

  return data;
};

export const mapAemData = (data: any): any => {
  if (data && data[':items'] && data[':items'].root && data[':items'].root[':items']) {
    const contentFragments = data[':items'].root[':items'];
    const content = {};
    Object.values(contentFragments).forEach((fragments, index) => {
      Object.entries(fragments['elements']).forEach(([key, value]) => {
        return content[key]
          ? (content[`${key}_${index}`] = anchorMarkdownConversion(value as Record<string, string>))
          : (content[key] = anchorMarkdownConversion(value as Record<string, string>));
      });
    });
    return content;
  }
  return null;
};

export const mapAemDataWithAccordions = (data: any): any => {
  if (data && data[':items'] && data[':items'].root && data[':items'].root[':items']) {
    const contentFragments = data[':items'].root[':items'];
    const content = {};
    Object.values(contentFragments).forEach((fragments, index) => {
      const accordionSectionID = get(fragments, 'elements.sectionID.value');
      const isAccordionContent = !isEmpty(accordionSectionID);
      if (isAccordionContent && !content[accordionSectionID]) {
        content[accordionSectionID] = {};
      }

      Object.entries(fragments['elements']).forEach(([key, value]) => {
        if (isAccordionContent) {
          return content[accordionSectionID][key]
            ? (content[accordionSectionID][`${key}_${index}`] = anchorMarkdownConversion(
                value as Record<string, string>
              ))
            : (content[accordionSectionID][key] = anchorMarkdownConversion(
                value as Record<string, string>
              ));
        }

        return content[key]
          ? (content[`${key}_${index}`] = anchorMarkdownConversion(value as Record<string, string>))
          : (content[key] = anchorMarkdownConversion(value as Record<string, string>));
      });
    });
    return content;
  }
  return null;
};

export const mapAemHeaderData = (data: any): any => {
  const result = [];
  if (data && data[':items'] && data[':items'].root && data[':items'].root[':items']) {
    const contentFragments = data[':items'].root[':items'];

    Object.values(contentFragments).forEach((fragments) => {
      const content = {};

      Object.entries(fragments['elements']).forEach(([key, value]) => (content[key] = value));

      result.push({ contentFragment: content });
    });
  }
  return result;
};

export const mapAemDataWithKeys = (data: any): any => {
  if (data && data[':items'] && data[':items'].root && data[':items'].root[':items']) {
    const contentFragments = data[':items'].root[':items'];
    const content = {};
    Object.values(contentFragments).forEach((fragments, index) => {
      content[index] = fragments['elements'];
    });
    return content;
  }
  return null;
};

export const mapAemDataToList = (data: any): any => {
  if (data && data[':items'] && data[':items'].root && data[':items'].root[':items']) {
    const contentFragments = data[':items'].root[':items'];
    const content = [];
    Object.values(contentFragments).forEach((fragments) => {
      content.push(fragments['elements']);
    });
    return content;
  }
  return null;
};

export const renderAemMetaData = (content: any): any => {
  return (
    <PageMetaData
      title={get(content, 'pageTitle.value')}
      description={get(content, 'metaDescription.value')}
      keywords={get(content, 'metaTags.value')}
    />
  );
};

export const renderContentfulMetaData = (content: any): any => {
  return (
    <PageMetaData
      title={get(content, 'title')}
      description={get(content, 'metaDescription')}
      keywords={get(content, 'metaTags.value')}
    />
  );
};

export const renderSectionText = (keys: string[], content: Record<string, string>): JSX.Element => {
  return (
    <>
      {keys.map((key, index) => (
        <div id={get(content, `${key}firstSectionTitleAnchorText.value`)} key={index}>
          <h2>{get(content, `${key}SectionTitle.value`)}</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: get(content, `${key}SectionPara.value`),
            }}
          />
        </div>
      ))}
    </>
  );
};

export const aemAddScrollClickHandlerToLinks = (containerId: string): void => {
  try {
    const links: NodeListOf<any> = document.querySelectorAll(`#${containerId} a`);
    links.forEach((link) => {
      if (link.href.indexOf('#') === -1) return; // Prevent breaking normal href links
      const anchorElement = document.getElementById(link.href.split('#').pop());
      link.addEventListener('click', (e) => {
        e.preventDefault();
        anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    });
  } catch {
    // no catch, this is AEM data we cannot guarantee it will contain links
  }
};
