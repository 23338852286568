import gql from 'graphql-tag';
import Config from '../../config';
import { ApiCaller } from 'helpers';

const { serviceEndpoints, appId } = Config.get;

export const apiFetchWorkshop = (workshopId: string): Promise<any> => {
  const query = gql`{
                    workshop: oneWorkshop(id: "${workshopId}") {
                      id
                      name
                      description
                      address {
                        formattedAddress
                        location
                        placeId
                      }
                      openingHours {
                        day
                        openHours
                        openMinutes
                        closeHours
                        closeMinutes
                      }
                      media {
                        isTitleImage
                        mediaContent {
                          contentUrl
                        }
                      }
                      facilities {
                        id
                        facilityId
                      }
                      services {
                        id
                        jobTypeId
                        repairTypeId
                      }
                      specializations {
                        type
                        ref
                        description
                        isCertified
                        certificate {
                          documentBlobIds
                          validFrom
                          validTo
                          institute
                          areaOfAchievement
                          additionalInfo
                        }
                      }
                      cas
                    }
                 }`;
  return ApiCaller.graphQl(serviceEndpoints.graphqlApi, query, {}, 'workshop', {
    'x-app-id': appId,
  });
};

export const apiFetchAvailibleDates = (
  startDate: Date,
  endDate: Date,
  workshopId: string
): Promise<any> => {
  const query = gql`
    query GetAvailability($searchParams: WorkshopAvailabilityInputDto!) {
      getWorkshopAvailability(searchParams: $searchParams) {
        availableDates
      }
    }
  `;

  return ApiCaller.graphQl(
    serviceEndpoints.bookingGraphqlApi,
    query,
    {
      searchParams: {
        workshopId,
        startDate,
        endDate,
      },
    },
    'getWorkshopAvailability',
    {
      'x-app-id': appId,
    }
  );
};
