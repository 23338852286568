import Spinner from 'components/Spinner/Spinner';
import * as React from 'react';
import { useEffect, useState } from 'react';
import history from 'helpers/history';
import { LoadingComponentProps } from 'modules/loading/types/base-types';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Suspense } from 'react';
import routes from 'routing/app-routes';
import { BodyDiv, HeaderContainer, PageDiv } from 'styles/root-styles';
import AppContainer from './AppContainer';
import { useReducer } from 'react';
import appReducer, { initialState } from '../../../modules/app/app-hooks-reducer';
import AccountHeader from '../../Header/AccountHeader';
import Header from '../../Header';
import Footer from '../../Footer';
import CookieBanner from '../../CookieBanner/CookieBanner';
import { getLocalisedRoute } from 'constants/urls';
import AuthenticatedRoute from './AuthenticatedRoute';

const paths = (
  height: string,
  previousPath: string,
  setPreviousPath: Function
): React.ReactElement => {
  return (
    <Switch>
      {routes.map(
        ({ path, exact = true, ViewComponent, restricted, displayLayout = true, ...rest }) => {
          const page = (props: any): any => {
            if (!ViewComponent) {
              return null;
            }

            previousPath !== path;
            setPreviousPath(path);

            return (
              <>
                {displayLayout && (
                  <HeaderContainer>
                    <AccountHeader />
                    <Header />
                  </HeaderContainer>
                )}
                <PageDiv className="body-page-wrapper">
                  <BodyDiv height={height}>
                    <ViewComponent {...props} {...rest} />
                  </BodyDiv>
                </PageDiv>
                {displayLayout && <Footer />}
              </>
            );
          };

          return restricted ? (
            <AuthenticatedRoute key={path} path={path} exact={exact} render={page} />
          ) : (
            <Route key={path} path={path} exact={exact} render={page} />
          );
          // return <Route key={path} path={path} exact={exact} render={page} />;
        }
      )}
    </Switch>
  );
};

const Index: React.FC<LoadingComponentProps> = (
  props: LoadingComponentProps & RouteChildrenProps
) => {
  const [state] = useReducer(appReducer, initialState);
  const [previousPath, setPreviousPath] = useState();

  const { isLoading, fetchReferenceData } = props;
  useEffect(() => {
    fetchReferenceData();
  }, []);

  useEffect(() => {
    if (!isLoading && props.location) {
      history.push(props.location.pathname !== '/' ? props.location : getLocalisedRoute('home'));
    }
  }, [isLoading]);

  return (
    <Spinner isLoading={isLoading}>
      {isLoading ? null : (
        <ErrorBoundary>
          <Switch>
            <Suspense fallback="Loading">
              <AppContainer>
                <CookieBanner />
                <Route render={() => paths(state.headerHeight, previousPath, setPreviousPath)} />
              </AppContainer>
            </Suspense>
            <Route path="/path" component={() => <div>Page need to setup</div>} />
          </Switch>
        </ErrorBoundary>
      )}
    </Spinner>
  );
};

export default Index;
