import {
  AEM_API,
  FOOTER_ENDPOINT,
  PRIVACY_ENDPOINT,
  HOME_ENDPOINT,
  HEADER_ENDPOINT,
  SERVICES_CONTENT_ENDPOINT,
  CONTACT_US_ENDPOINT,
  IMPRESSUM_ENDPOINT,
  TERMS_ENDPOINT,
  FAQ_ENDPOINT,
  MAGAZINE_HUB_ENDPOINT,
  CONSUMER_PROMOTION_ENDPOINT,
  getLocalisedRoute,
} from 'constants/urls';

export const getHeaderEndpoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${HEADER_ENDPOINT}`;

export const getFooterEndPoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${FOOTER_ENDPOINT}`;

export const getHomeEndPoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${HOME_ENDPOINT}`;

export const getPrivacyEndPoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${PRIVACY_ENDPOINT}`;

export const getContactUsEndPoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${CONTACT_US_ENDPOINT}`;

export const getImpressumEndPoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${IMPRESSUM_ENDPOINT}`;

export const getTermsEndpoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${TERMS_ENDPOINT}`;

export const getFaqEndPoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${FAQ_ENDPOINT}`;

export const getServicesEndPoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${SERVICES_CONTENT_ENDPOINT}`;

export const getServicesEndPointByType = (serviceType: string): string => {
  return `${AEM_API}/services/services-${serviceType}.model.json`;
};

export const getMagazineHubEndpoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${MAGAZINE_HUB_ENDPOINT}`;

export const getMagazineArticleEndpoint = (languageCode: string, articleName: string): string =>
  `${AEM_API}/${languageCode}/caas${getLocalisedRoute(
    'articles'
  )}-${articleName.trim()}.model.json`;

export const getConsumerPromotionEndPoint = (languageCode: string): string =>
  `${AEM_API}/${languageCode}${CONSUMER_PROMOTION_ENDPOINT}`;
