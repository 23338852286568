import { createSelector } from 'reselect';
import { AppState } from 'store/AppState';
import { AuthState } from './types/auth-state';

export const getAuth = (state: AppState): AuthState => state.auth;

export const isUserLoggedInSf = createSelector(getAuth, (state) => {
  return state.isLoggedInSf;
});

export const isUserLoggedInCarama = createSelector(getAuth, (state) => {
  return state.isAuthenticated;
});

export const getUser = createSelector(getAuth, (state) => {
  return state.user;
});

export const getCars = createSelector(getAuth, (state) => {
  return state.user.cars;
});
