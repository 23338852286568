export const customerQueryFields = `
  customer {
    title
    firstName
    lastName
    email
    telNo
    notes
  }
`;

export const carQueryFields = `
  car {
    registration
    make
    model
    year
    variant
    hsnTsn
  }
`;

export const addressQueryFields = `
  address {
      addressLine1
      addressLine2
      addressLine3
      formattedAddress
      city
      postcode
      state
      country
      location
    }
`;

const bookingCommonFields = `
  id
  reference
  scheduledDate
`;

const couponCommonFields = `
  coupon 
  couponPercentage
`;

const bookingWorkshopQueryFields = `
  workshop {
    id
    name
    images {
      isTitleImage
      url
    }
    ${addressQueryFields}
  }
`;

const jobsQueryFields = `
  jobs {
    jobTypeId
  }
`;

export const createBookingQuery = `
  mutation Book($booking: BookingInputDto!, $marketingOptInAgreed: Boolean, $privacyPolicyAgreed: Boolean, $bookingTermsAgreed:Boolean, $recaptchaToken: String) {
    book(booking: $booking, marketingOptInAgreed: $marketingOptInAgreed, privacyPolicyAgreed: $privacyPolicyAgreed, bookingTermsAgreed: $bookingTermsAgreed, recaptchaToken: $recaptchaToken) {
      ${bookingCommonFields}
      ${couponCommonFields}
      ${bookingWorkshopQueryFields}
      ${jobsQueryFields}
      ${customerQueryFields}
      ${carQueryFields}
    }
  }
`;

export const getBookingQueryFields = `
  cancelled
  ${bookingCommonFields}
  ${couponCommonFields}
  ${bookingWorkshopQueryFields}
  ${jobsQueryFields}
  ${customerQueryFields}
  ${carQueryFields}
`;

export const getBookingQuery = `
  query GetBooking($bookingId: String!) {
    getBooking(bookingId: $bookingId) {
     ${getBookingQueryFields}
    }
  }
`;

export const cancelBookingQuery = `mutation CancelBooking ($bookingId: String!) {
  cancelBooking(bookingId: $bookingId)
}`;
