import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { getTranslations } from '../../../modules/loading/loading-selectors';
import { AppState } from 'store/AppState';

type Props = {
  children: React.ReactNode;
  translations: Record<string, string>;
};

const Body = ({ children, translations }: Props): React.ReactElement => {
  return (
    <IntlProvider locale={'en'} messages={translations}>
      <div className="page-wrapper">{children}</div>
    </IntlProvider>
  );
};

const mapStateToProps = (state: AppState): Partial<Props> => {
  return {
    translations: getTranslations(state),
  };
};

export default connect(mapStateToProps)(Body);
