import { actionTypes } from './header-actions';

const initialState = {
  contentFragments: [],
  error: {},
};

export default (state = initialState, action): any => {
  switch (action.type) {
    case actionTypes.FETCH_BUSINESS_HEADER_CONTENT_SUCCESS: {
      return {
        ...state,
        contentFragments: [...action.content],
      };
    }
    case actionTypes.FETCH_BUSINESS_HEADER_CONTENT_FAILURE: {
      return {
        ...state,
        error: { ...action.error },
      };
    }

    default:
      return state;
  }
};
