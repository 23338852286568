export const PrivacyActionTypes = {
  FETCH_PRIVACY: 'FETCH_PRIVACY',
  FETCH_PRIVACY_CONTENT_SUCCESS: 'FETCH_PRIVACY_CONTENT_SUCCESS',
  FETCH_PRIVACY_CONTENT_FAILURE: 'FETCH_PRIVACY_CONTENT_FAILURE',
};

export const PrivacyActions = {
  fetchPrivacy: () => ({
    type: PrivacyActionTypes.FETCH_PRIVACY,
  }),
  fetchPrivacySuccess: (content) => ({
    type: PrivacyActionTypes.FETCH_PRIVACY_CONTENT_SUCCESS,
    payload: { content },
  }),
  fetchPrivacyFailure: (error) => ({
    type: PrivacyActionTypes.FETCH_PRIVACY_CONTENT_FAILURE,
    payload: { error },
  }),
};
