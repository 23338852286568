import React from 'react';
import loading from 'assets/images/carama-loader.gif';
import styled from 'styled-components';
import { TranslatedText } from 'components/Translators';
import { media } from '../../styles/theme';

interface Props {
  children?: React.ReactNode;
  isLoading: boolean;
  altText?: string;
  message?: string;
}
const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 70vh;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
  }

  ${media.tablet`
    img {
      width: 10%;
    }
  `}
`;

const Spinner: React.FC<Props> = ({ children, isLoading, altText = 'Loading', message }: Props) => {
  return isLoading ? (
    <StyledImageContainer>
      <img src={loading} alt={altText} />

      {message && (
        <div>
          <TranslatedText id={message} />
        </div>
      )}
    </StyledImageContainer>
  ) : (
    <>{children}</>
  );
};

export default Spinner;
