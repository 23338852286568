export interface VehicleData {
  hsn: string;
  tsn: string;
  manufacturer: string;
  make: string;
  commercialName: string;
  allotmentDay: number;
  allotmentMonth: number;
  allotmentYear: number;
  vehicleCategory: string;
  bodyworkCode: string;
  powerSourceCode: string;
  maxNet: string;
  kWPower: string;
  maxAxles: string;
  maxPoweredAxles: string;
  maxSeats: string;
  maxMass: string;
}

export enum LookupType {
  HSN_TSN = 'HSN_TSN',
  MANUAL = 'MANUAL',
}

export interface VehicleLookupState {
  vehicleData: VehicleData;
  lookupType: LookupType;
  hsnTsn: string;
  selectedBrand: string;
  vehicleModels: string[];
  selectedModel: string;
  vehicleSeriesDetails: VehicleData[];
  error: string;
}
