import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import Config from '../../config';
import { signupActionTypes } from './signup-actions';
import { getSelectedLocale, getSelectedRegion } from 'helpers/locale';
import { SagaIterator } from '@redux-saga/types';

const { cip } = Config.get;

export function registerUser(userInfo): Promise<any> {
  return axios.post(`${cip.cipUsers}`, userInfo);
}

export function* createUser(): SagaIterator {
  yield takeEvery(signupActionTypes.SIGNUP_USER, function* (signUpData: any) {
    const { payload } = signUpData;

    try {
      const reqData = {
        ...payload.user,
        language: getSelectedLocale(),
        region: getSelectedRegion()?.toUpperCase(),
      };
      const userResponse = yield call(registerUser, reqData);

      payload.props.resetForm();
      if (payload.props.setIsSubmitted) {
        payload.props.setIsSubmitted(true);
      }

      yield put({
        type: signupActionTypes.SIGNUP_USER_SUCCESS,
        payload: userResponse,
      });
    } catch (err) {
      payload.props.setSubmitting(false);
      payload.props.setError({
        message: err.response?.data?.message,
        status: err.response?.status,
      });

      yield put({
        type: signupActionTypes.SIGNUP_USER_FAILURE,
        payload: err.response.data,
      });
    }
  });
}

export default function* SignupSaga(): SagaIterator {
  yield all([fork(createUser)]);
}
