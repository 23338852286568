import get from 'lodash/get';
import {
  COOKIE_POLICY_CONSTANTS,
  COOKIE_POLICY_KEY,
  DEFAULT_COOKIE_POLICY,
} from '../constants/general';

export const safelyParseJson = (json: string, defaultReturn: any): any => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error(e);
    return defaultReturn;
  }
};

export const getLocalStorageCookiePolicies = (): any => {
  return safelyParseJson(localStorage.getItem(COOKIE_POLICY_KEY), DEFAULT_COOKIE_POLICY);
};

export const getLocalStorageCookiePolicyByKey = (key: string): boolean => {
  if (!Object.keys(COOKIE_POLICY_CONSTANTS).includes(key)) {
    return false;
  }
  const cookieJson = getLocalStorageCookiePolicies();
  const value = get(cookieJson, key, false);
  return Boolean(value);
};

export const setLocalStorageCookiePolicyByKey = (key: string, value: boolean): void => {
  if (!Object.keys(COOKIE_POLICY_CONSTANTS).includes(key)) {
    return;
  }
  const cookieJson = getLocalStorageCookiePolicies();

  localStorage.setItem(COOKIE_POLICY_KEY, JSON.stringify({ ...cookieJson, [key]: value }));
};

export const addBodyClass = (classNames: string | Array<string>): void => {
  const classNamesArray = Array.isArray(classNames) ? classNames : [classNames];
  classNamesArray.forEach((className) => {
    document.body.classList.add(className);
  });
};

export const removeBodyClass = (classNames: string | Array<string>): void => {
  const classNamesArray = Array.isArray(classNames) ? classNames : [classNames];
  classNamesArray.forEach((className) => {
    document.body.classList.remove(className);
  });
};
