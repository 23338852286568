import { isEmpty } from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { AppState } from 'store/AppState';
import { getIpDataForUserSelectedLocale } from '../../app/app-selectors';
import { ServicesActions, ServiceTypeActions } from './services-actions';
import { getHomeServicesContent, getServicesTypeContent } from 'contentful/helpers';

export function* fetchServicesSaga(): SagaIterator {
  try {
    const state = (yield select()) as AppState;
    const servicesState = state.brochureWare.servicesContent;

    if (!isEmpty(servicesState.content)) return;

    const language = yield select(getIpDataForUserSelectedLocale);
    if (language) {
      const content = yield call(getHomeServicesContent, language);
      yield put(ServicesActions.fetchServicesSuccess(content?.items[0]?.fields));
    }
  } catch (e) {
    yield put(ServicesActions.fetchServicesFailure(e));
  }
}

export function* fetchServiceTypeSaga(serviceType: string): SagaIterator {
  try {
    const language = yield select(getIpDataForUserSelectedLocale);
    if (language) {
      const content = yield call(getServicesTypeContent, language, serviceType);
      yield put(ServiceTypeActions.fetchServiceTypeSuccess(content?.items[0]?.fields));
    }
  } catch (e) {
    yield put(ServiceTypeActions.fetchServiceTypeFailure(e));
  }
}
