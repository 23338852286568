import produce from 'immer';

import { LookupType, VehicleLookupState } from './types/vehicle-lookup-types';
import { VehicleLookupActionTypes } from './vehicle-lookup-actions';

export const initialVehicleLookupState: VehicleLookupState = {
  vehicleData: null,
  hsnTsn: null,
  lookupType: LookupType.HSN_TSN,
  selectedBrand: null,
  selectedModel: null,
  vehicleModels: [],
  vehicleSeriesDetails: [],
  error: null,
};

export default (state, action: any): VehicleLookupState =>
  produce(state, (draft) => {
    switch (action.type) {
      case VehicleLookupActionTypes.VehicleChangeLookupType: {
        draft.lookupType = action.lookupType;
        return;
      }

      case VehicleLookupActionTypes.VehicleLookUpFormFieldChange: {
        draft[action.id] = action.value;
        return;
      }

      case VehicleLookupActionTypes.VehicleLookupSetVehicleData: {
        draft.vehicleData = action.vehicleData;
        return;
      }

      case VehicleLookupActionTypes.VehicleLookupOnBrandChange: {
        if (draft.selectedBrand !== action.selectedBrand) {
          draft.selectedBrand = action.selectedBrand;
          draft.selectedModel = null;
          draft.vehicleData = null;
        }
        return;
      }

      case VehicleLookupActionTypes.VehicleLookupOnModelChange: {
        if (draft.selectedModel !== action.selectedModel) {
          draft.selectedModel = action.selectedModel;
          draft.vehicleData = null;
        }
        return;
      }

      case VehicleLookupActionTypes.VehicleLookupGetModelsSuccess: {
        draft.vehicleModels = action.vehicleModels;
        return;
      }

      case VehicleLookupActionTypes.VehicleLookupGetSeriesDetailsSuccess: {
        draft.vehicleSeriesDetails = action.vehicleSeriesDetails;
        return;
      }

      default:
        return state;
    }
  });
