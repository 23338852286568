import { connect } from 'react-redux';

import Header, { Props } from './Header';
import { getHeaderContent } from '../../modules/header/header-selectors';
import { actions } from '../../modules/header/header-actions';
import { AppState } from '../../store/AppState';

const mapStateToProps = (state: AppState): Partial<Props> => ({
  headerItems: getHeaderContent(state),
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
  fetchWebsiteHeader: () => dispatch(actions.fetchWebsiteHeader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
