import { AppState } from 'store/AppState';
import { FeatureFlags } from './feature-flag-types';
import launchDarklyHelper from './launch-darkly-helper';

function toProxy(featureFlags: FeatureFlags): FeatureFlags {
  return new Proxy(featureFlags, {
    get(target, prop, receiver) {
      const currentValue = Reflect.get(target, prop, receiver);
      if (typeof prop === 'symbol') {
        return currentValue;
      }

      launchDarklyHelper.logEvaluation(prop);

      return currentValue;
    },
    setPrototypeOf: () => false,
    set: () => false,
    defineProperty: () => false,
    deleteProperty: () => false,
    preventExtensions: () => false,
  });
}

export const featureFlagsSelector = (state: AppState): FeatureFlags => {
  return toProxy({ ...state.featureFlags.ld, ...state.featureFlags.overrides });
};
