export const signupActionTypes = {
  SIGNUP_USER: 'SIGNUP_USER',
  SIGNUP_USER_SUCCESS: 'SIGNUP_USER_SUCCESS',
  SIGNUP_USER_FAILURE: 'SIGNUP_USER_FAILURE',
};

export const signupActions = {
  signUpUser: (user, props) => {
    return {
      type: signupActionTypes.SIGNUP_USER,
      payload: { user, props },
    };
  },
};
