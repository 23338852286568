import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Grid, IconButton, Toolbar } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { Colors, Button } from '@smp/carama-ui-components';
import logo from '../../../assets/images/logo.svg';
import UserIcon from '../../../assets/images/user-icon.svg';
import MobileMenuIcon from '../../../assets/images/svgs/HamburgerNavIcon.svg';
import appReducer, { HEADER_HEIGHT, initialState } from 'modules/app/app-hooks-reducer';
import HeaderDetailsItem from './HeaderDetailsItem';
import { HeaderItem } from '../types/header-types';
import {
  AccountDropdownContainerOld,
  Drawer,
  StyledMenu,
  StyledMenuItem,
  StyledSiteSwitchButton,
} from '../header-styles';
import { getLocalisedRoute } from 'constants/urls';
import { Link } from 'react-router-dom';
import { TranslatedText, TranslateString } from 'components/Translators';
import { navigateToPage } from 'helpers/history';
import { getUser, navigateToCIPLoginPage, navigateToCIPRegistrationPage } from 'helpers/login';
import { connect, useSelector } from 'react-redux';
import authActionTypes from '../../../modules/auth/auth-actions';
import { getUser as userSelector } from 'modules/auth/auth-selectors';
import isEmpty from 'lodash/isEmpty';
import { ConsumerMenuOptions } from 'components/Pages/Consumer/ConsumerMenu';
import {
  getCustomPanel,
  getCustomPanelDetails,
  getCustomServiceSummary,
  getCustomSummary,
} from './accordion-items';
type HeaderMobileOldProps = {
  headerItems: Array<HeaderItem>;
  isAuthenticated?: boolean;
  logoutUser?: any;
};

type CustomServiceSummaryProps = {
  localisedRoute: string;
  translationTokenID: string;
  others?: any;
};

const HeaderMobileOld: React.FC<HeaderMobileOldProps> = ({
  headerItems,
  isAuthenticated,
  logoutUser,
}: HeaderMobileOldProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedPanel, setExpandedPanel] = useState('');
  const [, dispatch] = useReducer(appReducer, initialState);

  const containerDiv = useRef(null);
  const { WHITE, DARK_GREEN } = Colors;
  const CustomServiceSummary = getCustomServiceSummary();
  const CustomExpansionPanelServiceSummary: React.FC<CustomServiceSummaryProps> = ({
    localisedRoute,
    translationTokenID,
    others,
  }: CustomServiceSummaryProps) => (
    <CustomServiceSummary {...others}>
      <Link onClick={() => setOpen(!open)} to={getLocalisedRoute(localisedRoute)}>
        <div>
          <span>
            <TranslatedText id={translationTokenID} />
          </span>
        </div>
      </Link>
    </CustomServiceSummary>
  );

  const consumerIdentity = useSelector(userSelector) || getUser();

  const auth = isAuthenticated ? isAuthenticated : !isEmpty(consumerIdentity?.idToken);

  const handleDropdownClick = (event): void => setAnchorEl(event.currentTarget);

  const handleDropdownClose = (): void => setAnchorEl(null);

  useEffect(() => {
    dispatch({ type: HEADER_HEIGHT, height: containerDiv.current.clientHeight });
  }, []);

  return (
    <Grid container ref={containerDiv}>
      <Grid
        item
        xs={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-gtm-tag={open ? 'close-menu' : 'open-menu'}>
        {!isEmpty(headerItems) && (
          <Toolbar disableGutters data-gtm-tag={open ? 'close-menu' : 'open-menu'}>
            <IconButton
              data-qa={open ? 'close-menu' : 'open-menu'}
              data-gtm-tag={open ? 'close-menu' : 'open-menu'}
              color="inherit"
              aria-label="menu items"
              style={{ paddingLeft: '0.5em' }}
              onClick={() => setOpen(!open)}>
              {open ? (
                <CloseIcon
                  style={{ color: WHITE, cursor: 'pointer' }}
                  data-gtm-tag={open ? 'close-menu' : 'open-menu'}
                />
              ) : (
                <img
                  src={MobileMenuIcon}
                  title={TranslateString('img-title-tag-mobile-icon')}
                  alt={TranslateString('img-alt-tag-mobile-icon')}
                  style={{ height: '21px' }}
                  data-gtm-tag={open ? 'close-menu' : 'open-menu'}
                />
              )}
            </IconButton>
          </Toolbar>
        )}
      </Grid>
      <Grid item xs={1} md={2} />
      <Grid
        item
        xs={6}
        md={4}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <a onClick={() => navigateToPage(getLocalisedRoute('home'))} style={{ marginTop: '.3em' }}>
          <img
            src={logo}
            title={TranslateString('img-title-tag-mobile-logo')}
            alt={TranslateString('img-alt-tag-mobile-logo')}
            style={{ width: '100%', height: 'auto' }}
          />
        </a>
      </Grid>
      <Grid item xs={1} md={2} />
      {open && (
        <Drawer>
          <Grid>
            <CustomExpansionPanelServiceSummary
              localisedRoute={'services'}
              translationTokenID={'services-offered-mobile-tab'}
            />
          </Grid>
          <Grid>
            <CustomExpansionPanelServiceSummary
              localisedRoute={'articles'}
              translationTokenID={'magazine-title-tab'}
            />
          </Grid>
          {headerItems.map((headerItem) => {
            const CustomPanel = getCustomPanel();
            const CustomExpansionPanelSummary = getCustomSummary();
            const PanelDetails = getCustomPanelDetails();

            return (
              <CustomPanel
                onChange={() => {
                  setExpandedPanel(
                    expandedPanel === headerItem.itemText ? '' : headerItem.itemText
                  );
                }}
                key={headerItem.itemText}
                square
                expanded={expandedPanel === headerItem.itemText}>
                <CustomExpansionPanelSummary
                  expandIcon={
                    <ArrowDropDown
                      style={{
                        color: DARK_GREEN,
                        fontSize: '2.5rem',
                      }}
                    />
                  }>
                  <div>
                    <span style={{ color: DARK_GREEN }}>{headerItem.itemText}</span>
                  </div>
                </CustomExpansionPanelSummary>
                <PanelDetails>
                  {headerItem.children.map((child, index) => (
                    <HeaderDetailsItem key={index} onClick={() => setOpen(!open)} {...child} />
                  ))}
                </PanelDetails>
              </CustomPanel>
            );
          })}

          <Grid container>
            <Grid item xs={12} data-qa="switch-site-button">
              <StyledSiteSwitchButton disableRipple href={getLocalisedRoute('workshop')}>
                <TranslatedText id="consumer-switch-to-workshop-site" />
              </StyledSiteSwitchButton>
            </Grid>
            <Grid item xs={12} style={{ padding: 16 }}>
              <Link to={getLocalisedRoute('workshops')}>
                <Button
                  id="menu-view-workshops"
                  data-qa="menu-view-workshops"
                  data-gtm-tag="menu-view-workshops"
                  text={
                    <TranslatedText id={'menu-view-workshops'} gtmTag={'menu-view-workshops'} />
                  }
                  gtmTag="menu-view-workshops"
                  onClick={() => setOpen(!open)}
                />
              </Link>
            </Grid>
          </Grid>
        </Drawer>
      )}
      <Grid item xs={2} style={{ alignItems: 'center', display: 'flex' }}>
        {!auth && (
          <AccountDropdownContainerOld>
            <IconButton
              aria-label="current-user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDropdownClick}>
              <img
                src={UserIcon}
                onClick={handleDropdownClick}
                style={{ height: '30px' }}
                alt={TranslateString('img-alt-text-user-icon')}
                title={TranslateString('img-title-text-user-icon')}
              />
            </IconButton>
            <StyledMenu
              id="account-menu"
              anchorEl={anchorEl}
              keepMounted
              variant="menu"
              open={Boolean(anchorEl)}
              onClose={handleDropdownClose}
              aria-controls="menu-appbar"
              aria-haspopup="true">
              <StyledMenuItem
                onClick={() => {
                  handleDropdownClose();
                  navigateToCIPLoginPage();
                }}
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}>
                <TranslatedText id="header-login" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  navigateToCIPRegistrationPage();
                  handleDropdownClose();
                }}
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}>
                <TranslatedText id="header-register" />
              </StyledMenuItem>
            </StyledMenu>
          </AccountDropdownContainerOld>
        )}
        {auth && (
          <AccountDropdownContainerOld>
            <IconButton
              aria-label="current-user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDropdownClick}>
              <img
                src={UserIcon}
                onClick={handleDropdownClick}
                style={{ height: '30px' }}
                alt={TranslateString('img-alt-text-user-icon')}
                title={TranslateString('img-title-text-user-icon')}
              />
            </IconButton>
            <StyledMenu
              id="account-menu"
              anchorEl={anchorEl}
              keepMounted
              variant="menu"
              open={Boolean(anchorEl)}
              onClose={handleDropdownClose}
              aria-controls="menu-appbar"
              aria-haspopup="true">
              <StyledMenuItem
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  navigateToPage(getLocalisedRoute(ConsumerMenuOptions.profile));
                  handleDropdownClose();
                }}>
                <TranslatedText id="header-profile" />
              </StyledMenuItem>
              <StyledMenuItem
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  navigateToPage(getLocalisedRoute(ConsumerMenuOptions.myCars));
                  handleDropdownClose();
                }}>
                <TranslatedText id="header-myCars" />
              </StyledMenuItem>
              <StyledMenuItem
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  navigateToPage(getLocalisedRoute('faq'));
                  handleDropdownClose();
                }}>
                <TranslatedText id="header-help" />
              </StyledMenuItem>

              <StyledMenuItem
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  logoutUser();
                  handleDropdownClose();
                }}>
                <TranslatedText id="header-logout" />
              </StyledMenuItem>
            </StyledMenu>
          </AccountDropdownContainerOld>
        )}
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps: any = (dispatch) => ({
  logoutUser: () => dispatch(authActionTypes.logOutUser()),
});

const mapStateToProps: any = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobileOld);
