/* eslint-disable no-unused-expressions*/
import axios from 'axios/index';

import store from '../store/configureStore';
import { AppActions } from '../modules/app/actions';
import { DocumentNode } from 'graphql';
import { AxiosRequestConfig } from 'axios';
import { getUser } from './login';
import authActionTypes from 'modules/auth/auth-actions';

const getHeaders = (header = {}): any => {
  const consumerIdentity = getUser();

  if (consumerIdentity) {
    return {
      headers: {
        Authorization: `Bearer ${consumerIdentity.idToken}`,
        ...header,
      },
    };
  }

  return { headers: { ...header } };
};
const checkIdToken = (headers: any): void => {
  const identity = getUser() || {};
  let newIdentity = { ...identity };
  if (
    headers['x-new-user-bearer-token'] &&
    headers['x-new-user-bearer-token'] !== identity.idToken
  ) {
    newIdentity = { ...identity, idToken: headers['x-new-user-bearer-token'] };
    localStorage.setItem('consumerIdentity', JSON.stringify(newIdentity));
    store.dispatch(authActionTypes.loginSuccess(newIdentity));
  }
};

const get: any = async (url, config: AxiosRequestConfig = {}, showError = true) => {
  if (config?.headers) config.headers = { ...config.headers, ...getHeaders() };
  else config = getHeaders();
  try {
    const response = await axios.get(url, config);
    checkIdToken(response.headers);
    return response.data;
  } catch (err) {
    if (showError) store.dispatch(AppActions.notifyApiError(err?.response?.data));
    throw err;
  }
};

const post: any = async (url, body, showError = true, header = {}) => {
  const headers = getHeaders(header);
  try {
    const response = await axios.post(url, body, headers);
    checkIdToken(response.headers);
    return response.data;
  } catch (err) {
    if (showError) store.dispatch(AppActions.notifyApiError(err?.response?.data));
    throw err;
  }
};

const put: any = async (url, body) => {
  try {
    const response = await axios.put(url, body);
    checkIdToken(response.headers);
    return response.data;
  } catch (err) {
    store.dispatch(AppActions.notifyApiError(err?.response?.data));
    throw err;
  }
};

const patch: any = async (url, body) => {
  try {
    const response = await axios.patch(url, body, getHeaders());
    checkIdToken(response.headers);
    return response.data;
  } catch (err) {
    store.dispatch(AppActions.notifyApiError(err?.response?.data));
    throw err;
  }
};

const del: any = async (url) => {
  try {
    const response = await axios.delete(url);
    checkIdToken(response.headers);
    return response.data;
  } catch (err) {
    store.dispatch(AppActions.notifyApiError(err?.response?.data));
    throw err;
  }
};

const getGqlString = (doc: DocumentNode): string => doc.loc && doc.loc.source.body;

const graphQl: any = async (
  url: string,
  query: DocumentNode,
  variables: {} = {},
  responseObjectPropName: string,
  headers = {}
) => {
  try {
    const response = await axios.post(
      url,
      { query: getGqlString(query), variables: variables },
      { headers }
    );
    checkIdToken(response.headers);

    return response.data.data[responseObjectPropName];
  } catch (err) {
    store.dispatch(AppActions.notifyApiError(err?.response?.data));
    throw err;
  }
};

const graphQlQuery: any = async (
  url: string,
  query: DocumentNode,
  variables: {} = {},
  responseObjectPropName: string,
  headers = {}
) => {
  try {
    const response = await axios.post(
      url,
      { query: getGqlString(query), variables: variables },
      { headers }
    );
    checkIdToken(response.headers);

    if (Object.prototype.hasOwnProperty.call(response.data, 'errors')) {
      return response.data;
    }

    return response.data.data[responseObjectPropName];
  } catch (err) {
    store.dispatch(AppActions.notifyApiError(err?.response?.data));
    throw err;
  }
};

export default { get, post, put, patch, del, graphQl, graphQlQuery };
