import AsyncComponent from 'helpers/AsyncComponent';
import { getLocalisedRoute } from 'constants/urls';
import { ConsumerMenuOptions } from 'components/Pages/Consumer/ConsumerMenu';

const routes = [
  {
    path: getLocalisedRoute('updateProfile'),
    restricted: false,
    exact: true,
    displayLayout: false,
    selected: ConsumerMenuOptions.profile,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Consumer/Profile/ProfileUpdateContainer')
    ),
  },
  {
    path: getLocalisedRoute('profile'),
    restricted: true,
    exact: true,
    selected: ConsumerMenuOptions.profile,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Consumer/ConsumerContainer')
    ),
  },
  {
    path: getLocalisedRoute('myCars'),
    restricted: true,
    exact: true,
    selected: ConsumerMenuOptions.myCars,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Consumer/ConsumerContainer')
    ),
  },
];
export default routes;
