import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Grid, Toolbar } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import { Colors, Button } from '@smp/carama-ui-components';
import logo from '../../../assets/images/logoNew.svg';
import UserIcon from '../../../assets/images/svgs/user-icon-grey.svg';
import MobileMenuIcon from '../../../assets/images/svgs/hamburgerNavIconGrey.svg';
import appReducer, { HEADER_HEIGHT, initialState } from 'modules/app/app-hooks-reducer';
import HeaderDetailsItem from './HeaderDetailsItem';
import { HeaderItem } from '../types/header-types';
import {
  LogoLink,
  AccountDropdownContainer,
  Drawer,
  StyledIconButton,
  StyledMenu,
  StyledMenuItem,
  StyledSiteSwitchButton,
} from '../header-styles';
import { getLocalisedRoute } from 'constants/urls';
import { Link } from 'react-router-dom';
import { TranslatedText, TranslateString } from 'components/Translators';
import { navigateToPage } from 'helpers/history';
import { getUser, navigateToCIPLoginPage, navigateToCIPRegistrationPage } from 'helpers/login';
import { connect, useSelector } from 'react-redux';
import authActionTypes from '../../../modules/auth/auth-actions';
import { getUser as userSelector } from 'modules/auth/auth-selectors';
import isEmpty from 'lodash/isEmpty';
import { ConsumerMenuOptions } from 'components/Pages/Consumer/ConsumerMenu';
import { featureFlagsSelector } from 'modules/feature-flag';
import {
  getCustomPanel,
  getCustomPanelDetails,
  getCustomServiceSummary,
  getCustomSummary,
} from './accordion-items';
type HeaderMobileProps = {
  headerItems: Array<HeaderItem>;
  isAuthenticated?: boolean;
  logoutUser?: any;
  getCIPIdToken?: Function;
};

type CustomServiceSummaryProps = {
  localisedRoute: string;
  translationTokenID: string;
  others?: any;
};

const HeaderMobile: React.FC<HeaderMobileProps> = ({
  headerItems,
  isAuthenticated,
  getCIPIdToken,
}: HeaderMobileProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedPanel, setExpandedPanel] = useState('');
  const [, dispatch] = useReducer(appReducer, initialState);
  const { enhancementsToConsumerHeaderMenu } = useSelector(featureFlagsSelector);

  const containerDiv = useRef(null);
  const { ALMOST_BLACK, DARK_GREEN } = Colors;
  const CustomServiceSummary = getCustomServiceSummary();
  const CustomExpansionPanelServiceSummary: React.FC<CustomServiceSummaryProps> = ({
    localisedRoute,
    translationTokenID,
    others,
  }: CustomServiceSummaryProps) => (
    <CustomServiceSummary {...others}>
      <Link onClick={() => setOpen(!open)} to={getLocalisedRoute(localisedRoute)}>
        <div>
          <span>
            <TranslatedText id={translationTokenID} />
          </span>
        </div>
      </Link>
    </CustomServiceSummary>
  );

  const consumerIdentity = useSelector(userSelector) || getUser();

  const auth = isAuthenticated ? isAuthenticated : !isEmpty(consumerIdentity?.idToken);

  const handleDropdownClick = (event): void => setAnchorEl(event.currentTarget);

  const handleDropdownClose = (): void => setAnchorEl(null);

  const CustomPanel = getCustomPanel();
  const PanelDetails = getCustomPanelDetails();
  const CustomExpansionPanelSummary = getCustomSummary(enhancementsToConsumerHeaderMenu);

  useEffect(() => {
    dispatch({ type: HEADER_HEIGHT, height: containerDiv.current.clientHeight });
  }, []);

  return (
    <Grid container ref={containerDiv} data-qa="mobile-nav-header">
      <Grid
        item
        xs={6}
        sm={8}
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}>
        <LogoLink onClick={() => navigateToPage(getLocalisedRoute('home'))}>
          <img
            src={logo}
            title={TranslateString('img-title-tag-mobile-logo')}
            alt={TranslateString('img-alt-tag-mobile-logo')}
          />
        </LogoLink>
      </Grid>
      <Grid
        item
        xs={3}
        sm={2}
        style={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>
        {!auth && (
          <AccountDropdownContainer style={{ marginRight: 0 }}>
            <StyledIconButton
              color="inherit"
              aria-label="current-user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDropdownClick}
              disableRipple
              style={{
                backgroundColor: 'transparent',
                overflowWrap: 'break-word',
                maxWidth: '100%',
              }}>
              <img
                src={UserIcon}
                onClick={handleDropdownClick}
                style={{ height: '32px' }}
                alt={TranslateString('img-alt-text-user-icon')}
                title={TranslateString('img-title-text-user-icon')}
              />
              <TranslatedText id="header-mobile-nav-manage" />
            </StyledIconButton>
            <StyledMenu
              id="account-menu"
              anchorEl={anchorEl}
              keepMounted
              variant="menu"
              open={Boolean(anchorEl)}
              onClose={handleDropdownClose}
              aria-controls="menu-appbar"
              aria-haspopup="true">
              <StyledMenuItem
                onClick={() => {
                  handleDropdownClose();
                  navigateToCIPLoginPage();
                }}
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}>
                <TranslatedText id="header-login" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => {
                  navigateToCIPRegistrationPage();
                  handleDropdownClose();
                }}
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}>
                <TranslatedText id="header-register" />
              </StyledMenuItem>
            </StyledMenu>
          </AccountDropdownContainer>
        )}
        {auth && (
          <AccountDropdownContainer>
            <StyledIconButton
              aria-label="current-user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              disableRipple
              style={{ backgroundColor: 'transparent' }}
              onClick={handleDropdownClick}>
              <img
                src={UserIcon}
                onClick={handleDropdownClick}
                style={{ height: '32px' }}
                alt={TranslateString('img-alt-text-user-icon')}
                title={TranslateString('img-title-text-user-icon')}
              />
              <TranslatedText id="header-mobile-nav-manage" />
            </StyledIconButton>
            <StyledMenu
              id="account-menu"
              anchorEl={anchorEl}
              keepMounted
              variant="menu"
              open={Boolean(anchorEl)}
              onClose={handleDropdownClose}
              aria-controls="menu-appbar"
              aria-haspopup="true">
              <StyledMenuItem
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  navigateToPage(getLocalisedRoute(ConsumerMenuOptions.profile));
                  handleDropdownClose();
                }}>
                <TranslatedText id="header-profile" />
              </StyledMenuItem>
              <StyledMenuItem
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  navigateToPage(getLocalisedRoute(ConsumerMenuOptions.myCars));
                  handleDropdownClose();
                }}>
                <TranslatedText id="header-myCars" />
              </StyledMenuItem>
              <StyledMenuItem
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  navigateToPage(getLocalisedRoute('faq'));
                  handleDropdownClose();
                }}>
                <TranslatedText id="header-help" />
              </StyledMenuItem>

              <StyledMenuItem
                style={{
                  display: 'flex',
                  padding: '6px 16px',
                  lineHeight: '1.5rem',
                  justifyContent: 'flex-start',
                }}
                onClick={() => {
                  getCIPIdToken();
                  handleDropdownClose();
                }}>
                <TranslatedText id="header-logout" />
              </StyledMenuItem>
            </StyledMenu>
          </AccountDropdownContainer>
        )}
      </Grid>
      <Grid
        item
        xs={3}
        sm={2}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-gtm-tag={open ? 'close-menu' : 'open-menu'}>
        {!isEmpty(headerItems) && (
          <Toolbar disableGutters data-gtm-tag={open ? 'close-menu' : 'open-menu'}>
            <StyledIconButton
              data-qa={open ? 'close-menu' : 'open-menu'}
              data-gtm-tag={open ? 'close-menu' : 'open-menu'}
              color="inherit"
              aria-label="menu items"
              style={{
                paddingLeft: '0.5em',
                backgroundColor: 'transparent',
              }}
              onClick={() => setOpen(!open)}
              disableRipple>
              {open ? (
                <CloseIcon
                  style={{ color: ALMOST_BLACK, cursor: 'pointer' }}
                  data-gtm-tag={open ? 'close-menu' : 'open-menu'}
                />
              ) : (
                <>
                  <img
                    src={MobileMenuIcon}
                    title={TranslateString('img-title-tag-mobile-icon')}
                    alt={TranslateString('img-alt-tag-mobile-icon')}
                    style={{ height: '32px' }}
                    data-gtm-tag={open ? 'close-menu' : 'open-menu'}
                  />
                  <TranslatedText
                    id="header-mobile-nav-menu"
                    gtmTag={open ? 'close-menu' : 'open-menu'}
                  />
                </>
              )}
            </StyledIconButton>
          </Toolbar>
        )}
      </Grid>
      {open && (
        <Drawer style={{ height: enhancementsToConsumerHeaderMenu ? 'auto' : '100vh' }}>
          {!enhancementsToConsumerHeaderMenu && (
            <>
              <Grid>
                <CustomExpansionPanelServiceSummary
                  localisedRoute={'services'}
                  translationTokenID={'services-offered-mobile-tab'}
                />
              </Grid>
              <Grid>
                <CustomExpansionPanelServiceSummary
                  localisedRoute={'articles'}
                  translationTokenID={'magazine-title-tab'}
                />
              </Grid>
            </>
          )}

          {!enhancementsToConsumerHeaderMenu &&
            headerItems.map((headerItem) => {
              return (
                <CustomPanel
                  onChange={() => {
                    setExpandedPanel(
                      expandedPanel === headerItem.itemText ? '' : headerItem.itemText
                    );
                  }}
                  key={headerItem.itemText}
                  square
                  expanded={expandedPanel === headerItem.itemText}>
                  <CustomExpansionPanelSummary
                    expandIcon={
                      <ArrowDropDown
                        style={{
                          color: DARK_GREEN,
                          fontSize: '2.5rem',
                        }}
                      />
                    }>
                    <div>
                      <span style={{ color: DARK_GREEN }}>{headerItem.itemText}</span>
                    </div>
                  </CustomExpansionPanelSummary>
                  <PanelDetails>
                    {headerItem.children.map((child, index) => (
                      <HeaderDetailsItem key={index} onClick={() => setOpen(!open)} {...child} />
                    ))}
                  </PanelDetails>
                </CustomPanel>
              );
            })}

          {enhancementsToConsumerHeaderMenu &&
            headerItems.map((headerItem, index) => {
              return (
                <CustomPanel
                  onChange={() => {
                    setExpandedPanel(
                      expandedPanel === headerItem.itemText ? '' : headerItem.itemText
                    );
                  }}
                  onClick={() => headerItem.children.length < 1 && setOpen(!open)}
                  key={headerItem.itemText}
                  data-gtm-tag={`mobile-menu-item-${index}`}
                  square
                  expanded={
                    expandedPanel === headerItem.itemText && headerItem.children.length > 0
                  }>
                  <CustomExpansionPanelSummary
                    expandIcon={
                      <ArrowDropDown
                        style={{
                          color: DARK_GREEN,
                          fontSize: '2.5rem',
                          display: headerItem.children.length > 0 ? 'block' : 'none',
                        }}
                      />
                    }>
                    <div>
                      {headerItem.children.length > 0 && (
                        <span
                          style={{ color: DARK_GREEN }}
                          data-gtm-tag={`mobile-menu-item-${index}`}>
                          <TranslatedText
                            id={headerItem.itemText}
                            data-gtm-tag={`mobile-menu-item-${index}`}
                          />
                        </span>
                      )}
                      {headerItem.children.length < 1 && (
                        <Link
                          to={headerItem?.href}
                          style={{ color: DARK_GREEN, fontWeight: '600' }}
                          data-gtm-tag={`mobile-menu-item-${index}`}>
                          <TranslatedText
                            id={headerItem.itemText}
                            data-gtm-tag={`mobile-menu-item-${index}`}
                          />
                        </Link>
                      )}
                    </div>
                  </CustomExpansionPanelSummary>
                  {headerItem.children.length > 0 && (
                    <PanelDetails>
                      {headerItem.children.map((child, i) => (
                        <HeaderDetailsItem
                          key={i}
                          gtmTag={child?.altText?.toLowerCase()?.replace(/\s/g, '-')}
                          onClick={() => setOpen(!open)}
                          {...child}
                        />
                      ))}
                    </PanelDetails>
                  )}
                </CustomPanel>
              );
            })}

          {enhancementsToConsumerHeaderMenu && (
            <>
              <CustomPanel>
                <CustomExpansionPanelSummary>
                  <div>
                    <Link
                      to={getLocalisedRoute('home') + getLocalisedRoute('stepper')}
                      style={{ color: DARK_GREEN, fontWeight: '600' }}
                      onClick={() => setOpen(!open)}>
                      <TranslatedText id={'how-carama-works-mobile-tab'} />
                    </Link>
                  </div>
                </CustomExpansionPanelSummary>
              </CustomPanel>
              <CustomPanel>
                <CustomExpansionPanelSummary>
                  <div>
                    <Link
                      to={'/'}
                      style={{ color: DARK_GREEN, fontWeight: '600' }}
                      onClick={() => {
                        setOpen(!open);
                        navigateToCIPRegistrationPage();
                      }}>
                      <TranslatedText id={'sign-up-form-mobile-tab'} />
                    </Link>
                  </div>
                </CustomExpansionPanelSummary>
              </CustomPanel>
            </>
          )}

          <Grid container>
            {!enhancementsToConsumerHeaderMenu && (
              <Grid item xs={12} data-qa="switch-site-button">
                <StyledSiteSwitchButton disableRipple href={getLocalisedRoute('workshop')}>
                  <TranslatedText id="consumer-switch-to-workshop-site" />
                </StyledSiteSwitchButton>
              </Grid>
            )}

            <Grid item xs={12} style={{ padding: 16 }}>
              <Link to={getLocalisedRoute('workshops')}>
                <Button
                  id="menu-view-workshops"
                  data-qa="menu-view-workshops"
                  data-gtm-tag="menu-view-workshops"
                  text={
                    <TranslatedText id={'menu-view-workshops'} gtmTag={'menu-view-workshops'} />
                  }
                  gtmTag="menu-view-workshops"
                  onClick={() => setOpen(!open)}
                />
              </Link>
            </Grid>
          </Grid>
        </Drawer>
      )}
    </Grid>
  );
};

const mapDispatchToProps: any = (dispatch) => ({
  getCIPIdToken: () => dispatch(authActionTypes.getCIPIdToken()),
});

const mapStateToProps: any = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile);
