import {
  CARAMA_CONTENTFUL_ACCESS_TOKEN,
  CARAMA_CONTENTFUL_ENV,
  CARAMA_CONTENTFUL_SPACE_ID,
} from 'constants/env';
import { get } from 'lodash';

// eslint-disable-next-line
const contentful = require('contentful');

const contentfulClient = contentful.createClient({
  accessToken: CARAMA_CONTENTFUL_ACCESS_TOKEN,
  space: CARAMA_CONTENTFUL_SPACE_ID,
  environment: CARAMA_CONTENTFUL_ENV,
});

const serviceTypeSlugMap = {
  // English
  tuv: 'periodicMaintenance', // same in turkish
  brakes: 'brakeMaintenance',
  'brake-fluid': 'breakFluidMaintenance',
  'brake-calipers': 'breakCalipersMaintenance',
  'brake-discs-and-drums': 'discAndDrumMaintenance',
  wheels: 'wheelMaintenance',
  tyres: 'tyreMaintenance',
  servicing: 'servicing',
  exhaust: 'exhaustMaintenance',
  // Turkish
  fren: 'brakeMaintenance',
  'fren-hidroligi': 'breakFluidMaintenance',
  'fren-kaliperi': 'breakCalipersMaintenance',
  'fren-diskleri-kampana': 'discAndDrumMaintenance',
  tekerlek: 'wheelMaintenance',
  lastik: 'tyreMaintenance',
  'periyodik-bakim': 'servicing',
  egzoz: 'exhaustMaintenance',
};

export function getPagePath(page): string {
  const pages = [];
  const stack = [];
  stack.push(page);

  while (stack.length > 0) {
    const node = stack.pop();
    const name = node.fields?.slug;
    pages.push(name);
    if (node.fields?.parentPage) {
      stack.push(node.fields.parentPage);
    }
  }

  let result = '/' + pages.reverse().join('/') + '/';
  result = result.replace(/[/]+/g, '/');

  return result;
}

export const getContentType: any = (node?) => {
  try {
    return node.sys?.contentType.sys.id;
  } catch (e) {
    return undefined;
  }
};

export const getHomePageContent = (locale: string): Promise<any> => {
  return contentfulClient.getEntries({
    'fields.slug[match]': 'consumerIndex',
    // eslint-disable-next-line
    content_type: 'page',
    include: 10,
    locale: locale === 'tr' ? 'tr-TR' : 'en-US',
  });
};
export const getHomeServicesContent = (locale: string): Promise<any> => {
  return contentfulClient.getEntries({
    'fields.slug[match]': 'serviceLandingPage',
    // eslint-disable-next-line
    content_type: 'consumerServiceLandingPage',
    include: 10,
    locale: locale === 'tr' ? 'tr-TR' : 'en-US',
  });
};

export const getServicesTypeContent = (locale: string, serviceType: string): Promise<any> => {
  return contentfulClient.getEntries({
    'fields.slug[match]': get(serviceTypeSlugMap, serviceType),
    // eslint-disable-next-line
    content_type: 'consumerServiceTypePage',
    include: 10,
    locale: locale === 'tr' ? 'tr-TR' : 'en-US',
  });
};

export const getArticleHubData = (locale: string): Promise<any> => {
  return contentfulClient.getEntries({
    // eslint-disable-next-line
    content_type: 'magazineArticle',
    include: 10,
    select:
      'fields.title,fields.link,fields.datePublished,fields.bannerImage,fields.bannerImageAltText,fields.headlineText,fields.readTime',
    'fields.showInBlogsPage': true,
    order: 'fields.order',
    locale: locale === 'tr' ? 'tr-TR' : 'en-US',
  });
};

export const getMagazineArticle = (link: string, locale: string): Promise<any> => {
  return contentfulClient.getEntries({
    'fields.link[match]': link,
    // eslint-disable-next-line
    content_type: 'magazineArticle',
    include: 10,
    locale: locale === 'tr' ? 'tr-TR' : 'en-US',
  });
};

export const getContentfulHeaderData = (locale: string): Promise<any> => {
  return contentfulClient.getEntries({
    // eslint-disable-next-line
    content_type: 'magazineArticle',
    include: 10,
    select: 'fields.title,fields.link,fields.bannerImage,fields.bannerImageAltText',
    'fields.showInHeader': true,
    order: 'fields.order',
    locale: locale === 'tr' ? 'tr-TR' : 'en-US',
  });
};
