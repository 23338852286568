export const languages = {
  en: {
    name: 'English',
    nativeName: undefined,
    languageCode: 'en',
  },
  de: {
    name: 'German',
    nativeName: 'Deutsch',
    languageCode: 'de',
  },
  tr: {
    name: 'Turkish',
    nativeName: 'Turkiye',
    languageCode: 'tr',
  },
};
