export const LoadingActionTypes = {
  FETCH_REFERENCE_DATA: 'FETCH_REFERENCE_DATA',
  FETCH_IMPRESSUM_DATA: 'FETCH_IMPRESSUM_DATA',
  FETCH_PRIVACY_DATA: 'FETCH_PRIVACY_DATA',
  FETCH_CONTACTUS_DATA: 'FETCH_CONTACTUS_DATA',
  FETCH_SERVICE_DATA: 'FETCH_SERVICE_DATA',
  FETCH_FAQ_DATA: 'FETCH_FAQ_DATA',
  FETCH_TERMS_DATA: 'FETCH_TERMS_DATA',
  FETCH_MAGAZINE_DATA: 'FETCH_MAGAZINE_DATA',
  FETCH_CONSUMERPROMOTION_DATA: 'FETCH_CONSUMERPROMOTION_DATA',
  FETCH_SERVICES_SUCCESS: 'FETCH_SERVICES_SUCCESS',
  FETCH_FACILITIES_SUCCESS: 'FETCH_FACILITIES_SUCCESS',
  FETCH_MANUFACTURERS_SUCCESS: 'FETCH_MANUFACTURERS_SUCCESS',
  FETCH_CAR_TYPES_SUCCESS: 'FETCH_CAR_TYPES_SUCCESS',
  FETCH_REFERENCE_DATA_FAILURE: 'FETCH_REFERENCE_DATA_FAILURE',
  FETCH_TRANSLATIONS_SUCCESS: 'FETCH_TRANSLATIONS_SUCCESS',
  FETCH_TRANSLATIONS_ERROR: 'FETCH_TRANSLATIONS_ERROR',
};

export const LoadingActions = {
  fetchReferenceData: () => ({
    type: LoadingActionTypes.FETCH_REFERENCE_DATA,
  }),
  fetchFaqData: () => ({
    type: LoadingActionTypes.FETCH_FAQ_DATA,
  }),
  fetchServiceData: () => ({
    type: LoadingActionTypes.FETCH_SERVICE_DATA,
  }),
  fetchPrivacyData: () => ({
    type: LoadingActionTypes.FETCH_PRIVACY_DATA,
  }),
  fetchContactUsData: () => ({
    type: LoadingActionTypes.FETCH_CONTACTUS_DATA,
  }),
  fetchImpressumData: () => ({
    type: LoadingActionTypes.FETCH_IMPRESSUM_DATA,
  }),
  fetchTermsData: () => ({
    type: LoadingActionTypes.FETCH_TERMS_DATA,
  }),
  fetchConsumerPromotionData: () => ({
    type: LoadingActionTypes.FETCH_CONSUMERPROMOTION_DATA,
  }),
  fetchMagazineHubData: () => ({
    type: LoadingActionTypes.FETCH_MAGAZINE_DATA,
  }),
  fetchServicesSuccess: (services) => ({
    type: LoadingActionTypes.FETCH_SERVICES_SUCCESS,
    services,
  }),
  fetchFacilitiesSuccess: (facilities) => ({
    type: LoadingActionTypes.FETCH_FACILITIES_SUCCESS,
    facilities,
  }),
  fetchManufacturersSuccess: (manufacturers) => ({
    type: LoadingActionTypes.FETCH_MANUFACTURERS_SUCCESS,
    manufacturers,
  }),
  fetchCarTypesSuccess: (carTypes) => ({
    type: LoadingActionTypes.FETCH_CAR_TYPES_SUCCESS,
    carTypes,
  }),

  fetchReferenceDataFailure: (error, key) => ({
    type: LoadingActionTypes.FETCH_REFERENCE_DATA_FAILURE,
    error,
    key,
  }),
  fetchTranslationsSuccess: (translations) => ({
    type: LoadingActionTypes.FETCH_TRANSLATIONS_SUCCESS,
    translations,
  }),
  fetchTranslationsFailure: (error) => ({
    type: LoadingActionTypes.FETCH_TRANSLATIONS_SUCCESS,
    error,
  }),
};
