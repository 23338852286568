import urljoin from 'url-join';
import get from 'lodash/get';
import {
  WORKSHOP_SVC_URL,
  ADMIN_SVC_URL,
  AEM_BASE_URL,
  BOOKING_SVC_URL,
  GOOGLE_API_KEY,
  PRICING_SVC_URL,
  USER_SVC_URL,
  CARAMA_PUBLIC_SF_COMMUNITY_URL,
  CARAMA_PUBLIC_SF_REGISTER_URL,
  CARAMA_PUBLIC_SF_REDIRECT_CALLBACK,
  CARAMA_PUBLIC_SF_CLIENT_ID,
  CARAMA_PUBLIC_SF_EXPERIENCE_ID,
  PRICING_SVC_URL_CASTROL_CONNECT,
} from './env';
import { getSelectedLocale } from '../helpers/locale';

const CARAMA_WORKSHOP_SVC_URL = urljoin(WORKSHOP_SVC_URL, 'v1');
const CARAMA_BOOKING_SVC_URL = urljoin(BOOKING_SVC_URL, 'v1');
const CARAMA_PRICING_SVC_URL = urljoin(PRICING_SVC_URL, 'v1');
const CASTROL_CONNECT_PRICING_SVC_URL = urljoin(PRICING_SVC_URL_CASTROL_CONNECT, 'v1');
const ADMIN_SVC_BASE_URL = urljoin(ADMIN_SVC_URL, 'v1');

export const SALESFORCE_REGISTER_URL = urljoin(
  CARAMA_PUBLIC_SF_COMMUNITY_URL,
  CARAMA_PUBLIC_SF_REGISTER_URL
);
export const SALESFORCE_CALLBACK = urljoin(USER_SVC_URL, CARAMA_PUBLIC_SF_REDIRECT_CALLBACK);

export const SALESFORCE_COMMUNITY_URL = CARAMA_PUBLIC_SF_COMMUNITY_URL;
export const SALESFORCE_CLIENT_ID = CARAMA_PUBLIC_SF_CLIENT_ID;
export const SALESFORCE_EXPERIENCE_ID = CARAMA_PUBLIC_SF_EXPERIENCE_ID;
const SF_LOGOUT_PATH = process.env.CARAMA_PUBLIC_SF_LOGOUT_PATH || 'secur/logout.jsp';
export const SALESFORCE_LOGOUT_ENDPOINT = SALESFORCE_COMMUNITY_URL + SF_LOGOUT_PATH;
export const USER_LOGOUT_ENDPOINT = urljoin(USER_SVC_URL, 'v1', 'auth', 'logout');
export const CONSUMER_ENDPOINT = urljoin(USER_SVC_URL, 'v1', 'consumers');

export const CIP_CONSUMER_ENDPOINT = urljoin(USER_SVC_URL, 'v1', 'consumers', 'cip');
export const CIP_CONSUMER_TOKEN_REFRESH = urljoin(
  USER_SVC_URL,
  'v1',
  'consumers',
  'cip',
  'tokenRefresh'
);
export const USER_CIP_LOG_IN_ENDPOINT = urljoin(USER_SVC_URL, 'v1', 'auth', 'login');
export const USER_CIP_LOG_OUT_ENDPOINT = urljoin(USER_SVC_URL, 'v1', 'auth', 'logout', 'CIP');

export const SERVICES_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'repairTypes');
export const REPAIR_TYPES_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'repairTypes');
export const JOBS_TYPES_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'jobtypes');
export const CAR_TYPES_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'cartypes');
export const FACILITIES_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'facilities');
export const MANUFACTURERS_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'manufacturers');
export const TRANSLATIONS_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'translations');
export const COUNTRIES_ENDPOINT = urljoin(ADMIN_SVC_BASE_URL, 'countries');
export const PRICING_ENDPOINT = urljoin(CARAMA_PRICING_SVC_URL, 'pricing');
export const PRICING_ENDPOINT_CASTROL_CONNECT = urljoin(CASTROL_CONNECT_PRICING_SVC_URL, 'oats');
export const WORKSHOP_SEARCH_GRAPHQL_API = urljoin(CARAMA_WORKSHOP_SVC_URL, 'graphql');
export const BOOKING_GRAPHQL_API = urljoin(CARAMA_BOOKING_SVC_URL, 'graphql');
export const AEM_API = AEM_BASE_URL;
export const GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com/maps/api';
export const GOOGLE_GEOCODE_ENDPOINT = `${GOOGLE_MAPS_API_URL}/geocode/json?key=${GOOGLE_API_KEY}`;
export const HEADER_ENDPOINT = '/caas/consumer-header-content.model.json';
export const FOOTER_ENDPOINT = '/caas/consumer-footer-content.model.json';
export const HOME_ENDPOINT = '/caas/consumer-landing-page.model.json';
export const SERVICES_CONTENT_ENDPOINT = '/caas/services-landing-page.model.json';
export const PRIVACY_ENDPOINT = '/caas/consumer-privacy.model.json';
export const CONTACT_US_ENDPOINT = '/caas/consumer-contact-us.model.json';
export const IMPRESSUM_ENDPOINT = '/caas/consumer-impressum.model.json';
export const MAGAZINE_HUB_ENDPOINT = '/caas/magazine-hub.model.json';
export const TERMS_ENDPOINT = '/caas/consumer-terms-and-conditions.model.json';
export const FAQ_ENDPOINT = '/caas/consumer-faq.model.json';
export const WORKSHOP_REGISTER_INTEREST_ENDPOINT = `${CARAMA_WORKSHOP_SVC_URL}/register-interest`;
export const CONSUMER_PROMOTION_ENDPOINT = '/caas/consumer-promotion.model.json';

export const VEHICLE_LOOKUP_BASE = urljoin(CARAMA_WORKSHOP_SVC_URL, 'vehicle-lookup');
export const VEHICLE_HSN_TSN_LOOKUP = urljoin(VEHICLE_LOOKUP_BASE, 'vehicle-details');
export const VEHICLE_LOOKUP_GET_MANUFACTURERS = urljoin(VEHICLE_LOOKUP_BASE, 'manufacturers');
export const VEHICLE_LOOKUP_GET_MODELS = urljoin(VEHICLE_LOOKUP_BASE, 'models');
export const VEHICLE_LOOKUP_GET_SERIES_DETAILS = urljoin(VEHICLE_LOOKUP_BASE, 'series-details');

export const BOOTSTRAPPING_APP = '/loading';

export const WORKSHOP = '/workshop';
export const WORKSHOP_TR = '/servis';

export const CONSUMER = '/consumer';
export const CONSUMER_TR = '/aracsahibi';
export const HOME = '/home';
export const HOME_TR = '/ana-sayfa';
export const PROFILE = '/profile';
export const UPDATE_PROFILE = '/update-profile';
export const PROFILE_TR = `/profil`;
export const MYCARS = '/my-cars';
export const MYCARS_TR = '/arabalarim';

export const SIGNUP_PAGE = '/#sign-up';
export const SIGNUP_PAGE_TR = '/#uye-ol';
export const SIGNUP_ELEMENT_ID = 'sign-up';
export const SIGNUP_ELEMENT_ID_TR = 'uye-ol';

export const REVIEWS_ELEMENT_ID = 'reviews';
export const REVIEWS_ELEMENT_ID_TR = 'yorumlari';
export const REVIEWS_PAGE = `/#${REVIEWS_ELEMENT_ID}`;
export const REVIEWS_PAGE_TR = `/#${REVIEWS_ELEMENT_ID_TR}`;

export const WORKSHOPS = '/workshops';
export const WORKSHOPS_TR = '/servisler';
export const WORKSHOP_RESULT = '/workshop-result';
export const WORKSHOP_RESULT_TR = '/servis-listesi';

export const BOOK = '/book';
export const BOOK_TR = '/randevu';

export const BOOKING = '/booking';
export const BOOKING_TR = '/rezervasyon';

export const BOOKING_MANAGEMENT = '/booking-management';
export const BOOKING_MANAGEMENT_TR = '/randevu-yonetimi';

export const BOOKING_FAIL = `${BOOK}/failed-booking`;
export const BOOKING_FAIL_TR = `${BOOK_TR}/basarisiz-rezervasyon`;

export const COOKIES = '/cookies';
export const COOKIES_TR = '/cerez-politikasi';
export const CONTACT_US = '/contact-us';
export const CONTACT_US_TR = '/iletisim';
export const FAQ = '/faq';
export const FAQ_TR = '/sss';
export const PRIVACY = '/privacy';
export const PRIVACY_TR = '/gizlilik-politikasi';
export const TERMS = '/terms';
export const TERMS_TR = '/kullanim-kosullari';
export const ABOUT_US = '/about-us';
export const ABOUT_US_TR = '/hakkimizda';

export const ABOUT_US_BLOG = '/articles';
export const ABOUT_US_BLOG_TR = '/blog/caramaya-hos-geldiniz';

export const ARTICLES = '/articles';
export const ARTICLES_TR = '/blog';
export const WORKSHOP_REGISTER_INTEREST = '/register-interest';
export const WORKSHOP_REGISTER_INTEREST_TR = '/uye-ol';
export const SERVICES = '/services';
export const SERVICES_TR = '/arac-bakim-hizmetleri';
export const CONSUMER_PROMOTION = '/promotion';

export const SERVICES_MOT = '/services/tuv';
export const SERVICES_BRAKES = '/services/brakes';
export const SERVICES_WHEELS = '/services/wheels';
export const SERVICES_TYRES = '/services/tyres';
export const SERVICES_SERVICE = '/services/servicing';
export const SERVICES_EXHAUST = '/services/exhaust';

export const ARTICLES_1 = '/articles';
export const ARTICLES_2 = '/articles';
export const ARTICLES_3 = '/articles';
export const ARTICLES_4 = '/articles';
export const ARTICLES_5 = '/articles';

export const SERVICES_TR_MOT = '/arac-bakim-hizmetleri/tuv';
export const SERVICES_TR_BRAKES = '/arac-bakim-hizmetleri/fren';
export const SERVICES_TR_WHEELS = '/arac-bakim-hizmetleri/tekerlek';
export const SERVICES_TR_TYRES = '/arac-bakim-hizmetleri/lastik';
export const SERVICES_TR_SERVICE = '/arac-bakim-hizmetleri/periyodik-bakim';
export const SERVICES_TR_EXHAUST = '/arac-bakim-hizmetleri/egzoz';

export const ARTICLES_TR_1 = '/blog/baski-balata-nedir-bittigi-nasil-anlasilir';
export const ARTICLES_TR_2 = '/blog/araba-neden-su-eksiltir';
export const ARTICLES_TR_3 = '/blog/araba-koltugu-nasil-temizlenir';
export const ARTICLES_TR_4 = '/blog/motor-yagi-ne-zaman-degistirilir';
export const ARTICLES_TR_5 = '/blog/lastik-hiz-endeksi-ve-kodlari';

export const STEPPER_ELEMENT_ID = 'step-section';
export const STEPPER_ELEMENT_ID_TR = 'adimlar';
export const STEPPER_PAGE = `/#${STEPPER_ELEMENT_ID}`;
export const STEPPER_PAGE_TR = `/#${STEPPER_ELEMENT_ID_TR}`;

export const IMAGES = '/images';

export const LOGOUT_TR = '/log-out';
export const LOGOUT_EN = '/log-out';

export const ROUTE_MAP = {
  en: {
    workshop: WORKSHOP,
    consumer: CONSUMER,
    home: HOME,
    profile: PROFILE,
    updateProfile: UPDATE_PROFILE,
    myCars: MYCARS,
    signup: SIGNUP_PAGE,
    signupElementID: SIGNUP_ELEMENT_ID,
    reviews: REVIEWS_PAGE,
    reviewsElementId: REVIEWS_ELEMENT_ID,
    workshops: WORKSHOPS,
    workshopResult: WORKSHOP_RESULT,
    cookies: COOKIES,
    contactUs: CONTACT_US,
    faq: FAQ,
    privacy: PRIVACY,
    terms: TERMS,
    aboutUs: ABOUT_US,
    articles: ARTICLES,
    interest: WORKSHOP_REGISTER_INTEREST,
    services: SERVICES,
    consumerPromotion: CONSUMER_PROMOTION,
    book: BOOK,
    bookingFailed: BOOKING_FAIL,
    booking: BOOKING,
    bookingManagement: BOOKING_MANAGEMENT,
    servicesMot: SERVICES_MOT,
    servicesBrakes: SERVICES_BRAKES,
    servicesWheels: SERVICES_WHEELS,
    servicesTyres: SERVICES_TYRES,
    servicesService: SERVICES_SERVICE,
    servicesExhaust: SERVICES_EXHAUST,
    articles1: ARTICLES_1,
    articles2: ARTICLES_2,
    articles3: ARTICLES_3,
    articles4: ARTICLES_4,
    articles5: ARTICLES_5,
    aboutUsBlog: ABOUT_US_BLOG,
    stepper: STEPPER_PAGE,
    stepperElementId: STEPPER_ELEMENT_ID,
    logout: LOGOUT_EN,
  },
  tr: {
    workshop: WORKSHOP_TR,
    consumer: CONSUMER_TR,
    home: HOME_TR,
    profile: PROFILE_TR,
    updateProfile: UPDATE_PROFILE,
    myCars: MYCARS_TR,
    signup: SIGNUP_PAGE_TR,
    signupElementID: SIGNUP_ELEMENT_ID_TR,
    reviews: REVIEWS_PAGE_TR,
    reviewsElementId: REVIEWS_ELEMENT_ID_TR,
    workshops: WORKSHOPS_TR,
    workshopResult: WORKSHOP_RESULT_TR,
    cookies: COOKIES_TR,
    contactUs: CONTACT_US_TR,
    faq: FAQ_TR,
    privacy: PRIVACY_TR,
    terms: TERMS_TR,
    aboutUs: ABOUT_US_TR,
    articles: ARTICLES_TR,
    interest: WORKSHOP_REGISTER_INTEREST_TR,
    services: SERVICES_TR,
    consumerPromotion: CONSUMER_PROMOTION,
    book: BOOK_TR,
    bookingFailed: BOOKING_FAIL_TR,
    booking: BOOKING_TR,
    bookingManagement: BOOKING_MANAGEMENT_TR,
    servicesMot: SERVICES_TR_MOT,
    servicesBrakes: SERVICES_TR_BRAKES,
    servicesWheels: SERVICES_TR_WHEELS,
    servicesTyres: SERVICES_TR_TYRES,
    servicesService: SERVICES_TR_SERVICE,
    servicesExhaust: SERVICES_TR_EXHAUST,
    articles1: ARTICLES_TR_1,
    articles2: ARTICLES_TR_2,
    articles3: ARTICLES_TR_3,
    articles4: ARTICLES_TR_4,
    articles5: ARTICLES_TR_5,
    aboutUsBlog: ABOUT_US_BLOG_TR,
    stepper: STEPPER_PAGE_TR,
    stepperElementId: STEPPER_ELEMENT_ID_TR,
    logout: LOGOUT_TR,
  },
};

export const getLocalizedRoutes = (key: string): string => {
  const routes: Array<string> = [];
  Object.entries(ROUTE_MAP).forEach(([, localeRoutes]) => {
    const link = localeRoutes[key];
    if (link) {
      routes.push(link.replace('/', ''));
    }
  });
  return `/(${routes.join('|')})/`;
};

export const getLocalisedRoute = (key: string): string => {
  const locale = getSelectedLocale();
  return get(ROUTE_MAP, `${locale}.${key}`, '/');
};

export const getIsCurrentRouteByKey = (key: string): boolean => {
  const path = window.location.pathname.split('/').pop();
  return getLocalizedRoutes(key).includes(path);
};

export const AUTH_URL = `${CARAMA_PUBLIC_SF_COMMUNITY_URL}services/oauth2/authorize/expid_${CARAMA_PUBLIC_SF_EXPERIENCE_ID}?response_type=code&client_id=${CARAMA_PUBLIC_SF_CLIENT_ID}`;
