import { CARAMA_PUBLIC_SF_REDIRECT_CALLBACK, USER_SVC_URL } from 'constants/env';
import { AUTH_URL, getLocalisedRoute } from 'constants/urls';
import Config from 'config';
const { cip } = Config.get;

export const navigateToSfLoginPage = (): void => {
  const state = window.location.href?.endsWith('/')
    ? window.location.href.slice(0, -1)
    : window.location.href;
  const redirectUrl =
    AUTH_URL +
    '&redirect_uri=' +
    USER_SVC_URL +
    CARAMA_PUBLIC_SF_REDIRECT_CALLBACK +
    '&state=' +
    state;
  console.log('redirectUrl : ', redirectUrl);
  window.location.assign(encodeURI(redirectUrl));
};

export const navigateToCIPLoginPage = (): void => {
  window.location.assign(
    `${cip.cipLogin}?uiRedirectUrl=${window.origin}${getLocalisedRoute('consumer')}&signUp=0`
  );
};
export const navigateToCIPRegistrationPage = (): void => {
  window.location.assign(
    `${cip.cipLogin}?uiRedirectUrl=${window.origin}${getLocalisedRoute('consumer')}&signUp=1`
  );
};

export const safelyParseJson = (json, defaultReturn?): any => {
  try {
    return JSON.parse(json);
  } catch (e) {
    // appInsights.trackTrace({ message: 'Could not pass JSON string', severityLevel: 2 });
    return defaultReturn;
  }
};

export const getUser = (): any => {
  return safelyParseJson(localStorage.getItem('consumerIdentity'));
};

export const removeUser = (): void => {
  localStorage.removeItem('consumerIdentity');
};

export const setUser = (user): void => {
  const consumerIdentity = getUser();
  localStorage.setItem('consumerIdentity', JSON.stringify({ ...consumerIdentity, ...user }));
};

export const isUserDetailsComplete = (user): boolean => {
  return user?.phone && user.birthDate && user.address;
};
export const isUserNameComplete = (user): boolean => {
  return user?.firstName && user.lastName;
};

export const navigateToEndSessionPage = (): void => {
  const identity = getUser();
  window.location.assign(
    `${cip.cipEndSession}?id_token_hint=${identity.idToken}&client_id=${
      cip.cipClientId
    }&post_logout_redirect_uri=${window.origin}${getLocalisedRoute('consumer')}${getLocalisedRoute(
      'logout'
    )}`
  );
};
