import React, { useRef, useState } from 'react';
import { Button, Colors } from '@smp/carama-ui-components';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import {
  AccountDropdownContainer,
  ButtonTabOld,
  ButtonTab,
  StyledMenu,
  StyledMenuButton,
  StyledMenuItem,
} from './header-styles';
import { TranslatedText } from 'components/Translators';
import { getLocalisedRoute } from '../../constants/urls';
import { navigateToPage } from 'helpers/history';
import { getUser, navigateToCIPRegistrationPage, navigateToSfLoginPage } from 'helpers/login';
import authActionTypes from 'modules/auth/auth-actions';
import { connect, useSelector } from 'react-redux';
// import SmpSpan from 'components/SmpSpan/SmpSpan';
import UserIcon from '../../assets/images/user-icon.svg';
import DropdownTriangle from '../../assets/images/dropdowntriangle.svg';
import { getUser as userSelector } from 'modules/auth/auth-selectors';
import isEmpty from 'lodash/isEmpty';
import { ConsumerMenuOptions } from 'components/Pages/Consumer/ConsumerMenu';
import { featureFlagsSelector } from 'modules/feature-flag';

const { GREEN, DARK_GREEN, WHITE } = Colors;

export type HeaderProps = {
  // logoutUser?: any;
  classes: any;
  isAuthenticated: boolean;
  getCIPIdToken: Function;
  // workshop: Object,
  // setTaskId: Function,
};

const AccountHeader: any = ({ isAuthenticated, classes, getCIPIdToken }: HeaderProps) => {
  const consumerIdentity = useSelector(userSelector) || getUser();
  // const consumerIdentity = useSelector(getUser);
  const auth = isAuthenticated ? isAuthenticated : !isEmpty(consumerIdentity?.idToken);
  const username = (consumerIdentity && consumerIdentity.displayName) || '';

  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownRef = useRef();

  const handleDropdownClick = (event): void => setAnchorEl(event.currentTarget);
  const handleDropdownClose = (): void => setAnchorEl(null);

  const { homepageUpdateTextWithBrandPromise } = useSelector(featureFlagsSelector);

  const renderNavButtons = (
    <>
      {!homepageUpdateTextWithBrandPromise && (
        <Grid container item xs={7}>
          <Grid item md={3} style={{ marginRight: '3px', maxWidth: '110px' }}>
            <ButtonTabOld
              id="btnConsumer"
              onClick={() => navigateToPage(getLocalisedRoute('home'))}
              text={<TranslatedText id="header-consumer" />}
              bgColor={GREEN}
            />
          </Grid>
          <Grid item md={3} style={{ marginRight: '3px', maxWidth: '110px' }}>
            <ButtonTabOld
              id="btnBusiness"
              onClick={() => window && window.location.assign(getLocalisedRoute('workshop'))}
              text={<TranslatedText id="header-business" />}
              bgColor={DARK_GREEN}
            />
          </Grid>
        </Grid>
      )}
      {homepageUpdateTextWithBrandPromise && (
        <Grid
          container
          item
          alignItems={'center'}
          justifyContent={'flex-end'}
          spacing={1}
          xs={12}
          style={{ textAlign: 'right', padding: '0 1rem' }}>
          <Grid item md={2}>
            <ButtonTab
              id="btnConsumer"
              onClick={() => navigateToPage(getLocalisedRoute('home'))}
              text={<TranslatedText id="header-consumer" />}
              className={'active'}
              bgColor={WHITE}
            />
            <ButtonTab
              id="btnBusiness"
              onClick={() => window && window.location.assign(getLocalisedRoute('workshop'))}
              text={<TranslatedText id="header-business" />}
              bgColor={WHITE}
            />
          </Grid>
        </Grid>
      )}
    </>
  );

  const renderLoginButtons = (
    <Grid container item xs={5} spacing={1} alignItems={'center'} justifyContent={'flex-end'}>
      <Grid item md={4}>
        <Button
          id="btnLogin"
          data-gtm-tag="login"
          style={{ height: '29px' }}
          onClick={navigateToSfLoginPage}
          text={<TranslatedText id="header-login" />}
          variant="squareInverse"
        />
      </Grid>
      <Grid item md={4}>
        <Button
          id="btnRegister"
          data-gtm-tag="register"
          style={{ height: '29px' }}
          onClick={() => {
            navigateToCIPRegistrationPage();
          }}
          text={<TranslatedText id="header-register" />}
        />
      </Grid>
    </Grid>
  );

  const renderProfileDropDown = (
    <Grid container item xs spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
      <div data-gtm-tag="account-menu" id="accountBtn" style={{ paddingRight: '0.25rem' }}>
        <StyledMenuButton
          onClick={handleDropdownClick}
          ref={dropdownRef}
          data-qa="accountMenuDropdown">
          <div className={classes?.menuButtonItem}>
            <img src={UserIcon} alt="user icon" />
          </div>
          <div className={classes?.menuButtonItem} data-hj-suppress>
            {username}
          </div>
          <div className={classes?.menuButtonItem}>
            <img src={DropdownTriangle} style={{ height: '7px' }} alt="dropdown icon" />
          </div>
        </StyledMenuButton>
        <StyledMenu
          id="account-menu"
          anchorEl={anchorEl}
          keepMounted
          variant="menu"
          open={Boolean(anchorEl)}
          onClose={handleDropdownClose}
          aria-controls="menu-appbar"
          aria-haspopup="true">
          <StyledMenuItem
            style={{
              display: 'flex',
              padding: '6px 16px',
              lineHeight: '1.5rem',
              justifyContent: 'flex-start',
            }}
            onClick={() => {
              navigateToPage(getLocalisedRoute(ConsumerMenuOptions.profile));
              handleDropdownClose();
            }}>
            <TranslatedText id="header-profile" data-qa="headerMenu-profile" />
          </StyledMenuItem>
          <StyledMenuItem
            style={{
              display: 'flex',
              padding: '6px 16px',
              lineHeight: '1.5rem',
              justifyContent: 'flex-start',
            }}
            onClick={() => {
              navigateToPage(getLocalisedRoute(ConsumerMenuOptions.myCars));
              handleDropdownClose();
            }}>
            <TranslatedText id="header-myCars" data-qa="headerMenu-myCars" />
          </StyledMenuItem>
          <StyledMenuItem
            style={{
              display: 'flex',
              padding: '6px 16px',
              lineHeight: '1.5rem',
              justifyContent: 'flex-start',
            }}
            onClick={() => {
              navigateToPage(getLocalisedRoute('faq'));
              handleDropdownClose();
            }}>
            <TranslatedText id="header-help" data-qa="headerMenu-faq" />
          </StyledMenuItem>

          <StyledMenuItem
            style={{
              display: 'flex',
              padding: '6px 16px',
              lineHeight: '1.5rem',
              justifyContent: 'flex-start',
            }}
            onClick={() => {
              getCIPIdToken();
              handleDropdownClose();
            }}>
            <TranslatedText id="header-logout" data-qa="headerMenu-logout" />
          </StyledMenuItem>
        </StyledMenu>
      </div>
    </Grid>
  );

  return (
    <>
      <Hidden mdDown>
        {auth && (
          <AccountDropdownContainer>
            <Grid container>
              {renderNavButtons}
              {!homepageUpdateTextWithBrandPromise && renderProfileDropDown}
            </Grid>
          </AccountDropdownContainer>
        )}
        {!auth && (
          <AccountDropdownContainer>
            <Grid container>
              {renderNavButtons}
              {!homepageUpdateTextWithBrandPromise && renderLoginButtons}
            </Grid>
          </AccountDropdownContainer>
        )}
      </Hidden>
    </>
  );
};

const mapDispatchToProps: any = (dispatch) => ({
  getCIPIdToken: () => dispatch(authActionTypes.getCIPIdToken()),
});

const mapStateToProps: any = (state) => {
  return {
    // pathname: state.router.location.pathname,
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHeader);
