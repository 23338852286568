import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { APPINSIGHTS_CONNECTION_STRING, ENVIRONMENT_NAME, RELEASE_NAME } from '../../constants/env';
import history from '../../helpers/history';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    connectionString: APPINSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },

    /* Configs to enable correlation */
    // see https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#enable-correlation
    disableFetchTracking: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableCorsCorrelation: true,
    correlationHeaderExcludedDomains: [
      'myapp.azurewebsites.net',
      '*.queue.core.windows.net',
      '*.contentful.com',
      'preprod-downstream-www.carama.com',
      '*.google-analytics.com',
      '*.googleadservices.com',
      '*.doubleclick.net',
      'maps.googleapis.com',
      'castrol-idp-pp.bpglobal.com',
      'content.carama.com',
      '*.launchdarkly.com',
    ],
    // ignoreHeaders: ['Authorization', 'X-API-Key', 'WWW-Authenticate'],

    /* Configs for AJAX */
    enableAjaxErrorStatusText: true,
    enableAjaxPerfTracking: true,
  },
  queue: [],
});

ai.loadAppInsights();
ai.addTelemetryInitializer((envelope) => {
  envelope.tags['ai.cloud.role'] = 'carama-consumer-ui';
  envelope.data = {
    environment: ENVIRONMENT_NAME,
    release: RELEASE_NAME,
  };
});
ai.trackPageView();

export default (Component: any): any => withAITracking(reactPlugin, Component);
export const index = ai.appInsights;
