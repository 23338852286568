import { call, put, select } from 'redux-saga/effects';
import { fetchBusinessFooter } from './footer-api';
import { actions } from './footer-actions';
import { SagaIterator } from 'redux-saga';
import { getIpDataForUserSelectedLocale } from '../app/app-selectors';

export function* fetchFooterContent(): SagaIterator {
  try {
    const language = yield select(getIpDataForUserSelectedLocale);

    const contentResponse = yield call(fetchBusinessFooter, language);

    yield put(actions.fetchBrochureFooterContentSuccess(contentResponse));
  } catch (e) {
    yield put(actions.fetchBrochureFooterContentFailure(e));
  }
}
