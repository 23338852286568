import React, { useState } from 'react';
import { isEmpty, pickBy } from 'lodash';
import { connect } from 'react-redux';
import { FormLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ModalDialog, CaramaSelect } from '@smp/carama-ui-components';
import { theme } from 'styles/theme';
import { AppState } from 'store/AppState';
import { TranslatedText } from 'components/Translators';
import { AppActions } from '../../modules/app/actions';
import Config from '../../config';
import { CONSUMER, CONSUMER_TR } from 'constants/urls';
import countries from 'helpers/countries';
import { languages } from 'helpers/languages';
import { getSelectedLocale } from 'helpers/locale';

const getRegionOptions = (): Array<{ name: string; value: string }> => {
  return Object.values(
    pickBy(countries, (_, key) => Config.get.availableCountries.includes(key)) as unknown as any[]
  ).map((country) => ({
    name: !isEmpty(country.nativeCountryName)
      ? `${country.countryName} - ${country.nativeCountryName}`
      : country.countryName,
    value: country.countryIsoCode,
  }));
};

const getLocaleOptions = (): Array<{ name: string; value: string }> => {
  return Object.values(
    pickBy(languages, (_, key) => Config.get.availableLocales.includes(key)) as unknown as any[]
  ).map((language) => ({
    name: !isEmpty(language.nativeName)
      ? `${language.name} - ${language.nativeName}`
      : language.name,
    value: language.languageCode,
  }));
};

type Props = {
  open: boolean;
  selectedRegion: string;
  selectedLocale: string;
  changeLocale: Function;
  onCloseClick?: Function;
};

const renderRegionSelector = (
  regionSelectOption,
  setRegionSelectOption,
  localSelectOption,
  setLocalSelectOption
): JSX.Element => {
  const handleRegionChange = (event): void => {
    if (event.target.value) {
      setRegionSelectOption(event.target.value);
    }
  };

  const handleLocaleChange = (event): void => {
    if (event.target.value) {
      setLocalSelectOption(event.target.value);
    }
  };

  return (
    <>
      <Grid container direction={'column'}>
        <Grid item style={{ margin: '1rem 0' }}>
          <FormLabel htmlFor="region">
            <TranslatedText id={'region-selector-select-region'} />
          </FormLabel>
          <CaramaSelect
            theme={theme}
            id={'region'}
            options={getRegionOptions()}
            value={regionSelectOption}
            handleChange={handleRegionChange}
          />
        </Grid>
        <Grid item>
          <FormLabel htmlFor="language">
            <TranslatedText id={'region-selector-select-locale'} />
          </FormLabel>
          <CaramaSelect
            theme={theme}
            id={'locale'}
            options={getLocaleOptions()}
            value={localSelectOption}
            handleChange={handleLocaleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

const RegionSelectorDialog: React.FC<Props> = (props: Props) => {
  const [openModal, setOpenModal] = useState(props.open);
  const [regionSelectedOption, setRegionSelectedOption] = useState(props.selectedRegion);
  const [localeSelectedOption, setLocaleSelectedOption] = useState(props.selectedLocale);

  const onConfirmClick = (): void => {
    props.changeLocale(localeSelectedOption, regionSelectedOption);
    getSelectedLocale() === 'tr'
      ? (window.location.href = window.location.origin + CONSUMER_TR)
      : (window.location.href = window.location.origin + CONSUMER);
    if (props.onCloseClick) {
      props.onCloseClick();
    }
  };

  const closeModal = (): void => {
    setOpenModal(false);
    if (props.onCloseClick) {
      props.onCloseClick();
    }
  };

  return (
    <>
      {openModal && (
        <ModalDialog
          open={openModal}
          maxWidth="sm"
          theme={theme}
          onBackdropClick={closeModal}
          customTitle={<TranslatedText id="region-selector-dialog-title" />}
          cancelText={<TranslatedText id="dialog-cancel" />}
          confirmText={<TranslatedText id="dialog-confirm" />}
          content={renderRegionSelector(
            regionSelectedOption,
            setRegionSelectedOption,
            localeSelectedOption,
            setLocaleSelectedOption
          )}
          onConfirm={onConfirmClick}
          onCancel={closeModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): Partial<Props> => ({
  selectedRegion: state.core.ipData.countryIsoCode,
  selectedLocale: state.core.ipData.selectedLocale,
});

const mapDispatchToProps = (dispatch): Partial<Props> => ({
  changeLocale: (locale, region) => dispatch(AppActions.changeLocale(locale, region)),
});

export default connect<Partial<Props>, Partial<Props>, { open: boolean; onCloseClick: Function }>(
  mapStateToProps,
  mapDispatchToProps
)(RegionSelectorDialog);
