export const actionTypes = {
  FETCH_BROCHUREWARE_FOOTER: 'FETCH_BROCHUREWARE_FOOTER',
  FETCH_BROCHUREWARE_FOOTER_CONTENT_SUCCESS: 'FETCH_BUSINESS_FOOTER_CONTENT_SUCCESS',
  FETCH_BROCHUREWARE_FOOTER_CONTENT_FAILURE: 'FETCH_BUSINESS_FOOTER_CONTENT_FAILURE',
};

export const actions = {
  fetchBrochureFooter: () => ({
    type: actionTypes.FETCH_BROCHUREWARE_FOOTER,
  }),
  fetchBrochureFooterContentSuccess: (content) => ({
    type: actionTypes.FETCH_BROCHUREWARE_FOOTER_CONTENT_SUCCESS,
    payload: { content },
  }),
  fetchBrochureFooterContentFailure: (error) => ({
    type: actionTypes.FETCH_BROCHUREWARE_FOOTER_CONTENT_FAILURE,
    payload: { error },
  }),
};
