import { getMagazineArticle } from 'contentful/helpers';
import { ApiCaller } from 'helpers';
import { mapAemDataToList } from 'modules/brochureware/helpers';
import { getMagazineHubEndpoint } from 'modules/brochureware/url-helper';

export const fetchMagazineHub: any = async (languageCode: string) => {
  const data = await ApiCaller.get(getMagazineHubEndpoint(languageCode));
  return mapAemDataToList(data);
};

export const fetchMagazineArticle: any = async (languageCode: string, articleName: string) =>
  getMagazineArticle('/' + articleName, languageCode);
