import authActionTypes from './auth-actions';

const initState = { isAuthenticated: false, user: {}, isLoggedInSf: false };

export default (state = initState, action): any => {
  switch (action.type) {
    case authActionTypes.EMAIL_LOGIN: {
      return Object.assign(
        {},
        {
          isAuthenticated: true,
          isLoggedInSf: true,
          user: action.payload,
        }
      );
    }
    case authActionTypes.EMAIL_LOGIN_SUCCESS: {
      return Object.assign(
        {},
        {
          isAuthenticated: true,
          isLoggedInSf: true,
          user: action.payload,
        }
      );
    }
    case authActionTypes.LOGOUT_SUCCESS: {
      return { ...initState, isLoggedInSf: true };
    }
    case authActionTypes.LOGOUT_SALESFORCE_SUCCESS: {
      return initState;
    }
    case authActionTypes.USER_PROFILE_UPDATE_SUCCESS: {
      return { ...state, user: { ...state.user, ...action.payload } };
    }
    case authActionTypes.UPDATE_CAR_SUCCESS: {
      const user = { ...state.user, cars: [...action.payload] };
      return { ...state, user };
    }
    default:
      return state;
  }
};
