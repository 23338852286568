import AsyncComponent from 'helpers/AsyncComponent';
import { getLocalisedRoute } from '../constants/urls';

const routes = [
  {
    path: '/',
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../components/Pages/Brochureware/Home/HomeContainer')
    ),
  },
  {
    path: getLocalisedRoute('home'),
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../components/Pages/Brochureware/Home/HomeContainer')
    ),
  },
  {
    path: `${getLocalisedRoute('home')}/index.html`,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../components/Pages/Brochureware/Home/HomeContainer')
    ),
  },
  {
    path: `${getLocalisedRoute('home')}/index`,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../components/Pages/Brochureware/Home/HomeContainer')
    ),
  },
  {
    path: `${getLocalisedRoute('home')}/:stepIndex([0-3])`,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../components/Pages/Brochureware/Home/HomeContainer')
    ),
  },
  {
    path: '/loading',
    ViewComponent: AsyncComponent(() => import('../modules/auth/Bootstrapper')),
  },
];

export default routes;
