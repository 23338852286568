import { Action } from 'typesafe-actions';
import { BookingDto } from '../../../common-types/booking-types';

export const bookingActionTypes = {
  SUBMIT_BOOKING: 'SUBMIT_BOOKING',
  SUBMIT_BOOKING_SUCCESS: 'SUBMIT_BOOKING_SUCCESS',
  SUBMIT_BOOKING_FAILURE: 'SUBMIT_BOOKING_FAILURE',
  SUBMIT_BOOKING_ISNEWBOOKING: 'SUBMIT_BOOKING_ISNEWBOOKING',
  RESET_BOOKING: 'RESET_BOOKING',

  FETCH_BOOKING: 'FETCH_BOOKING',
  FETCH_BOOKING_SUCCESS: 'FETCH_BOOKING_SUCCESS',
  FETCH_BOOKING_ERROR: 'FETCH_BOOKING_ERROR',

  CANCEL_BOOKING: 'CANCEL_BOOKING',
  CANCEL_BOOKING_SUCCESS: 'CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_ERROR: 'CANCEL_BOOKING_ERROR',
};

export const BookingActions = {
  submitBooking: () => ({
    type: bookingActionTypes.SUBMIT_BOOKING,
  }),
  submitBookingSuccess: (payload: BookingDto) => ({
    type: bookingActionTypes.SUBMIT_BOOKING_SUCCESS,
    payload: payload,
  }),
  submitBookingFailure: (error) => ({
    type: bookingActionTypes.SUBMIT_BOOKING_FAILURE,
    error: error,
  }),
  submitIsNewBooking: (payload) => ({
    type: bookingActionTypes.SUBMIT_BOOKING_ISNEWBOOKING,
    payload,
  }),
  bookingError: (error: any) => ({
    type: bookingActionTypes.SUBMIT_BOOKING,
    error,
  }),
  resetBooking: (user) => ({
    type: bookingActionTypes.RESET_BOOKING,
    user,
  }),
  fetchBooking: (bookingId: string) => ({
    type: bookingActionTypes.FETCH_BOOKING,
    bookingId,
  }),
  fetchBookingSuccess: (payload: BookingDto) => ({
    type: bookingActionTypes.FETCH_BOOKING_SUCCESS,
    payload,
  }),
  fetchBookingError: (error: any) => ({
    type: bookingActionTypes.FETCH_BOOKING_ERROR,
    error,
  }),
  cancelBooking: (bookingId: string) => ({
    type: bookingActionTypes.CANCEL_BOOKING,
    bookingId,
  }),
  cancelBookingSuccess: () => ({
    type: bookingActionTypes.CANCEL_BOOKING_SUCCESS,
  }),
  cancelBookingError: (error: any) => ({
    type: bookingActionTypes.CANCEL_BOOKING_ERROR,
    error,
  }),
};

export interface BookingAction extends Action {
  bookingId: string;
}
