import { ActionWithPayload } from 'common-types/redux';

const authActionTypes = {
  EMAIL_LOGIN: 'EMAIL_LOGIN',
  EMAIL_LOGIN_SUCCESS: 'EMAIL_LOGIN_SUCCESS',
  EMAIL_LOGIN_FAILURE: 'EMAIL_LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGOUT_SALESFORCE_SUCCESS: 'LOGOUT_SALESFORCE_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  RESET_PASSWORD: 'RESET_PASSWORD',
  IN_PROGRESS: 'IN_PROGRESS',
  USER_REDIRECT: 'USER_REDIRECT',
  USER_PROFILE_UPDATE_SUCCESS: 'USER_PROFILE_UPDATE_SUCCESS',
  UPDATE_CAR_SUCCESS: 'UPDATE_CAR_SUCCESS',
  CIP_LOGOUT: 'CIP_LOGOUT',
  LOGOUT_CIP_SUCCESS: 'LOGOUT_CIP_SUCCESS',
  END_CIP_USER_SESSION: 'END_CIP_USER_SESSION',
  REVOKE_CIP_TOKEN: 'REVOKE_CIP_TOKEN',
  GET_CIP_ID_TOKEN: 'GET_CIP_ID_TOKEN',
  REDIRECT_TO_PROFILE_UPDATE: 'REDIRECT_TO_PROFILE_UPDATE',

  loggedInUser: (user, pathname?): ActionWithPayload<string> => {
    return {
      type: authActionTypes.EMAIL_LOGIN,
      payload: user,
      fromPath: pathname,
    };
  },
  loginSuccess: (user) => {
    return {
      type: authActionTypes.EMAIL_LOGIN_SUCCESS,
      payload: user,
    };
  },
  redirectUser: (path) => {
    return { type: authActionTypes.USER_REDIRECT, path };
  },

  logOutUser: (navigateToLandingPage = true) => {
    return {
      type: authActionTypes.LOGOUT,
      payload: { navigateToLandingPage },
    };
  },

  logoutSalesforceSuccess: () => {
    return {
      type: authActionTypes.LOGOUT_SALESFORCE_SUCCESS,
    };
  },

  logOutSuccess: () => {
    return {
      type: authActionTypes.LOGOUT_SUCCESS,
    };
  },
  userLogoutCIP: (navigateToLandingPage = true) => {
    return {
      type: authActionTypes.CIP_LOGOUT,
      payload: { navigateToLandingPage },
    };
  },
  endCIPUserSession: () => {
    return {
      type: authActionTypes.END_CIP_USER_SESSION,
    };
  },
  revokeCIPToken: (navigateToLandingPage = true) => {
    return {
      type: authActionTypes.REVOKE_CIP_TOKEN,
      payload: { navigateToLandingPage },
    };
  },
  getCIPIdToken: () => {
    return {
      type: authActionTypes.GET_CIP_ID_TOKEN,
    };
  },
  redirectToUpdateProfile: () => {
    return {
      type: authActionTypes.REDIRECT_TO_PROFILE_UPDATE,
    };
  },
};

export default authActionTypes;
