import _truncate from 'lodash/truncate';

export const convertAndPadStart = (val: number, count = 2, valueToPad = '0'): string =>
  val.toString().padStart(count, valueToPad);

export const formatLongString = (title: string, length: number): string => {
  if (title && title.length > length) {
    return _truncate(title, { length });
  }
  return title;
};

export const getPageKeyWordFromPath = (): string => {
  const path = window.location.pathname;
  return path.replace(/\//g, ' ').trim();
};

export const camelCaseWords = (sentence: string): string =>
  sentence.replace(/(\b[a-z](?!\s))/g, (str) => str.toUpperCase());

export const pascalCaseWords = (sentence: string): string =>
  sentence
    .replace(/\b\w/g, (char) => char.toUpperCase())
    .replace(/\B\w*/g, (char) => char.toLowerCase());

export const getMailToHref = (emailAddress: string, subject: string, body: string): string =>
  `mailto:${emailAddress}?subject=${subject}&body=${body.replace(/\n/g, '%0A')}`;

export const removeSpaces = (value: string): string => {
  return value ? value.replace(/\s+/g, '') : null;
};

export const getUrlQuery = (): string => window.location?.search || '';

export const getUrlPath = (): string => window.location.pathname;

export const getUrlOrigin = (): string => window.location.origin;

export const isEmpty = (it: string): boolean => it === undefined || it == null || it.length <= 0;

export const getCookie = (cname: string): string => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.startsWith(' ')) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const removeCookie = (cname: string): void => {
  if (getCookie(cname)) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
};
