import search from '../modules/search/search-reducer';
import workshops from '../modules/workshop/workshop-reducer';
import referenceData from '../modules/loading/loading-reducer';
import brochureWare from '../modules/brochureware/brochureware-reducers';
import core from '../modules/app/app-reducer';
import booking from '../modules/booking/booking-reducers';
import auth from '../modules/auth/auth-reducer';
import signup from '../modules/signup/signup-reducer';
import featureFlags from '../modules/feature-flag/feature-flag-reducer';

export default {
  search,
  workshops,
  referenceData,
  brochureWare,
  core,
  booking,
  auth,
  signup,
  featureFlags,
};
