const countries = {
  GB: {
    languageCode: 'en',
    selectedLocale: 'en',
    countryId: 1,
    countryCode: 44,
    latitude: 51.5088,
    longitude: -0.126,
    countryIsoCode: 'gb',
    country: 'UK',
    countryName: 'United Kingdom',
    nativeCountryName: '',
    currency: '£',
    postcodeRegex:
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
  },
  DE: {
    languageCode: 'de',
    selectedLocale: 'de',
    countryId: 2,
    postcodeRegex: /^\d{5}$/,
    countryCode: 49,
    country: 'GER',
    countryName: 'Germany',
    nativeCountryName: 'Deutschland',
    countryIsoCode: 'de',
    currency: '€',
    latitude: 50.1188,
    longitude: 8.6843,
  },
  TR: {
    languageCode: 'tr',
    selectedLocale: 'tr',
    countryId: 3,
    postcodeRegex: /^\d{5}$/,
    countryCode: 90,
    country: 'TUR',
    countryName: 'Turkey',
    nativeCountryName: 'Turkiye',
    countryIsoCode: 'tr',
    currency: '₺',
    latitude: 38.9637,
    longitude: 35.2433,
  },
};

export default countries;
