import React, { useState, useRef } from 'react';
import { Colors } from '@smp/carama-ui-components';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { getAemMediaUrl } from '../../modules/brochureware/helpers';
import { Link } from 'react-router-dom';
import { useOutsideClick } from '../../helpers/react-helpers';
import {
  detailsStyle,
  HeaderButton,
  HeaderItemChildContainer,
  HeaderItemDetails,
  HeaderLink,
  HeaderLinksContainer,
  CaramaLogo,
  StyledHeader,
  StyledMenu,
  StyledMenuButton,
  SelectedHeaderItemContainer,
  StyledMenuItem,
} from './header-styles';
import { HeaderItem } from './types/header-types';
import { getLocalisedRoute } from '../../constants/urls';
import { navigateToPage } from '../../helpers/history';
import { TranslatedText } from 'components/Translators';
import {
  getUser,
  navigateToCIPLoginPage,
  navigateToCIPRegistrationPage,
} from '../../helpers/login';
import { connect, useSelector } from 'react-redux';
import authActionTypes from '../../modules/auth/auth-actions';
import { getUser as userSelector } from 'modules/auth/auth-selectors';
import { ConsumerMenuOptions } from 'components/Pages/Consumer/ConsumerMenu';
import UserIcon from '../../assets/images/user-icon.svg';
import DropdownTriangle from '../../assets/images/dropdowntriangle.svg';
import { featureFlagsSelector } from 'modules/feature-flag';

const { DARK_GREEN } = Colors;
interface Props {
  headerItems: Array<HeaderItem>;
  isAuthenticated?: boolean;
  logoutUser?: any;
  classes?: any;
  getCIPIdToken?: Function;
}

const HeaderLarge: React.FC<Props> = ({
  headerItems,
  isAuthenticated,
  getCIPIdToken,
  classes,
}: Props) => {
  const headerNode = useRef();
  const selectedHeaderItemGrid = useRef<HTMLDivElement>();
  const [selectedHeaderItem, setSelectedHeaderItem] = useState(undefined);
  const [selectedHeaderItemPosition, setSelectedHeaderItemPosition] = useState(undefined);
  const consumerIdentity = useSelector(userSelector) || getUser();
  const auth = isAuthenticated ? isAuthenticated : !isEmpty(consumerIdentity?.idToken);
  const username = (consumerIdentity && consumerIdentity.displayName) || '';
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownRef = useRef();
  const { enhancementsToConsumerHeaderMenu } = useSelector(featureFlagsSelector);

  const handleDropdownClick = (event): void => setAnchorEl(event.currentTarget);
  const handleDropdownClose = (): void => setAnchorEl(null);

  useOutsideClick(headerNode, () => setSelectedHeaderItem(undefined));

  const handleHeaderItemMouseLeave = function (e): void {
    const selectHeaderItem = selectedHeaderItemGrid?.current;
    if (
      e.relatedTarget?.contains &&
      !e.relatedTarget.contains(e.currentTarget) &&
      selectHeaderItem
    ) {
      if (!selectHeaderItem.contains(e.relatedTarget)) {
        setSelectedHeaderItem(undefined);
      }
    }
  };

  const handleHeaderItemMouseEnter = function (e, headerItem): any {
    setSelectedHeaderItem(headerItem);
    let boundingRect = e.target.getBoundingClientRect();
    boundingRect =
      e.target.tagName.toLowerCase() === 'svg' || e.target.tagName.toLowerCase() === 'span'
        ? e.target.closest('div').getBoundingClientRect()
        : boundingRect;
    setSelectedHeaderItemPosition(boundingRect.x);
  };

  const renderLoginButtons = (
    <Grid container item xs={12} spacing={1} alignItems={'center'} justifyContent={'flex-end'}>
      <Grid item md={4}>
        <HeaderButton
          id="btnLogin"
          data-gtm-tag="login"
          onClick={navigateToCIPLoginPage}
          text={<TranslatedText id="header-login" />}
          variant="squareInverse"
          bgColor={DARK_GREEN}
        />
      </Grid>
      <Grid item md={4}>
        <HeaderButton
          id="btnRegister"
          bgColor={DARK_GREEN}
          data-gtm-tag="register"
          onClick={() => {
            navigateToCIPRegistrationPage();
          }}
          text={<TranslatedText id="header-register" />}
        />
      </Grid>
    </Grid>
  );

  const renderProfileDropDown = (
    <Grid container item xs spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
      <div data-gtm-tag="account-menu" id="accountBtn" style={{ marginTop: '0.75rem' }}>
        <StyledMenuButton
          onClick={handleDropdownClick}
          ref={dropdownRef}
          data-qa="accountMenuDropdown"
          style={{ background: 'none' }}>
          <div className={classes?.menuButtonItem}>
            <img src={UserIcon} alt="user icon" />
          </div>
          <div className={classes?.menuButtonItem} data-hj-suppress>
            {username}
          </div>
          <div className={classes?.menuButtonItem}>
            <img src={DropdownTriangle} style={{ height: '7px' }} alt="dropdown icon" />
          </div>
        </StyledMenuButton>
        <StyledMenu
          id="account-menu"
          anchorEl={anchorEl}
          keepMounted
          variant="menu"
          open={Boolean(anchorEl)}
          onClose={handleDropdownClose}
          aria-controls="menu-appbar"
          aria-haspopup="true">
          <StyledMenuItem
            style={{
              display: 'flex',
              padding: '6px 16px',
              lineHeight: '1.5rem',
              justifyContent: 'flex-start',
              fontSize: enhancementsToConsumerHeaderMenu ? '0.8em' : 'inherit',
            }}
            onClick={() => {
              navigateToPage(getLocalisedRoute(ConsumerMenuOptions.profile));
              handleDropdownClose();
            }}>
            <TranslatedText id="header-profile" data-qa="headerMenu-profile" />
          </StyledMenuItem>
          <StyledMenuItem
            style={{
              display: 'flex',
              padding: '6px 16px',
              lineHeight: '1.5rem',
              justifyContent: 'flex-start',
              fontSize: enhancementsToConsumerHeaderMenu ? '0.8em' : 'inherit',
            }}
            onClick={() => {
              navigateToPage(getLocalisedRoute(ConsumerMenuOptions.myCars));
              handleDropdownClose();
            }}>
            <TranslatedText id="header-myCars" data-qa="headerMenu-myCars" />
          </StyledMenuItem>
          <StyledMenuItem
            style={{
              display: 'flex',
              padding: '6px 16px',
              lineHeight: '1.5rem',
              justifyContent: 'flex-start',
              fontSize: enhancementsToConsumerHeaderMenu ? '0.8em' : 'inherit',
            }}
            onClick={() => {
              navigateToPage(getLocalisedRoute('faq'));
              handleDropdownClose();
            }}>
            <TranslatedText id="header-help" data-qa="headerMenu-faq" />
          </StyledMenuItem>

          <StyledMenuItem
            style={{
              display: 'flex',
              padding: '6px 16px',
              lineHeight: '1.5rem',
              justifyContent: 'flex-start',
              fontSize: enhancementsToConsumerHeaderMenu ? '0.8em' : 'inherit',
            }}
            onClick={() => {
              getCIPIdToken();
              handleDropdownClose();
            }}>
            <TranslatedText id="header-logout" data-qa="headerMenu-logout" />
          </StyledMenuItem>
        </StyledMenu>
      </div>
    </Grid>
  );

  // @ts-ignore
  return (
    <>
      <StyledHeader
        style={{ display: 'flex', flexDirection: 'column' }}
        ref={headerNode}
        onMouseLeave={handleHeaderItemMouseLeave}
        data-qa="nav-header">
        <HeaderLinksContainer>
          <Grid container>
            <Grid item md={2}>
              <Link to={getLocalisedRoute('home')}>
                <CaramaLogo />
              </Link>
            </Grid>
            <Grid item md={7}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  width: enhancementsToConsumerHeaderMenu ? '100%' : '75%',
                  margin: '0 auto',
                  height: '40px',
                }}>
                {!enhancementsToConsumerHeaderMenu && (
                  <>
                    <Grid item md={3}>
                      <HeaderLink>
                        <Link to={getLocalisedRoute('services')} data-qa="menuItem-servicesOffered">
                          <TranslatedText id="services-offered-tab" />
                        </Link>
                      </HeaderLink>
                    </Grid>
                    <Grid item md={3}>
                      <HeaderLink>
                        <Link to={getLocalisedRoute('articles')} data-qa="menuItem-articles">
                          <TranslatedText id="magazine-title-tab" />
                        </Link>
                      </HeaderLink>
                    </Grid>
                  </>
                )}

                {!enhancementsToConsumerHeaderMenu &&
                  headerItems.map((headerItem, index) => (
                    <Grid data-qa={`menuItem-${index}`} key={headerItem.itemText} item md={3}>
                      <HeaderLink
                        onMouseEnter={() => {
                          setSelectedHeaderItem(headerItem);
                        }}
                        onMouseLeave={handleHeaderItemMouseLeave}
                        // @ts-ignore
                        itemText={headerItem.itemText}
                        showDownArrow={
                          selectedHeaderItem && selectedHeaderItem.itemText === headerItem.itemText
                        }>
                        <a>{headerItem.itemText}</a>
                        <ArrowDropDown />
                      </HeaderLink>
                    </Grid>
                  ))}

                {enhancementsToConsumerHeaderMenu &&
                  headerItems.map((headerItem, index) => (
                    <Grid
                      data-qa={`menuItem-${index}`}
                      data-gtm-tag={`menuItem-${index}`}
                      key={headerItem.itemText}
                      item>
                      <HeaderLink
                        data-gtm-tag={`menuItem-${index}`}
                        onMouseEnter={(e) => {
                          headerItem.children.length > 0
                            ? handleHeaderItemMouseEnter(e, headerItem)
                            : '';
                        }}
                        onMouseLeave={handleHeaderItemMouseLeave}>
                        <Link to={headerItem?.href} data-gtm-tag={`menuItem-${index}`}>
                          <TranslatedText
                            id={headerItem.itemText}
                            data-gtm-tag={`menuItem-${index}`}
                          />
                        </Link>
                        {headerItem.children.length > 0 && <ArrowDropDown />}
                      </HeaderLink>
                    </Grid>
                  ))}
              </Grid>
            </Grid>

            <Grid item md={3}>
              {auth && renderProfileDropDown}
              {!auth && renderLoginButtons}
            </Grid>
          </Grid>
        </HeaderLinksContainer>
      </StyledHeader>
      {enhancementsToConsumerHeaderMenu && selectedHeaderItem && (
        <SelectedHeaderItemContainer
          ref={selectedHeaderItemGrid}
          onMouseLeave={handleHeaderItemMouseLeave}
          style={{ left: `${selectedHeaderItemPosition}px` }}>
          <div className="headerItemDetails">
            <TranslatedText id={selectedHeaderItem.sectionTitle} />
          </div>
          {selectedHeaderItem.children
            .filter((headerChild) => !isEmpty(headerChild.href))
            .map((headerChild, index) => (
              <Grid
                data-qa="header-child-container"
                data-gtm-tag={headerChild?.altText?.toLowerCase()?.replace(/\s/g, '-')}
                key={index}
                item
                md={12}
                className="headerChildContainer">
                <div
                  onClick={() => navigateToPage(headerChild.href)}
                  data-qa={`subMenuItem-${index}`}
                  data-gtm-tag={headerChild?.altText?.toLowerCase()?.replace(/\s/g, '-')}
                  className="headerChildItem">
                  <div className="headerChildItemImage">
                    <img src={headerChild?.imagePath} alt={'header-detail-icon'} />
                  </div>
                  <div className="headerChildItemText">
                    <TranslatedText id={headerChild?.hrefText} />
                  </div>
                </div>
              </Grid>
            ))}
        </SelectedHeaderItemContainer>
      )}

      {!enhancementsToConsumerHeaderMenu && selectedHeaderItem && (
        // @ts-ignore
        <Grid
          ref={selectedHeaderItemGrid}
          onMouseLeave={handleHeaderItemMouseLeave}
          container
          // @ts-ignore
          style={detailsStyle}>
          <Grid container item style={{ margin: '0 auto', width: 1440, marginTop: '1.5em' }}>
            <Grid item md={4}>
              <HeaderItemDetails>
                <h1>{selectedHeaderItem.sectionTitle}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedHeaderItem.sectionDescription,
                  }}
                />

                <Link to={selectedHeaderItem.sectionHref || ''}>
                  {selectedHeaderItem.sectionHrefText}
                </Link>
              </HeaderItemDetails>
            </Grid>
            <Grid item md={3} />

            {selectedHeaderItem.children
              .filter((headerChild) => !isEmpty(headerChild?.href))
              .map((headerChild, index) => (
                <Grid
                  data-qa="header-child-container"
                  data-gtm-tag={headerChild.altText.toLowerCase().replace(/\s/g, '-')}
                  key={index}
                  item
                  md={2}>
                  <div>
                    <HeaderItemChildContainer
                      onClick={() => {
                        navigateToPage(headerChild.href);
                      }}
                      data-qa={`subMenuItem-${index}`}>
                      <img
                        src={getAemMediaUrl(headerChild?.imagePath)}
                        alt={'header-detail-icon'}
                      />
                      <span>{headerChild?.hrefText}</span>
                    </HeaderItemChildContainer>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapDispatchToProps: any = (dispatch) => ({
  getCIPIdToken: () => dispatch(authActionTypes.getCIPIdToken()),
});

const mapStateToProps: any = (state) => {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLarge);
