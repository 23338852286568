import { ReviewsMap } from 'modules/search/types/search';
import Config from '../config';
const { forceWorkshopReviews, suppressWorkshopReviews } = Config.get;

export function getPlaceIdsFromWorkshops(workshops): Array<string> {
  const suppressReviews = suppressWorkshopReviews === 'true';
  const forceReviews = forceWorkshopReviews === 'true';
  return workshops.map((workshop) => {
    return !suppressReviews && (forceReviews || !!workshop.allowReviews)
      ? workshop.address.placeId
      : null;
  });
}

export function mapReviews(reviews, workshops): ReviewsMap {
  const reviewsMap = reviews.reduce(
    (map, review, index) => {
      map.reviews[workshops[index].id] = review;
      return map;
    },
    {
      reviews: {},
      fetchDate: new Date(),
    }
  );

  reviewsMap.highestRating = Math.max(...reviews.map((review) => review.rating)) || 0;
  reviewsMap.lowestRating = Math.min(...reviews.map((review) => review.rating)) || 0;

  return reviewsMap;
}
