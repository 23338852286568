import produce from 'immer';
import uniq from 'lodash/uniq';

import { SearchState } from 'modules/search/types/search-state';
import { SearchActionTypes } from 'modules/search/search-actions';
import {
  SearchChangeCoordinates,
  SearchChangeDates,
  SearchSetServices,
  SearchSuccessfulAction,
  SetSearchVehicleDataState,
  SearchWorkshopReviews,
  ToggleSearchServices,
  SearchSetVehicle,
  SearchSetVehicleRegistration,
  SearchSelectedWorkshopReviews,
  SearchSetFilter,
} from 'modules/search/types/actions';
import { initialVehicleLookupState } from '../vehicle-lookup/vehicle-lookup-reducer';

const initialState: SearchState = {
  workshops: [],
  searchInProgress: true,
  searchCoordinates: { lng: 0, lat: 0, formattedAddress: '' },
  searchDates: {
    start: null,
    end: null,
  },
  searchVehicle: {
    brandId: '',
    brandName: '',
    makeId: '',
    makeName: '',
    year: '',
    carId: '',
    carName: '',
    notes: '',
    vehicleRegistration: '',
  },
  searchServices: [],
  vehicleLookup: initialVehicleLookupState,
  workshopReviews: {
    reviews: {},
  },
  jobs: [],
  manufacturer: '',
  specializations: [],
};

export default (
  state = initialState,
  action: SearchSuccessfulAction &
    SearchSetVehicle &
    SearchSetVehicleRegistration &
    SearchSetServices &
    SearchSetFilter &
    SearchChangeCoordinates &
    SearchChangeDates &
    SetSearchVehicleDataState &
    ToggleSearchServices &
    SearchWorkshopReviews &
    SearchSelectedWorkshopReviews
): SearchState =>
  produce(state, (draft) => {
    switch (action.type) {
      case SearchActionTypes.SEARCH_SUCCESSFUL: {
        draft.workshops = action.searchResults;
        draft.searchInProgress = false;
        return;
      }
      case SearchActionTypes.CHANGE_SEARCH_COORDINATES: {
        draft.searchCoordinates = action.searchCoordinates;
        draft.searchInProgress = true;
        return;
      }
      case SearchActionTypes.CHANGE_SEARCH_DATES: {
        draft.searchDates = action.searchDates;
        draft.workshops = [];
        draft.workshopReviews = null;
        draft.searchInProgress = true;
        return;
      }
      case SearchActionTypes.SET_VEHICLE: {
        draft.searchVehicle = action.searchVehicle;
        draft.workshops = [];
        draft.workshopReviews = null;
        draft.searchInProgress = true;
        return;
      }
      case SearchActionTypes.SET_VEHICLE_MAKE: {
        draft.searchVehicle = {
          ...action.searchVehicle,
          vehicleRegistration: draft.searchVehicle.vehicleRegistration,
          notes: draft.searchVehicle.notes,
        };
        draft.searchInProgress = true;
        return;
      }
      case SearchActionTypes.SET_VEHICLE_REGISTRATION: {
        draft.searchVehicle = {
          ...draft.searchVehicle,
          vehicleRegistration: action.vehicleRegistration,
        };
        return;
      }
      case SearchActionTypes.SET_SEARCH_FILTER: {
        draft.jobs = action.jobs;
        draft.manufacturer = action.manufacturer;
        draft.specializations = action.specializations;
        return;
      }
      case SearchActionTypes.SET_SERVICES: {
        draft.searchServices = action.searchServices;
        draft.workshops = [];
        draft.workshopReviews = null;
        draft.searchInProgress = true;
        return;
      }
      case SearchActionTypes.SEARCH_SET_VEHICLE_STATE: {
        draft.vehicleLookup = action.vehicleLookup;
        return;
      }
      case SearchActionTypes.TOGGLE_SEARCH_SERVICE: {
        const { id, selected } = action;
        draft.searchServices = selected
          ? uniq([...draft.searchServices, id])
          : uniq(draft.searchServices.filter((service) => service !== id));
        return;
      }
      case SearchActionTypes.RESET_SEARCH: {
        const tmpState = initialState;
        tmpState.searchCoordinates = draft.searchCoordinates;
        return tmpState;
      }

      case SearchActionTypes.GET_WORKSHOP_REVIEWS_SUCCESSFUL: {
        draft.workshopReviews = action.workshopReviews;
        return;
      }

      case SearchActionTypes.GET_SELECTED_WORKSHOP_REVIEWS_SUCCESSFUL: {
        draft.workshopReviews.reviews[action.workshopId] = action.reviews;
        draft.workshopReviews.fetchDate = new Date();
        return;
      }

      default:
        return state;
    }
  });
