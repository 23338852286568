import React from 'react';
import { FooterContainer } from '../../styles/root-styles';
import CaramaFooterContainer from './CaramaFooterContainer';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <CaramaFooterContainer />
    </FooterContainer>
  );
};

export default Footer;
