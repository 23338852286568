import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { getPageKeyWordFromPath, getUrlOrigin, getUrlPath } from '../../helpers/common';
import { getCanonicalURL } from '../../helpers/page-metadata-helpers';

interface Props {
  pageId?: string;
  title?: string;
  description?: string;
  keywords?: string;
}

const PageMetaData: React.FC<Props> = ({ pageId, title, description, keywords }: Props) => {
  const canonicalURL = getCanonicalURL(document.documentURI);

  const intl = useIntl();
  // Pass it in or derive it from the URL
  const id = pageId ?? 'consumer-' + getPageKeyWordFromPath();

  const titleText = title && title.length ? title : intl.formatMessage({ id: `${id}-meta-title` });

  const keywordsText =
    keywords && keywords.length ? keywords : intl.formatMessage({ id: `${id}-meta-keywords` });

  const descriptionText =
    description && description.length
      ? description
      : intl.formatMessage({ id: `${id}-meta-description` });

  const paths = [
    '/aracsahibi/ana-sayfa/2',
    '/aracsahibi/ana-sayfa/3',
    '/aracsahibi/ana-sayfa/4',
    '/aracsahibi/servisler',
    '/aracsahibi/booking/1',
    '/aracsahibi/booking/2',
    '/aracsahibi/booking/3',
    '/aracsahibi/booking/4',
  ];
  const currentPath = getUrlPath();
  const origin = getUrlOrigin();
  const demoOrigin = 'https://www.app-demo.carama.com';
  const blockedPaths =
    paths.includes(currentPath) ||
    origin == demoOrigin ||
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    !!currentPath.match(/\/consumer|\/workshop/);

  return (
    <Helmet>
      <title>{titleText}</title>
      <link rel="canonical" href={canonicalURL} />
      <meta name="description" content={descriptionText} />
      <meta name="keywords" content={keywordsText} />
      {blockedPaths && <meta name="robots" content="noindex, nofollow" />}
    </Helmet>
  );
};

export default PageMetaData;
