import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import {
  HeaderDetailsItemMobileOld,
  HeaderDetailsItemMobile,
  HeaderItemChildMobileContainerOld,
  HeaderItemChildMobileContainer,
} from '../header-styles';
import { getAemMediaUrl } from '../../../modules/brochureware/helpers';
import { featureFlagsSelector } from 'modules/feature-flag';
import { TranslatedText } from 'components/Translators';

export interface HeaderDetailsItemProps {
  imagePath: string;
  href: string;
  hrefText: string;
  altText: string;
  gtmTag?: string;
  onClick: () => void;
}

const HeaderDetailsItem: React.FC<HeaderDetailsItemProps> = ({
  imagePath,
  href,
  hrefText,
  altText,
  gtmTag,
  onClick,
}: HeaderDetailsItemProps) => {
  const { enhancementsToConsumerHeaderMenu } = useSelector(featureFlagsSelector);

  if (isEmpty(href)) {
    return null;
  }

  return (
    <>
      {!enhancementsToConsumerHeaderMenu && (
        <HeaderItemChildMobileContainerOld>
          <HeaderDetailsItemMobileOld>
            <img src={getAemMediaUrl(imagePath)} alt={altText} />
            <Link to={href} onClick={onClick}>
              {hrefText}
            </Link>{' '}
          </HeaderDetailsItemMobileOld>
        </HeaderItemChildMobileContainerOld>
      )}
      {enhancementsToConsumerHeaderMenu && (
        <HeaderItemChildMobileContainer data-gtm-tag={gtmTag}>
          <HeaderDetailsItemMobile data-gtm-tag={gtmTag}>
            <Link to={href} onClick={onClick} data-gtm-tag={gtmTag}>
              <img src={imagePath} alt={altText} data-gtm-tag={gtmTag} />
              <TranslatedText id={hrefText} gtmTag={gtmTag} />
            </Link>{' '}
          </HeaderDetailsItemMobile>
        </HeaderItemChildMobileContainer>
      )}
    </>
  );
};

export default HeaderDetailsItem;
