import {
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  MenuList,
  Paper,
  styled,
} from '@material-ui/core';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
// import DateRangeIcon from '@material-ui/icons/DateRange';
// import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import * as React from 'react';
import { TranslatedText } from 'components/Translators';
import { urlSoftPushState } from 'helpers/history';
import { getLocalisedRoute } from 'constants/urls';

export enum ConsumerMenuOptions {
  dashboard = 'dashboard',
  profile = 'profile',
  myCars = 'myCars',
  booking = 'booking',
  offers = 'offers',
}

type MenuItemProps = {
  label: string;
  icon: any;
};

const menuOptions: MenuItemProps[] = [
  // { label: ConsumerMenuOptions.dashboard, icon: DashboardIcon },
  { label: ConsumerMenuOptions.profile, icon: PersonIcon },
  { label: ConsumerMenuOptions.myCars, icon: DriveEtaIcon },
  // { label: ConsumerMenuOptions.booking, icon: DateRangeIcon },
  // { label: ConsumerMenuOptions.offers, icon: CardGiftcardIcon },
];

export type ConsumerMenuProps = {
  selectedOption: string;
  setSelectedOption: Function;
};

const StyledListItemText = styled(ListItemText)<ListItemTextProps>(() => ({
  '& .MuiTypography-body1': { marginBottom: '0' },
}));

const ConsumerMenu: React.FC<any> = (props: ConsumerMenuProps) => {
  const { selectedOption, setSelectedOption } = props;

  return (
    <Paper style={{ maxWidth: '237px' }}>
      <MenuList style={{ paddingTop: '0', paddingBottom: '0' }}>
        {menuOptions.map((option) => (
          <MenuItem
            style={{ height: '55px' }}
            key={`menu-item-${option.label}`}
            selected={selectedOption == option.label}
            onClick={() => {
              setSelectedOption(option.label);
              urlSoftPushState(getLocalisedRoute('consumer') + getLocalisedRoute(option.label));
            }}>
            <ListItemIcon style={{ minWidth: '40px' }}>
              <option.icon
                fontSize="small"
                style={{ color: selectedOption == option.label ? 'ffffff' : 'inherit' }}
              />
            </ListItemIcon>
            <StyledListItemText>
              <TranslatedText id={`header-${option.label}`} />
            </StyledListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};

export default ConsumerMenu;
