import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import authActionTypes from './auth-actions';
import { navigateToPage } from '../../helpers/history';
import Config from '../../config';
import { ApiCaller } from 'helpers';
import { ActionWithPayload } from 'common-types/redux';
import { getUser, navigateToEndSessionPage, removeUser, setUser } from 'helpers/login';
import { bookingFormActionTypes } from 'modules/booking/actions/booking-form-actions';
const { salesforceEndpoints } = Config.get;
import _ from 'lodash';
import { getLocalisedRoute } from 'constants/urls';
import { getIDTokenAPI, logoutAPI, revokeCIPTokenAPI } from 'modules/logout/logout-api';
const { serviceEndpoints } = Config.get;

export function* sfLogOut(): any {
  yield takeEvery(
    authActionTypes.LOGOUT,
    function* (action: ActionWithPayload<Record<string, any>>) {
      try {
        //TODO uncomment below once the mulesoft endpoint
        // https://api-test.carama.com/carama-user-ci/v1/auth/logout
        // has been exposed
        // if we leave this un-commented we are unable to log out which we are not able to do due to a release.
        let response = {};
        try {
          response = call(ApiCaller.post(`${salesforceEndpoints.logout}`, {}, false));
        } catch (error) {
          //error logging out from idp, but this should not stop the user from logging out
          response = {};
        }

        if (action?.payload?.navigateToLandingPage) navigateToPage('/');
        if (response) {
          yield put({
            type: authActionTypes.LOGOUT_SUCCESS,
          });
        }
        removeUser();
        sessionStorage.clear();
      } catch (error) {
        yield put({
          type: authActionTypes.LOGOUT_FAILURE,
          payload: error,
        });
      }
    }
  );
}

export function* cipLogOut(): any {
  yield takeEvery(
    authActionTypes.CIP_LOGOUT,
    function* (action: ActionWithPayload<Record<string, any>>) {
      try {
        yield call(logoutAPI);
        yield put({
          type: authActionTypes.LOGOUT_SUCCESS,
        });
      } catch (error) {
        yield put({
          type: authActionTypes.LOGOUT_FAILURE,
          payload: error,
        });
      }
      removeUser();
      sessionStorage.clear();
      if (action?.payload?.navigateToLandingPage) navigateToPage(getLocalisedRoute('home'));
    }
  );
}

export function* emailLogin(): any {
  yield takeEvery(
    authActionTypes.EMAIL_LOGIN,
    function* (action: ActionWithPayload<Record<string, any>>) {
      try {
        if (action) {
          const sfUser = action.payload;
          let loggedInUser;
          try {
            const user = yield call(
              ApiCaller.get,
              `${serviceEndpoints.users}/my-profile`,
              {},
              true
            );
            const idToken = getUser()?.idToken;
            if (idToken) loggedInUser = { ...sfUser, ...user, idToken };
            else loggedInUser = { ...sfUser, ...user };
          } catch (error) {
            //user doesn't exist, or is an evil genius, or just somethign went horribly wrong
            loggedInUser = { ...sfUser };
          }
          setUser(loggedInUser);

          yield put({
            type: authActionTypes.EMAIL_LOGIN_SUCCESS,
            payload: loggedInUser,
          });
          const bookingFormInfo = _.pick(loggedInUser, [
            'title',
            'firstName',
            'lastName',
            'email',
            'phone',
          ]);
          bookingFormInfo.bookingTermsAgreed = true;
          bookingFormInfo.privacyPolicyAgreed = true;
          bookingFormInfo.marketingOptInAgreed = loggedInUser.marketingDoubleOptIn;
          yield put({
            type: bookingFormActionTypes.UPDATE_BOOKING_FROM,
            payload: bookingFormInfo,
          });
        }
      } catch (error) {
        yield put({
          type: authActionTypes.EMAIL_LOGIN_FAILURE,
          payload: error,
        });
      }
    }
  );
}

function* redirectToEndUserCIPSession(): any {
  yield takeEvery(authActionTypes.END_CIP_USER_SESSION, function* () {
    try {
      yield navigateToEndSessionPage();
    } catch (e) {
      navigateToPage(getLocalisedRoute('consumer'));
    }
  });
}
export function* revokeTokenCIP(): any {
  yield takeEvery(
    authActionTypes.REVOKE_CIP_TOKEN,
    function* (action: ActionWithPayload<Record<string, any>>) {
      let response = {};
      try {
        response = yield call(revokeCIPTokenAPI);
      } catch (error) {
        //error logging out from idp, but this should not stop the user from logging out
        response = {};
      }
      if (response) {
        yield put({
          type: authActionTypes.LOGOUT_SUCCESS,
        });
      }
      removeUser();
      sessionStorage.clear();
      if (action?.payload?.navigateToLandingPage) navigateToPage('/');
    }
  );
}
export function* getIdToken(): any {
  yield takeEvery(authActionTypes.GET_CIP_ID_TOKEN, function* () {
    try {
      const response = yield call(getIDTokenAPI);
      const identity = getUser() || {};
      let newIdentity = { ...identity };
      if (response.idToken && response.idToken !== identity.idToken) {
        newIdentity = { ...identity, idToken: response.idToken };
        localStorage.setItem('consumerIdentity', JSON.stringify(newIdentity));
      }
    } catch (error) {
      console.log(error);
    }
    yield put({
      type: authActionTypes.END_CIP_USER_SESSION,
    });
  });
}
export function* updateProfileSuccess(): any {
  // prettier-ignore
  // eslint-disable-next-line require-yield
  yield takeEvery(authActionTypes.USER_PROFILE_UPDATE_SUCCESS, function*(action: ActionWithPayload<any>) {
    const user = action.payload;
    setUser(user);
  });
}
export function* redirectToProfileUpdate(): any {
  // prettier-ignore
  // eslint-disable-next-line require-yield
  yield takeEvery(authActionTypes.REDIRECT_TO_PROFILE_UPDATE, function*() {
    navigateToPage(getLocalisedRoute('updateProfile'));
  });
}

export default function* AuthSaga(): any {
  yield all([
    fork(emailLogin),
    fork(sfLogOut),
    fork(updateProfileSuccess),
    fork(cipLogOut),
    fork(redirectToEndUserCIPSession),
    fork(revokeTokenCIP),
    fork(getIdToken),
    fork(redirectToProfileUpdate),
  ]);
}
