import { ContentState } from '../brochureware-types';

export const PromotionActionTypes = {
  FETCH_PROMOTION_CONTENT_SUCCESS: 'FFETCH_PROMOTION_CONTENT_SUCCESS',
  FETCH_PROMOTION_CONTENT_FAILURE: 'FETCH_PROMOTION_CONTENT_FAILURE',
};

export const consumerPromotionActions = {
  fetchPromotionSuccess: (content: ContentState) => ({
    type: PromotionActionTypes.FETCH_PROMOTION_CONTENT_SUCCESS,
    payload: { content },
  }),
  fetchPromotionFailure: (error: ContentState) => ({
    type: PromotionActionTypes.FETCH_PROMOTION_CONTENT_FAILURE,
    payload: { error },
  }),
};
