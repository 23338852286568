export const actionTypes = {
  FETCH_WEBSITE_HEADER: 'FETCH_WEBSITE_HEADER',
  FETCH_BUSINESS_HEADER_CONTENT_SUCCESS: 'FETCH_BUSINESS_HEADER_CONTENT_SUCCESS',
  FETCH_BUSINESS_HEADER_CONTENT_FAILURE: 'FETCH_BUSINESS_HEADER_CONTENT_FAILURE',
};

export const actions = {
  fetchWebsiteHeader: () => ({
    type: actionTypes.FETCH_WEBSITE_HEADER,
  }),
  fetchHeaderContentSuccess: (content) => ({
    type: actionTypes.FETCH_BUSINESS_HEADER_CONTENT_SUCCESS,
    content,
  }),
  fetchHeaderContentFailure: (error) => ({
    type: actionTypes.FETCH_BUSINESS_HEADER_CONTENT_FAILURE,
    error,
  }),
};
