import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { Colors } from '@smp/carama-ui-components';
import * as React from 'react';

const defaultStyles = {
  root: {
    backgroundColor: Colors.LIGHT_GREEN,
    border: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  expanded: {},
};

const ExpansionPanelSummary = (styles = defaultStyles): React.ComponentType<any> => {
  return withStyles(styles)(MuiExpansionPanelSummary);
};

export default ExpansionPanelSummary;
