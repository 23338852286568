import styled from 'styled-components';

export const PageDiv = styled.div`
  position: relative;
  height: 100%;
`;

export const BodyDiv = styled.div<{ height?: string }>`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
`;

export const PaddedContainer = styled.div`
  padding: 16px;
`;

export const PageContent = styled.div`
  flex-grow: 1;
`;

export const SiteContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const HeaderContainer = styled.div`
  max-width: 100%;
  flex-shrink: 0;
  width: 100%;
`;

export const FooterContainer = styled.footer`
  max-width: 100%;
  flex-shrink: 0;
  width: 100%;
  background: rgb(236, 238, 238);
  margin: 1em auto 0 auto;
`;

export const BannerContainer = styled.div`
  max-width: 100%;
  background: rgb(236, 238, 238);
  margin: 0 auto;
`;

export const BackgroundImageContainer = styled.div<{ url: string }>`
  background: transparent url(${(props) => props.url}) no-repeat;
  background-size: cover;
  background-position: top;
  border: 0;
  height: inherit;
  width: inherit;
  padding: 0;
  outline: none;
  cursor: pointer;
`;
