import produce from 'immer';
import { CoreState, Actions } from './types';
import { AppActionTypes } from './actions';

const initialState: CoreState = {
  configurations: [
    {
      languageCode: 'en',
      key: 'phone-number-length',
      value: 10,
    },
    {
      languageCode: 'de',
      key: 'phone-number-length',
      value: 15,
    },
    {
      languageCode: 'tr',
      key: 'phone-number-length',
      value: 13,
    },
    {
      languageCode: 'tr',
      key: 'post-code-regex',
      value: /^\d{5}$/,
    },
  ],
  ipData: {},
  error: null,
};

export default (state = initialState, action: Actions): CoreState =>
  produce(state, (draft) => {
    switch (action.type) {
      case AppActionTypes.IP_INFORMATION_SUCCESS: {
        draft.ipData = action.ipData;
        return;
      }
      case AppActionTypes.RESET_STORE: {
        state = initialState;
        return;
      }
      case AppActionTypes.API_EXCEPTION:
      case AppActionTypes.FORBIDDEN: {
        draft.error = action.error;
        return;
      }
      default:
        return state;
    }
  });
