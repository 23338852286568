export const featureFlagActionTypes = {
  FEATURE_FLAGS: 'FEATURE_FLAGS',
  FEATURE_FLAGS_OVERRIDDEN: 'FEATURE_FLAGS_OVERRIDDEN',
};

export const featureFlagActions = {
  featureFlags: (featureFlags) => {
    return {
      type: featureFlagActionTypes.FEATURE_FLAGS,
      payload: featureFlags,
    };
  },

  featureFlagsOverridden: (featureFlags) => {
    return {
      type: featureFlagActionTypes.FEATURE_FLAGS_OVERRIDDEN,
      payload: featureFlags,
    };
  },
};
