import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import * as React from 'react';
const defaultStyles = {
  root: {
    flexDirection: 'column',
    borderTop: 0,
  },
};

const getStyles = (styles): any => {
  return styles;
};

const ExpansionPanelDetails = (styles = defaultStyles): React.ComponentType => {
  return withStyles(() => getStyles(styles))(MuiExpansionPanelDetails);
};

export default ExpansionPanelDetails;
