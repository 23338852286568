import React, { Component, ComponentType } from 'react';

interface State {
  component: ComponentType;
}

export default function AsyncComponent(importComponent): any {
  class AsyncFunc extends Component<{}, State> {
    mounted: boolean;

    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }

    componentWillUnmount(): void {
      this.mounted = false;
    }

    async componentDidMount(): Promise<void> {
      this.mounted = true;
      const { default: Component } = await importComponent();

      this.setState({
        component: Component,
      });
    }

    render(): JSX.Element {
      const component = this.state.component ? <this.state.component {...this.props} /> : <div />;
      return <React.Fragment>{component}</React.Fragment>;
    }
  }
  return AsyncFunc;
}
