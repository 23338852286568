import { getSelectedLocale } from 'helpers/locale';

const actions = {
  REGISTER_INTEREST: 'REGISTER_INTEREST',
  REGISTER_INTEREST_SUCCESS: 'REGISTER_INTEREST_SUCCESS',
  REGISTER_INTEREST_FAILURE: 'REGISTER_INTEREST_FAILURE',

  registerInterest: (interest, formActions) => {
    interest.domain = 'consumer';
    interest.language = getSelectedLocale();

    return {
      type: actions.REGISTER_INTEREST,
      payload: { interest, formActions },
    };
  },

  registerInterestSuccess: () => {
    return {
      type: actions.REGISTER_INTEREST_SUCCESS,
    };
  },

  registerInterestFailure: (error) => {
    return {
      type: actions.REGISTER_INTEREST_FAILURE,
      error,
    };
  },
};

export default actions;
