// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { Button } from '@smp/carama-ui-components';

type Props = {
  title: string | Function | JSX.Element;
  content: string | Function | JSX.Element;
  okText: string | Function | JSX.Element;
  onClose?: Function;
};

type State = {
  open: boolean;
};

const StyledDialog = withStyles(() => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: '24px',
    },
  },
  paper: {
    width: '40%',
    minWidth: 300,
  },
}))(Dialog);

const DialogCloseIcon = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles((theme) => ({
  title: {
    padding: theme.spacing(1),
    paddingTop: '0em',
    display: 'flex',
    fontFamily: 'MuseoSans',
    alignItems: 'flex-start',
    textAlign: 'justify',
    borderTop: 'none',
    '& h6': {
      display: 'flex',
      fontFamily: 'MuseoSans',
      fontSize: '1.5em',
      textAlign: 'center',
      justifyContent: 'center',
      flex: 1,
      color: '#2E3037',
    },
  },
}))((props: any) => {
  const { children, classes } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.title}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1, 2),
    fontFamily: 'MuseoSans',
    fontSize: 14,
    textAlign: 'center',
    justifyContent: 'center',
    lineHeight: '1.8em',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ActionsDiv = styled.div`
  margin: 20px;
  width: 30%;
`;

class ConfirmationDialog extends React.Component<Props, State> {
  state = {
    open: true,
  };

  handleClose = (): void => {
    this.setState({ open: false });
    if (this.props.onClose) this.props.onClose();
  };

  render(): JSX.Element {
    const { title, content, okText } = this.props;
    return (
      <div>
        <StyledDialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          style={{ borderRadius: '24px' }}>
          <DialogCloseIcon>
            <IconButton aria-label="Close" onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogCloseIcon>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {title}
          </DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <ActionsDiv>
              <Button id="btnOk" onClick={this.handleClose} text={okText} />
            </ActionsDiv>
          </DialogActions>
        </StyledDialog>
      </div>
    );
  }
}

export default ConfirmationDialog;
