import { ContentState } from 'modules/brochureware/brochureware-types';
import { ImpressumActionTypes } from './impressum-actions';

const initialState = {
  content: {},
  error: {},
};

export default (state = initialState, action: any): ContentState => {
  switch (action.type) {
    case ImpressumActionTypes.FETCH_IMPRESSUM_SUCCESS: {
      return {
        ...state,
        content: { ...action.payload.content },
      };
    }
    case ImpressumActionTypes.FETCH_IMPRESSUM_FAILURE: {
      return {
        ...state,
        error: { ...action.payload.error },
      };
    }

    default:
      return state;
  }
};
