import { call, put, select } from 'redux-saga/effects';
import { ContactUsActions } from './contact-us-actions';
import { fetchContactUs } from './contact-us-api';
import { isEmpty } from 'lodash';
import { AppState } from 'store/AppState';
import { SagaIterator } from 'redux-saga';
import { getIpDataForUserSelectedLocale } from '../../app/app-selectors';

export function* fetchContactUsSaga(): SagaIterator {
  try {
    const state = (yield select()) as AppState;
    const contactUsState = state.brochureWare.contactUs;
    if (!isEmpty(contactUsState.content)) return;
    const language = yield select(getIpDataForUserSelectedLocale);

    if (language) {
      const content = yield call(fetchContactUs, language);
      yield put(ContactUsActions.fetchContactUsSuccess(content));
    }
  } catch (e) {
    yield put(ContactUsActions.fetchContactUsFailure(e));
  }
}
