import { getLocalisedRoute } from 'constants/urls';
import AsyncComponent from 'helpers/AsyncComponent';

const fallbackRoutes = [
  {
    exact: true,
    path: getLocalisedRoute('logout'),
    ViewComponent: AsyncComponent(() => import('../modules/logout/logout')),
  },
  {
    path: '*',
    exact: true,
    ViewComponent: AsyncComponent(() => import('../components/Pages/OopsPage/OopsPage')),
  },
];

export default fallbackRoutes;
