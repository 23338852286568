import gql from 'graphql-tag';
import Config from '../../config';
import { ApiCaller } from 'helpers';
import { SearchCoordinates, SearchDates } from './types/search';
import { searchWorkshopQuery } from '../../constants/queries/workshop-queries';

const { serviceEndpoints, bookingSearchDistance, appId } = Config.get;

export const apiStartSearch = (
  coords: SearchCoordinates,
  services: Array<string>,
  searchDates: SearchDates,
  manufacturer: string,
  by: string,
  specializations = []
): Promise<any> => {
  const { lat, lng } = coords;
  const { start, end } = searchDates;
  const direction = 'ASCENDING';
  const maxDistanceMiles = bookingSearchDistance;

  return ApiCaller.graphQl(
    serviceEndpoints.bookingGraphqlApi,
    gql`
      ${searchWorkshopQuery}
    `,
    {
      searchParams: {
        startDate: start,
        endDate: end,
        jobs: services,
        sort: {
          by: by || 'RECOMMENDED',
          lat: lat,
          long: lng,
          direction: direction,
        },
        location: {
          lat: lat,
          long: lng,
        },
        specializations,
        maxDistanceMiles,
        manufacturer,
      },
    },
    'findAvailableWorkshops',
    { 'x-app-id': appId }
  );
};

let googlePlacesApi;
const overQueryLimitMessage = 'OVER_QUERY_LIMIT';
export const getPlaceDetailsFromGoogle = (placeRequest: any): Promise<any> => {
  if (!googlePlacesApi) {
    const googleServiceContainer = document.createElement('div');
    googlePlacesApi = new google.maps.places.PlacesService(googleServiceContainer);
  }

  return new Promise((resolve) => {
    if (!placeRequest.placeId) {
      resolve({ placeRequest: placeRequest, response: { rating: null } });
      return;
    }
    googlePlacesApi.getDetails(placeRequest, (details, status) => {
      if (status === overQueryLimitMessage) {
        resolve({
          placeRequest: placeRequest,
          response: { rating: null },
          exception: overQueryLimitMessage,
        });
      } else if (!details || status !== 'OK') {
        resolve({ placeRequest: placeRequest, response: { rating: null } });
      } else {
        const { rating, reviews, user_ratings_total: totalRatings } = details;
        resolve({ placeRequest: placeRequest, response: { rating, reviews, totalRatings } });
      }
    });
  });
};

export const waitFor = (ms: number): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve(`Simon says go`);
    }, ms);
  });
};

export const makePlaceIdRequests = (placeIds: Array<string>): Array<any> => {
  return placeIds.map((placeId) => {
    return {
      placeId: placeId,
      fields: ['rating', 'reviews', 'user_ratings_total'],
    };
  });
};
export const getGoogleReviews = async (placeIds: Array<string>): Promise<any> => {
  const reviewsAndErrors = [];
  const placeIdRequests = makePlaceIdRequests(placeIds);
  for (const placeIdRequest of placeIdRequests) {
    const result = await getPlaceDetailsFromGoogle(placeIdRequest);
    reviewsAndErrors.push(result);
  }
  const isError: boolean =
    reviewsAndErrors.findIndex((review) => review.exception === overQueryLimitMessage) > -1;

  const justReviews = reviewsAndErrors.map((review) => review.response);

  console.log('isReviewError : ', isError);
  return { reviews: justReviews, isError: isError, reviewsAndErrors: reviewsAndErrors };
};

export const throttleRequestsToGoogleApi = async (
  placeRequestsAndResponses: Array<any>
): Promise<any> => {
  const reviewsAndErrors = [];
  for (const item of placeRequestsAndResponses) {
    if (item.exception === 'OVER_QUERY_LIMIT') {
      const result = await getPlaceDetailsFromGoogle(item.placeRequest);
      if (result.exception === overQueryLimitMessage) {
        await waitFor(1000);
      }
      reviewsAndErrors.push(result);
    } else {
      reviewsAndErrors.push(item);
    }
  }
  return reviewsAndErrors;
};
export const resolveOverLimitQuery = async (
  placeRequestsAndResponses: Array<any>
): Promise<any> => {
  await waitFor(1000);
  const reviewsAndErrors = await throttleRequestsToGoogleApi(placeRequestsAndResponses);
  const isError: boolean =
    reviewsAndErrors.findIndex((review) => review.exception === overQueryLimitMessage) > -1;

  const justReviews = reviewsAndErrors.map((review) => review.response);

  console.log('isReviewError : ', isError);
  return { reviews: justReviews, isError: isError, reviewsAndErrors: reviewsAndErrors };
};
