import { createSelector } from 'reselect';
import { AppState } from 'store/AppState';
import { Selector } from 'react-redux';
import { SearchState } from 'modules/search/types/search-state';
import { getCoordinates } from 'helpers';
import { SearchCoordinates, SearchDates, SearchVehicle, ReviewsMap } from './types/search';
import { Workshop, WorkshopCoordinates } from '../../common-types/workshop';

const searchStateSelector = (state: AppState): SearchState => state.search;

export const getSearchInProgress: Selector<AppState, boolean> = createSelector(
  searchStateSelector,
  (searchState) => searchState.searchInProgress
);

export const getSearchCoordinates: Selector<AppState, SearchCoordinates> = createSelector(
  searchStateSelector,
  (searchState) => searchState.searchCoordinates
);

export const getFormattedSearchAddress: Selector<AppState, string> = createSelector(
  searchStateSelector,
  (searchState) => searchState.searchCoordinates.formattedAddress
);

export const getSearchDates: Selector<AppState, SearchDates> = createSelector(
  searchStateSelector,
  (searchState) => searchState.searchDates
);

export const getSearchVehicle: Selector<AppState, SearchVehicle> = createSelector(
  searchStateSelector,
  (searchState) => searchState.searchVehicle
);

export const getSpecializations: Selector<AppState, Array<string>> = createSelector(
  searchStateSelector,
  (searchState) => {
    return searchState.specializations;
  }
);

export const getManufacturer: Selector<AppState, string> = createSelector(
  searchStateSelector,
  (searchState) => searchState.manufacturer
);

export const getJobs: Selector<AppState, Array<string>> = createSelector(
  searchStateSelector,
  (searchState) => searchState.jobs
);

export const getSelectedServiceIds: Selector<AppState, Array<string>> = createSelector(
  searchStateSelector,
  (searchState) => searchState.searchServices
);

export const getDefaultCenter: Selector<AppState, WorkshopCoordinates> = createSelector(
  searchStateSelector,
  (searchState) => {
    if (searchState.workshops.length === 0) return { lat: 1, lng: 1 };

    const firstWorkshop = searchState.workshops[0];
    return { ...getCoordinates(firstWorkshop.address.location) };
  }
);

export const getSearchResults: Selector<AppState, Array<Partial<Workshop>>> = (state: AppState) => {
  return createSelector(searchStateSelector, (searchState) => {
    return searchState.workshops.map((workshop) => {
      return {
        id: workshop.id,
        allowReviews: workshop.allowReviews,
        name: workshop.name,
        address: workshop.address,
        ...getCoordinates(workshop.address.location),
        images: workshop?.images,
        media: workshop?.media,
        availableDates: workshop?.availableDates,
        distance: workshop?.distance,
        cas: workshop?.cas,
        specializations: workshop.specializations,
      };
    });
  })(state);
};

export const getWorkshopReviews: Selector<AppState, ReviewsMap> = createSelector(
  searchStateSelector,
  (searchState) => searchState.workshopReviews
);
