import { createSelector } from 'reselect';
import { AppState } from 'store/AppState';
import { isEmpty } from 'lodash';
import { getSelectedLocale } from 'helpers/locale';
import { Config } from './types';

const ipDataSelector = (state: AppState): Record<string, string> => state.core.ipData;
export const errorSelector = (state: AppState): Record<string, string> => state.core.error;
export const configurationsSelector = (state: AppState): Config[] => state.core.configurations;

export const getIpDataForUserSelectedLocale = createSelector(ipDataSelector, (ipData) =>
  !isEmpty(ipData) ? ipData.selectedLocale : getSelectedLocale()
);

export const getPhoneNumberLimit = createSelector(
  configurationsSelector,
  (state) => state.find((x) => x.languageCode == 'tr' && x.key == 'phone-number-length').value
);

export const getPostCodeRegex = createSelector(
  configurationsSelector,
  (state) => state.find((x) => x.languageCode == 'tr' && x.key == 'post-code-regex').value
);
