import { AppState } from './AppState';
const CARAMA_SEARCH_STATE = 'caramaSearchState';

export const loadState = (): Partial<AppState> | undefined => {
  try {
    const serializedState = localStorage.getItem(CARAMA_SEARCH_STATE);
    if (serializedState === null) {
      return undefined;
    }
    const serializedStateObject: Partial<AppState> | undefined = JSON.parse(serializedState);
    const { searchDates, jobs, manufacturer, specializations } = serializedStateObject.search;
    const { form } = serializedStateObject.booking;
    return {
      ...serializedStateObject,
      search: {
        ...serializedStateObject.search,
        searchDates: {
          start: searchDates.start ? new Date(searchDates.start) : null,
          end: searchDates.end ? new Date(searchDates.end) : null,
        },
        jobs: jobs || [],
        manufacturer: manufacturer || '',
        specializations: specializations || [],
      },
      booking: {
        ...serializedStateObject.booking,
        form: {
          ...form,
          bookingDate: form.bookingDate ? new Date(form.bookingDate) : null,
        },
      },
    };
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: Partial<AppState>): any => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(CARAMA_SEARCH_STATE, serializedState);
  } catch {
    // ignore write errors for now
  }
};
