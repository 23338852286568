import { call, put, select } from 'redux-saga/effects';
import { PrivacyActions } from './privacy-actions';
import { fetchPrivacy } from './privacy-api';
import { isEmpty } from 'lodash';
import { AppState } from 'store/AppState';
import { SagaIterator } from 'redux-saga';
import { getIpDataForUserSelectedLocale } from '../../app/app-selectors';

export function* fetchPrivacySaga(): SagaIterator {
  try {
    const state = (yield select()) as AppState;
    const privacyState = state.brochureWare.privacy;
    if (!isEmpty(privacyState.content)) return;
    const language = yield select(getIpDataForUserSelectedLocale);

    if (language) {
      const content = yield call(fetchPrivacy, language);
      yield put(PrivacyActions.fetchPrivacySuccess(content));
    }
  } catch (e) {
    yield put(PrivacyActions.fetchPrivacyFailure(e));
  }
}
