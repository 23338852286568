import { signupActionTypes } from './signup-actions';

const initialState = {
  content: {},
  error: {},
};

export default (state = initialState, action: any): any => {
  switch (action.type) {
    case signupActionTypes.SIGNUP_USER_SUCCESS:
    case signupActionTypes.SIGNUP_USER_FAILURE:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
