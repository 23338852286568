import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

interface Props extends TypographyProps {
  id: string;
  gtmTag?: string;
  values?: Record<string, any>;
}

const TranslatedText: React.FC<Props> = ({ id, values, gtmTag, ...rest }: Props) => {
  const textKey = id || 'unspecified-token';
  return (
    <Typography data-qa={textKey} data-gtm-tag={gtmTag} variant="inherit" {...rest}>
      <FormattedMessage id={textKey} defaultMessage={textKey} values={values} />
    </Typography>
  );
};

export default TranslatedText;
