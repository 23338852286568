import {
  SearchSetServices,
  SearchSuccessfulAction,
  ToggleSearchServices,
  SearchSetFilter,
} from 'modules/search/types/actions';
import { Reviews, ReviewsMap, SearchCoordinates, SearchDates, SearchVehicle } from './types/search';
import { VehicleLookupState } from '../vehicle-lookup/types/vehicle-lookup-types';
import { Action } from 'typesafe-actions';

export const SearchActionTypes = {
  START_SEARCH: 'START_SEARCH',
  SEARCH_SUCCESSFUL: 'SEARCH_SUCCESSFUL',
  SEARCH_FAILURE: 'SEARCH_FAILURE',
  CHANGE_SEARCH_COORDINATES: 'CHANGE_SEARCH_COORDINATES',
  CHANGE_SEARCH_DATES: 'CHANGE_SEARCH_DATES',
  SET_VEHICLE: 'SET_VEHICLE',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER',
  SET_VEHICLE_MAKE: 'SET_VEHICLE_MAKE',
  SET_VEHICLE_REGISTRATION: 'SET_VEHICLE_REGISTRATION',
  SET_SERVICES: 'SET_SERVICES',

  SEARCH_SET_VEHICLE_STATE: 'SEARCH_SET_VEHICLE_STATE',
  TOGGLE_SEARCH_SERVICE: 'TOGGLE_SEARCH_SERVICE',
  RESET_SEARCH: 'RESET_SEARCH',

  GET_WORKSHOP_REVIEWS: 'GET_WORKSHOP_REVIEWS',
  GET_WORKSHOP_REVIEWS_SUCCESSFUL: 'GET_WORKSHOP_REVIEWS_SUCCESSFUL',
  GET_SELECTED_WORKSHOP_REVIEWS: 'GET_SELECTED_WORKSHOP_REVIEWS',
  GET_SELECTED_WORKSHOP_REVIEWS_SUCCESSFUL: 'GET_SELECTED_WORKSHOP_REVIEWS_SUCCESSFUL',
};

export const SearchActions = {
  startSearch: (sortBy, jobs = [], specializations = [], manufacturer = '') => ({
    type: SearchActionTypes.START_SEARCH,
    sortBy,
    jobs,
    specializations,
    manufacturer,
  }),

  resetSearch: () => ({
    type: SearchActionTypes.RESET_SEARCH,
  }),

  searchSuccessful: (searchResults: SearchSuccessfulAction) => ({
    type: SearchActionTypes.SEARCH_SUCCESSFUL,
    searchResults,
  }),

  searchFailure: (error) => ({
    type: SearchActionTypes.SEARCH_FAILURE,
    error,
  }),

  changeSearchCoordinates: (coordinates: SearchCoordinates) => ({
    type: SearchActionTypes.CHANGE_SEARCH_COORDINATES,
    searchCoordinates: coordinates,
  }),

  changeSearchDates: (dates: SearchDates) => ({
    type: SearchActionTypes.CHANGE_SEARCH_DATES,
    searchDates: {
      start: dates.start,
      end: dates.end,
    },
  }),

  setSearchVehicle: (vehicleData: SearchVehicle): any => ({
    type: SearchActionTypes.SET_VEHICLE,
    searchVehicle: vehicleData,
  }),

  setVehicleMake: (vehicleMake: string) => ({
    type: SearchActionTypes.SET_VEHICLE_MAKE,
    searchVehicle: { brandId: vehicleMake, brandName: vehicleMake },
  }),

  setVehicleRegistration: (vehicleRegistration: string) => ({
    type: SearchActionTypes.SET_VEHICLE_REGISTRATION,
    vehicleRegistration,
  }),

  setSearchFilter: (
    jobs: Array<string>,
    manufacturer: string,
    specializations: Array<string>
  ): SearchSetFilter => ({
    type: SearchActionTypes.SET_SEARCH_FILTER,
    jobs: jobs,
    manufacturer: manufacturer,
    specializations: specializations,
  }),

  setSearchServices: (services: Array<string>): SearchSetServices => ({
    type: SearchActionTypes.SET_SERVICES,
    searchServices: services,
  }),

  toggleSearchService: (id: string, selected: boolean): ToggleSearchServices => ({
    type: SearchActionTypes.TOGGLE_SEARCH_SERVICE,
    id,
    selected,
  }),

  setVehicleData: (vehicleLookup: VehicleLookupState) => ({
    type: SearchActionTypes.SEARCH_SET_VEHICLE_STATE,
    vehicleLookup,
  }),

  getWorkshopReviews: () => ({
    type: SearchActionTypes.GET_WORKSHOP_REVIEWS,
  }),

  getWorkshopReviewsSuccess: (workshopReviews: ReviewsMap) => ({
    type: SearchActionTypes.GET_WORKSHOP_REVIEWS_SUCCESSFUL,
    workshopReviews,
  }),

  getSelectedWorkshopReviews: () => ({
    type: SearchActionTypes.GET_SELECTED_WORKSHOP_REVIEWS,
  }),

  getSelectedWorkshopReviewsSuccess: (workshopId: string, reviews: Reviews) => ({
    type: SearchActionTypes.GET_SELECTED_WORKSHOP_REVIEWS_SUCCESSFUL,
    workshopId,
    reviews,
  }),
};

export interface SearchAction extends Action {
  sortBy: string;
  jobs?: string[];
  specializations?: string[];
  manufacturer?: string;
}
