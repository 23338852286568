import actions from './actions';

interface State {
  error: string | boolean;
  registerInterestSuccess: boolean;
}

const initialState = {
  registerInterestSuccess: false,
  error: null,
};

export default (state = initialState, action): State => {
  switch (action.type) {
    case actions.REGISTER_INTEREST_SUCCESS:
      return {
        ...state,
        error: false,
        registerInterestSuccess: true,
      };

    case actions.REGISTER_INTEREST_FAILURE:
      return {
        ...state,
        error: action.error.message,
      };

    default:
      return state;
  }
};
