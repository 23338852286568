import { ContentState } from '../brochureware-types';

export const ImpressumActionTypes = {
  FETCH_IMPRESSUM_SUCCESS: 'FETCH_IMPRESSUM_SUCCESS',
  FETCH_IMPRESSUM_FAILURE: 'FETCH_IMPRESSUM_FAILURE',
};

export const ImpressumActions = {
  fetchImpressumSuccess: (content: ContentState) => ({
    type: ImpressumActionTypes.FETCH_IMPRESSUM_SUCCESS,
    payload: { content },
  }),
  fetchImpressumFailure: (error: ContentState) => ({
    type: ImpressumActionTypes.FETCH_IMPRESSUM_FAILURE,
    payload: { error },
  }),
};
