import { all } from 'redux-saga/effects';
import SearchSaga from 'modules/search/search-saga';
import WorkshopSaga from 'modules/workshop/workshop-saga';
import BookingSaga from 'modules/booking/booking-saga';
import LoadingSaga from 'modules/loading/loading-saga';
import BrochurewareSaga, { ServiceTypeSaga } from 'modules/brochureware/brochureware-saga';
import SignupSaga from 'modules/signup/signup-saga';
import AuthSaga from 'modules/auth/auth-saga';
import ConsumerSaga from 'modules/consumer/consumer-saga';

export default function* rootSaga(): any {
  yield all([
    SearchSaga(),
    WorkshopSaga(),
    LoadingSaga(),
    BrochurewareSaga(),
    ServiceTypeSaga(),
    BookingSaga(),
    SignupSaga(),
    AuthSaga(),
    ConsumerSaga(),
  ]);
}
