import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { FooterProps } from './Footer';
import { SocialMediaContainer } from './footer-styles';
import { getAemMediaUrl } from 'modules/brochureware/helpers';

const renderSocialMediaContainer: React.FC<any> = (content: any) => {
  return (
    <SocialMediaContainer>
      <a
        href={get(content, 'lowerFooter1SocialIconLink.value')}
        aria-label={get(content, 'lowerFooter1SocialIconLinkAriaLabel.title')}>
        <img
          src={getAemMediaUrl(get(content, 'lowerFooter1SocialIconPath.value'))}
          alt={get(content, 'lowerFooter1SocialIconAltText.value')}
        />
      </a>
      <a
        href={get(content, 'lowerFooter2SocialIconLink.value')}
        aria-label={get(content, 'lowerFooter2SocialIconLinkAriaLabel.title')}>
        <img
          src={getAemMediaUrl(get(content, 'lowerFooter2SocialIconPath.value'))}
          alt={get(content, 'lowerFooter2SocialIconAltText.value')}
        />
      </a>
      <a
        href={get(content, 'lowerFooter3SocialIconLink.value')}
        aria-label={get(content, 'lowerFooter3SocialIconLinkAriaLabel.title')}>
        <img
          src={getAemMediaUrl(get(content, 'lowerFooter3SocialIconPath.value'))}
          alt={get(content, 'lowerFooter3SocialIconAltText.value')}
        />
      </a>
      <a
        href={get(content, 'lowerFooter4SocialIconLink.value')}
        aria-label={get(content, 'lowerFooter4SocialIconLinkAriaLabel.title')}>
        <img
          src={getAemMediaUrl(get(content, 'lowerFooter4SocialIconPath.value'))}
          alt={get(content, 'lowerFooter4SocialIconAltText.value')}
        />
      </a>
    </SocialMediaContainer>
  );
};

const FooterLastRow: React.FC<Partial<FooterProps>> = ({ content }: FooterProps) => {
  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item>
        <p>
          <Link
            to={get(content, `lowerFooter${1}itemTitleLink.value`, '')}
            aria-label={get(content, `lowerFooter${1}itemTitleLink.title`)}
            data-qa="lowerfooter-column1">
            {get(content, `lowerFooter${1}itemTitle.value`)}
          </Link>
        </p>
      </Grid>
      <Grid item>
        <p>
          <Link
            to={get(content, `lowerFooter${2}itemTitleLink.value`, '')}
            aria-label={get(content, `lowerFooter${2}itemTitleLink.title`)}
            data-qa="lowerfooter-column2">
            {get(content, `lowerFooter${2}itemTitle.value`)}
          </Link>
        </p>
      </Grid>
      <Grid item>
        <p>
          <Link
            to={get(content, `lowerFooter${3}itemTitleLink.value`, '')}
            aria-label={get(content, `lowerFooter${3}itemTitleLink.title`)}
            data-qa="lowerfooter-column3">
            {get(content, `lowerFooter${3}itemTitle.value`)}
          </Link>
        </p>
      </Grid>
      <Grid item>
        <p>{get(content, 'lowerFooter4itemTitle.value')}</p>
      </Grid>
      <Grid item>
        <p>{get(content, 'lowerFooter5itemTitle.value')}</p>
      </Grid>
      <Grid item>{renderSocialMediaContainer(content)}</Grid>
    </Grid>
  );
};

export default FooterLastRow;
