import { applyMiddleware, createStore, compose, combineReducers, Store } from 'redux';
import { loadState } from './localStorage';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { AppState } from 'store/AppState';
import { ENABLE_REDUX_DEVTOOLS } from 'constants/env';
import reducers from '../../src/store/reducers';
import history from '../helpers/history';
import rootSaga from './sagas';

export default function configureStore(): Store<AppState> {
  const sagaMiddleware = createSagaMiddleware();
  const routeMiddleware = routerMiddleware(history);
  const middlewares = [sagaMiddleware, routeMiddleware];
  const composeEnhancers =
    (process.env.NODE_ENV === 'production' &&
      ENABLE_REDUX_DEVTOOLS === 'true' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const preloadedState = loadState();
  const store = createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);

  return store;
}
