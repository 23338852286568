import produce from 'immer';

export const initialState = { headerHeight: '55' };

export const HEADER_HEIGHT = 'HEADER_HEIGHT';

function reducer(state = initialState, action): any {
  produce(state, (draft) => {
    switch (action.type) {
      case HEADER_HEIGHT: {
        draft.headerHeight = action.height;
        return;
      }
      default:
        return state;
    }
  });
}
export default reducer;
