import { bookingFormActionTypes } from '../actions/booking-form-actions';
import { bookingActionTypes } from '../actions/booking-actions';
import { BookingForm } from '../booking-types';
import { SearchActionTypes } from '../../search/search-actions';

export const initialState: BookingForm = {
  bookingDate: null,
  bookingDateIndex: null,
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  notes: '',
  vehicleBrandId: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleYear: '',
  vehicleVariant: '',
  vehicleRegistration: '',
  vehicleHsnTsn: '',
  bookingTermsAgreed: false,
  privacyPolicyAgreed: false,
  marketingOptInAgreed: false,
  recaptchaToken: '',
  recaptchaVerified: false,
};

const form = (state = initialState, action: any): BookingForm => {
  switch (action.type) {
    case bookingFormActionTypes.UPDATE_BOOKING_FROM: {
      return { ...state, ...action.payload };
    }
    case bookingFormActionTypes.UPDATE_BOOKING_DATE_INDEX: {
      return { ...state, bookingDateIndex: action.payload };
    }
    case bookingFormActionTypes.RESET_BOOKING_DATE: {
      return { ...state, bookingDate: null, bookingDateIndex: null };
    }
    case bookingFormActionTypes.RESET_BOOKING_FORM: {
      return initialState;
    }
    case bookingActionTypes.RESET_BOOKING: {
      return {
        ...initialState,
        title: action.user.title || '',
        firstName: action.user.firstName || '',
        lastName: action.user.lastName || '',
        email: action.user.email || '',
        phone: action.user.phone || '',
        bookingTermsAgreed: action.user.termsAndConditions || false,
        privacyPolicyAgreed: action.user.privacyPolicyAccepted || false,
        marketingOptInAgreed: action.user.marketingDoubleOptIn || false,
        // to be filled for car details
        // vehicleMake: action.user.vehicleMake || '',
        // vehicleModel: action.user.vehicleModel || '',
        // vehicleYear: action.user.vehicleYear || '',
        // vehicleVariant: action.user.vehicleVariant || '',
        // vehicleRegistration: action.user.vehicleRegistration || '',
        // vehicleHsnTsn: action.user.vehicleHsnTsn || '',
      };
    }
    case bookingActionTypes.SUBMIT_BOOKING_SUCCESS:
    case bookingActionTypes.SUBMIT_BOOKING_FAILURE: {
      return {
        ...state,
        recaptchaToken: '',
        recaptchaVerified: false,
      };
    }
    case SearchActionTypes.SET_VEHICLE: {
      return {
        ...state,
        vehicleBrandId: action.searchVehicle.brandId,
        vehicleMake: action.searchVehicle.brandName,
        vehicleModel: action.searchVehicle.makeName,
        vehicleYear: action.searchVehicle.year,
        vehicleVariant: action.searchVehicle.carName,
        vehicleRegistration: action.searchVehicle.vehicleRegistration,
        notes: action.searchVehicle.notes,
      };
    }
    case SearchActionTypes.SET_VEHICLE_MAKE: {
      return {
        ...state,
        vehicleBrandId: action.searchVehicle.brandId,
        vehicleMake: action.searchVehicle.brandName,
        vehicleModel: action.searchVehicle.makeName || '',
        vehicleYear: action.searchVehicle.year || '',
        vehicleVariant: action.searchVehicle.carName || '',
      };
    }
    case SearchActionTypes.SET_VEHICLE_REGISTRATION: {
      return {
        ...state,
        vehicleRegistration: action.vehicleRegistration,
      };
    }
    default:
      return state;
  }
};

export default form;
