import {
  FACILITIES_ENDPOINT,
  MANUFACTURERS_ENDPOINT,
  SERVICES_ENDPOINT,
  TRANSLATIONS_ENDPOINT,
  JOBS_TYPES_ENDPOINT,
  VEHICLE_HSN_TSN_LOOKUP,
  VEHICLE_LOOKUP_GET_MANUFACTURERS,
  VEHICLE_LOOKUP_GET_MODELS,
  VEHICLE_LOOKUP_GET_SERIES_DETAILS,
  WORKSHOP_SEARCH_GRAPHQL_API,
  BOOKING_GRAPHQL_API,
  GOOGLE_GEOCODE_ENDPOINT,
  PRICING_ENDPOINT,
  SALESFORCE_COMMUNITY_URL,
  SALESFORCE_REGISTER_URL,
  SALESFORCE_CALLBACK,
  SALESFORCE_CLIENT_ID,
  SALESFORCE_EXPERIENCE_ID,
  SALESFORCE_LOGOUT_ENDPOINT,
  USER_LOGOUT_ENDPOINT,
  COUNTRIES_ENDPOINT,
  CAR_TYPES_ENDPOINT,
  USER_CIP_LOG_IN_ENDPOINT,
  USER_CIP_LOG_OUT_ENDPOINT,
  CIP_CONSUMER_TOKEN_REFRESH,
  CIP_CONSUMER_ENDPOINT,
  CONSUMER_ENDPOINT,
} from 'constants/urls';

import {
  CARAMA_PUBLIC_FORCE_WORKSHOP_REVIEWS,
  APP_ID,
  AVAILABLE_COUNTRIES,
  AVAILABLE_LOCALES,
  CARAMA_PUBLIC_SUPPRESS_WORKSHOP_REVIEWS,
  CIP_END_SESSION_URL,
  CIP_CLIENT_ID,
  CIP_REVOKE_TOKEN,
} from 'constants/env';

interface AppConfigurations {
  appId: string;
  appName: string;
  isProduction: boolean;
  dateFormat: {
    longDate: string;
    shortDate: string;
    longDay: string;
    abbreviatedDate: string;
  };
  serviceEndpoints: Record<string, string>;
  bookingSearchDistance: number;
  forceWorkshopReviews: string;
  suppressWorkshopReviews: string;
  salesforceEndpoints: Record<string, string>;
  cip: Record<string, string>;
  availableCountries: string[];
  availableLocales: string[];
}

class Config {
  config: AppConfigurations;
  constructor() {
    this.config = {
      appId: APP_ID,
      appName: 'carama',
      isProduction: Boolean(process.env.NODE_ENV === 'production'),
      dateFormat: {
        longDate: 'EEEE, d MMMM yyyy',
        shortDate: 'D MMMM yyyy',
        longDay: 'dddd',
        abbreviatedDate: 'EEE d MMM',
      },
      serviceEndpoints: {
        graphqlApi: WORKSHOP_SEARCH_GRAPHQL_API,
        bookingGraphqlApi: BOOKING_GRAPHQL_API,
        refDataServicesApi: SERVICES_ENDPOINT,
        refDataFacilitiesApi: FACILITIES_ENDPOINT,
        refDataManufacturersApi: MANUFACTURERS_ENDPOINT,
        refDataJobTypesApi: JOBS_TYPES_ENDPOINT,
        refDataCountriesApi: COUNTRIES_ENDPOINT,
        refDataCarTypesApi: CAR_TYPES_ENDPOINT,
        fetchPricingApi: PRICING_ENDPOINT,
        fetchTranslationsApi: TRANSLATIONS_ENDPOINT,
        vehicleHsnTsnLookupApi: VEHICLE_HSN_TSN_LOOKUP,
        vehicleGetManufacturersApi: VEHICLE_LOOKUP_GET_MANUFACTURERS,
        vehicleGetModelsApi: VEHICLE_LOOKUP_GET_MODELS,
        vehicleGetSeriesDetailsApi: VEHICLE_LOOKUP_GET_SERIES_DETAILS,
        googleGeoCodeApi: GOOGLE_GEOCODE_ENDPOINT,
        users: CONSUMER_ENDPOINT,
      },
      cip: {
        cipLogin: USER_CIP_LOG_IN_ENDPOINT,
        cipUsers: CIP_CONSUMER_ENDPOINT,
        cipLogout: USER_CIP_LOG_OUT_ENDPOINT,
        cipEndSession: CIP_END_SESSION_URL,
        cipClientId: CIP_CLIENT_ID,
        cipRevokeToken: CIP_REVOKE_TOKEN,
        cipRefreshToken: CIP_CONSUMER_TOKEN_REFRESH,
      },
      salesforceEndpoints: {
        registerUrl: SALESFORCE_REGISTER_URL,
        termsAndConditions: 'Terms and Conditions',
        privacyPolicy: 'Privacy Policy',
        clientId: SALESFORCE_CLIENT_ID,
        callback: SALESFORCE_CALLBACK,
        communityUrl: SALESFORCE_COMMUNITY_URL,
        logout: USER_LOGOUT_ENDPOINT,
        sfLogout: SALESFORCE_LOGOUT_ENDPOINT,
        experienceId: SALESFORCE_EXPERIENCE_ID,
      },
      bookingSearchDistance: Number(process.env.CARAMA_PUBLIC_BOOKING_SEARCH_DISTANCE),
      forceWorkshopReviews: CARAMA_PUBLIC_FORCE_WORKSHOP_REVIEWS,
      suppressWorkshopReviews: CARAMA_PUBLIC_SUPPRESS_WORKSHOP_REVIEWS,
      availableCountries: AVAILABLE_COUNTRIES as string[],
      availableLocales: AVAILABLE_LOCALES as string[],
    };
  }

  get get(): AppConfigurations {
    Object.freeze(this.config);
    return this.config;
  }
}

export default new Config();
