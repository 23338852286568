import React, { Component, ReactNode } from 'react';

type ErrorProps = {
  children: ReactNode;
};

class ErrorBoundary extends Component<ErrorProps> {
  state = { hasError: false };

  componentDidCatch(error): void {
    console.error(error);
    this.setState({ hasError: true });
  }

  render(): JSX.Element | React.ReactNode {
    // This page is to tackle with errors in our frontend pages.
    if (this.state.hasError) {
      return (
        <>
          <div className="error">
            <h2>Technical Error</h2>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
