import styled from 'styled-components';

import {
  Button as MUIButton,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { Button, Colors } from '@smp/carama-ui-components';
import logoOld from '../../assets/images/logoOld.svg';
import logo from '../../assets/images/logoNew.svg';

import { media } from '../../styles/theme';
import React from 'react';

const { GREEN, DARK_GREEN, ALMOST_BLACK, LINK_GRAY, PALE_GREY, SLATE_GRAY, WHITE } = Colors;

export const HeaderBackgroundOld = styled.div`
  background: ${Colors.GREEN};
  width: 100%;
  top: 50px;
  z-index: 1;

  ${media.desktop`
    top: 0;
  `}
`;

export const HeaderBackground = styled.div`
  position: relative;
  background: ${Colors.WHITE};
  box-shadow: 0 4px 5px 0 rgba(193, 193, 193, 0.5);
  width: 100%;
  top: 0;
  z-index: 1;

  ${media.large`   
    top: 0;  
    box-shadow: 0 -3px 14px 0 rgba(193, 193, 193, 0.5);  
  `}
`;

export const LogoLink = styled.a`
  margin-left: 1.5rem;
  img {
    width: 140px;
    height: auto;
  }
`;

export const Drawer = styled.div`
  z-index: 1001;
  background: #fff;
  width: 100%;
  height: 100vh;
`;

export const StyledHeader = styled.div`
  margin: 0 auto;
  padding: 0.8em 1em;
  max-width: 1440px;
  a {
    text-decoration: none;
    font-size: 0.8em;
  }
`;

export const HeaderLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CaramaLogoOld = styled.div`
  background-size: contain;
  background-image: url(${logoOld});
  background-repeat: no-repeat;
  min-height: 25px;
`;

export const CaramaLogo = styled.div`
  background-size: contain;
  background-position: center;
  background-image: url(${logo});
  background-repeat: no-repeat;
  min-height: 45px;
`;

export const StyledIconButton = styled(IconButton)`
  overflow-wrap: break-word;
  max-width: 100%;
  & > span {
    flex-direction: column;
    font-size: 11px;
    font-family: MuseoSans, sans-serif;
    color: ${ALMOST_BLACK};
  }
`;

export const HeaderLinkOld = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 45%;
    top: 100%;
    width: 0;
    z-index: 1001;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-top: ${(props) =>
      // @ts-ignore
      props.showDownArrow ? `1.5rem solid ${GREEN}` : '1.5rem solid transparent'};
    clear: both;
  }
  a {
    color: white;
    font-family: MuseoSans, sans-serif;
  }

  svg {
    fill: white;
  }
`;

export const HeaderLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  a {
    color: ${DARK_GREEN};
    font-family: MuseoSans, sans-serif;
  }

  svg {
    fill: ${DARK_GREEN};
  }
`;

export const HeaderItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-family: MuseoSansLight, serif;
  left: 0;

  a {
    color: ${SLATE_GRAY};
    font-size: 0.9em;
  }
  p {
    margin-top: 0;
  }
`;

export const HeaderItemChildContainer = styled.div`
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  // border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: #f8f8f8;
  min-height: 13em;
  margin-left: 1.33rem;
  padding-bottom: 12px;
  cursor: pointer;
  text-align: center;

  img {
    flex: 1;
  }
  a {
    color: ${LINK_GRAY};
    text-align: center;
  }
`;

export const HeaderItemChildMobileContainerOld = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.13);
  background-color: #f8f8f8;
  padding: 1em 4px;
  margin-bottom: 0.5em;
`;

export const HeaderDetailsItemMobileOld = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 10%;
  }
  a {
    flex: 1;
    color: ${LINK_GRAY};
    margin-left: 2em;
  }
`;

export const HeaderItemChildMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px rgba(216, 216, 216, 0.19);
  background-color: #fff;
  padding: 0;
  margin: 0;
`;

export const HeaderDetailsItemMobile = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  a {
    display: flex;
    flex: 1;
    color: ${DARK_GREEN};
    padding: 0.5em 0;

    span {
      margin: auto 0;
      padding-left: 1em;
    }
  }
`;

export const detailsStyle = {
  position: 'absolute',
  zIndex: 1000,
  background: WHITE,
  left: 0,
  borderBottom: `solid 1px ${GREEN}`,
  paddingBottom: '2em',
};

export const SelectedHeaderItemContainer = styled.div`
  position: absolute;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 5px 15px 0 rgba(211, 211, 211, 0.5);
  padding: 1rem;
  width: 310px;
  display: inline-block;

  .headerItemDetails {
    font-size: 1.25rem;
    font-weight: normal;
    color: ${DARK_GREEN};
    margin: 1rem;
  }

  .headerChildContainer {
    border-bottom: solid 1px rgba(216, 216, 216, 0.19);
    padding: 0.25rem 0;
  }

  .headerChildItem {
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 40px;
    background-color: rgba(0, 147, 67, 0);
    padding: 0.5rem;
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: rgba(0, 147, 67, 0.17);

      &:after {
        position: absolute;
        content: '';
        top: 28px;
        right: 24px;
        border: solid ${DARK_GREEN};
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }

    img {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      margin: 0 1rem 0 0;
      background-color: #fff;
    }
  }

  .headerChildItemText {
    justify-content: center;
    margin: auto 0;
    color: ${DARK_GREEN};
  }
`;

export const AccountDropdownContainerOld = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
  justify-content: flex-end;
  align-items: center;
`;

export const AccountDropdownContainer = styled.div`
  display: flex;
  max-width: 1440px;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;

  ${media.tablet`
    margin-right: 0;
  `}
`;

export const StyledMenu = withStyles({
  paper: {
    borderRadius: 1,
  },
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuButton = styled.button`
  border: 0;
  padding: 0;
  display: flex;
  outline: none;
  font-size: 1rem;
  height: 100%;
  color: ${SLATE_GRAY};
  cursor: pointer;
  align-items: center;
  div {
    padding: 0 0.3em;
  }
`;

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))(MenuItem);

export const ButtonTabOld = styled(Button)`
  margin-top: 6px;
  border-radius: 3px 3px 0 0;
`;

export const ButtonTab = styled(Button)`
  margin-top: 0.25rem;
  border-radius: 0;
  color: ${SLATE_GRAY};
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  border-bottom: solid 2px transparent;
  height: auto;
  padding: 0.5rem 0 0.25rem 0;
  width: auto;
  margin-left: 1rem;

  &:hover {
    background: none;
    border-bottom: solid 2px ${DARK_GREEN};
  }

  &.active {
    border-bottom: solid 2px ${DARK_GREEN};
  }
`;

export const HeaderButton = styled(Button)`
  height: 29px;
  margin-top: 8px;
  font-size: 13px;
`;

export const MobileNoMargin = styled.div`
  ${media.tablet`
    margin-left: -1em;
    margin-right: -1em;
  `}
  ${media.phone`
    margin-left: -1em;
    margin-right: -1em;
  `};
`;

export const StyledSiteSwitchGrid = styled(Grid)`
  flexwrap: wrap;
  position: fixed;
  bottom: 2em;
  padding: 0 1em;
  '& > .muigrid-item': {
    flex: 0 0 100%;
  }
`;

export const StyledSiteSwitchButtonOld = styled(MUIButton)`
  &.MuiButton-outlinedPrimary.MuiButton-outlinedSizeLarge {
    width: 100%;
    background-color: #fff;
    padding: 7px 21px;
    color: #009343;
    border: 1px solid rgba(0, 147, 67, 0.5);
  }
  & > .MuiButton-label {
    text-transform: none;
    font-weight: bold;
  }
`;

export const StyledSiteSwitchButton = styled(MUIButton)`
  &.MuiButton-text {
    display: block;
    background-color: #fff;
    padding: 0;
    margin: 0 16px;
    color: ${DARK_GREEN};
    justify-content: left;
    border-bottom: solid 1px ${PALE_GREY};
  }
  &&:hover,
  &&:active {
    background-color: #fff;
  }
  & > .MuiButton-label {
    text-transform: none;
    font-weight: normal;
    padding: 1rem 0;
    font-size: 1rem;
  }
`;
