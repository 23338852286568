import { Action } from 'typesafe-actions';
import { ContentState } from '../brochureware-types';
import { ArticleCardDto } from '../../../common-types/articles';

export const MagazineActionTypes = {
  FETCH_MAGAZINE_HUB_SUCCESS: 'FETCH_MAGAZINE_HUB_SUCCESS',
  FETCH_MAGAZINE_HUB_FAILURE: 'FETCH_MAGAZINE_HUB_FAILURE',

  FETCH_MAGAZINE_ARTICLE: 'FETCH_MAGAZINE_ARTICLE',
  FETCH_MAGAZINE_ARTICLE_SUCCESS: 'FETCH_MAGAZINE_ARTICLE_SUCCESS',
  FETCH_MAGAZINE_ARTICLE_FAILURE: 'FETCH_MAGAZINE_ARTICLE_FAILURE',
};

export const MagazineActions = {
  fetchMagazineHubSuccess: (content: Array<ArticleCardDto>) => ({
    type: MagazineActionTypes.FETCH_MAGAZINE_HUB_SUCCESS,
    payload: { content },
  }),
  fetchMagazineHubFailure: (error: any) => ({
    type: MagazineActionTypes.FETCH_MAGAZINE_HUB_FAILURE,
    payload: { error },
  }),

  fetchMagazineArticle: (articleName: string) => ({
    type: MagazineActionTypes.FETCH_MAGAZINE_ARTICLE,
    articleName,
  }),
  fetchMagazineArticleSuccess: (content: ContentState) => ({
    type: MagazineActionTypes.FETCH_MAGAZINE_ARTICLE_SUCCESS,
    payload: { content },
  }),
  fetchMagazineArticleFailure: (error: ContentState) => ({
    type: MagazineActionTypes.FETCH_MAGAZINE_ARTICLE_FAILURE,
    payload: { error },
  }),
};

export interface FetchMagazineArticleAction extends Action {
  articleName: string;
}
