export const actionTypes = {
  FETCH_STICKY_BOOK_BANNER: 'FETCH_STICKY_BOOK_BANNER',
  FETCH_STICKY_BOOK_BANNER_CONTENT_SUCCESS: 'FETCH_STICKY_BOOK_BANNER_CONTENT_SUCCESS',
  FETCH_STICKY_BOOK_BANNER_CONTENT_FAILURE: 'FETCH_STICKY_BOOK_BANNER_CONTENT_FAILURE',
};

export const actions = {
  fetchStickyBookBanner: () => ({
    type: actionTypes.FETCH_STICKY_BOOK_BANNER,
  }),
  fetchStickyBookBannerContentSuccess: (content) => ({
    type: actionTypes.FETCH_STICKY_BOOK_BANNER_CONTENT_SUCCESS,
    payload: { content },
  }),
  fetchStickyBookBannerContentFailure: (error) => ({
    type: actionTypes.FETCH_STICKY_BOOK_BANNER_CONTENT_FAILURE,
    payload: { error },
  }),
};
