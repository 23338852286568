import { getUser } from 'helpers/login';

import Config from '../../config';

import { ApiCaller } from 'helpers';
import { getLocalisedRoute } from 'constants/urls';

const { cip } = Config.get;

export const revokeCIPTokenAPI = (): Promise<any> => {
  const identity = getUser();
  const formData = new URLSearchParams();
  formData.append('client_id', cip.cipClientId);
  formData.append('token', identity.accessToken);
  return ApiCaller.post(`${cip.cipRevokeToken}`, formData, false, {
    'content-type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
  });
};

export const getIDTokenAPI = (): Promise<any> => {
  return ApiCaller.get(`${cip.cipRefreshToken}`);
};

export const logoutAPI = (): Promise<any> => {
  return ApiCaller.post(`${cip.cipLogout}`);
};

export const navigateToEndSessionPageAPI = async (): Promise<any> => {
  const identity = getUser();
  await fetch(
    `${cip.cipEndSession}?id_token_hint=${identity.idToken}&client_id=${
      cip.cipClientId
    }&post_logout_redirect_uri=${window.origin}${getLocalisedRoute('consumer')}${getLocalisedRoute(
      'logout'
    )}`,
    {
      redirect: 'follow',
      mode: 'no-cors',
    }
  );
};
