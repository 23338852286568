import { LookupType, VehicleData, VehicleLookupState } from './types/vehicle-lookup-types';

export const VehicleLookupActionTypes = {
  VehicleChangeLookupType: 'VEHICLE_CHANGE_LOOKUP_TYPE',

  VehicleLookUpFormFieldChange: 'VEHICLE_LOOKUP_FORM_FIELD_CHANGE',

  VehicleLookupSetVehicleData: 'VEHICLE_LOOKUP_SET_VEHICLE_LOOKUP',

  VehicleLookupOnBrandChange: 'VEHICLE_LOOKUP_ON_BRAND_CHANGE',
  VehicleLookupOnModelChange: 'VEHICLE_LOOKUP_ON_MODEL_CHANGE',

  VehicleLookupGetModelsSuccess: 'VEHICLE_LOOKUP_GET_MODELS_SUCCESS',
  VehicleLookupGetSeriesDetailsSuccess: 'VEHICLE_LOOKUP_GET_SERIES_DETAILS_SUCCESS',
};

export const VehicleLookupActions = {
  changeLookupType: (lookupType: LookupType) => ({
    type: VehicleLookupActionTypes.VehicleChangeLookupType,
    lookupType,
  }),

  onFormFieldChange: (id: keyof VehicleLookupState, value: any) => ({
    type: VehicleLookupActionTypes.VehicleLookUpFormFieldChange,
    id,
    value,
  }),

  setVehicleData: (vehicleData: VehicleData) => ({
    type: VehicleLookupActionTypes.VehicleLookupSetVehicleData,
    vehicleData,
  }),

  onBrandChange: (selectedBrand: string) => ({
    type: VehicleLookupActionTypes.VehicleLookupOnBrandChange,
    selectedBrand,
  }),

  onModelChange: (selectedModel: string) => ({
    type: VehicleLookupActionTypes.VehicleLookupOnModelChange,
    selectedModel,
  }),

  getVehicleModelsSuccess: (vehicleModels: string[]) => ({
    type: VehicleLookupActionTypes.VehicleLookupGetModelsSuccess,
    vehicleModels,
  }),

  getSeriesDetailsSuccess: (vehicleSeriesDetails: VehicleData[]) => ({
    type: VehicleLookupActionTypes.VehicleLookupGetSeriesDetailsSuccess,
    vehicleSeriesDetails,
  }),
};
