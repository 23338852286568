import { ApiCaller } from 'helpers';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { consumerActionTypes } from './consumer-actions';
import authActionTypes from '../auth/auth-actions';
import Config from '../../config';
import { ActionWithPayload } from 'common-types/redux';
import { bookingFormActionTypes } from 'modules/booking/actions/booking-form-actions';
import _ from 'lodash';
import { SearchActionTypes } from 'modules/search/search-actions';

const { cip } = Config.get;

export function* updateProfile(): any {
  // prettier-ignore
  yield takeEvery(consumerActionTypes.UPDATE_PROFILE, function*(action: ActionWithPayload<any>) {
    const { payload } = action;
    try {
      if (action) {
        const user = yield call(ApiCaller.patch, `${cip.cipUsers}`, payload.updateConsumerRequest);
        if (payload.props.setIsSubmitted) 
          payload.props.setIsSubmitted(true);
        yield put({
          type: authActionTypes.USER_PROFILE_UPDATE_SUCCESS,
          payload: user,
        });
        yield put({
          type: bookingFormActionTypes.UPDATE_BOOKING_FROM,
          payload: _.pick(user, ['title', 'firstName', 'lastName', 'email', 'phone']),
        });
      }
    } catch (error) {
      payload.props.clearSubmitted();
      if (payload.props.setInProgress) 
        payload.props.setInProgress(false);
        payload.props.setError({
          message: error.response?.data?.message,
          status: error.response?.status,
        });
      yield put({
        type: consumerActionTypes.UPDATE_PROFILE_FAILURE,
        payload: error,
      });
    }
  });
}

export function* updateCar(): any {
  // prettier-ignore
  yield takeEvery(consumerActionTypes.UPDATE_CAR, function* (action: ActionWithPayload<any>) {
    const { payload } = action;

    try {
      if (action) {
        const user = yield call(
          ApiCaller.patch,
          `${cip.cipUsers}`,
          payload.updateConsumerRequest
        );

        payload.props.resetForm();
        
        if (payload.props.setIsSubmitted) payload.props.setIsSubmitted(true);
        yield put({
          type: authActionTypes.UPDATE_CAR_SUCCESS,
          payload: user.cars,
        });

        yield put({
          type: SearchActionTypes.SET_VEHICLE,
          searchVehicle: {
            brandId: user.cars[0].model,
            brandName: user.cars[0].model,
            vehicleRegistration: user.cars[0].registration,
          },
        });
      }
    } catch (error) {
      payload.props.clearSubmitted();
      if (payload.props.setInProgress) payload.props.setInProgress(false);
      payload.props.setErrorText(error.response?.data?.message);
      yield put({
        type: consumerActionTypes.UPDATE_CAR_FAILURE,
        payload: error,
      });
    }
  });
}

export default function* ConsumerSaga(): any {
  yield all([fork(updateProfile)]);
  yield all([fork(updateCar)]);
}
