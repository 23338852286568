import React, { Component, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import store from './store/configureStore';
import history from './helpers/history';
import { theme } from 'styles/theme';
import { ConnectedRouter } from 'connected-react-router';
import { GlobalStyle } from 'styles/global-styles';
import { Route } from 'react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import withAppInsights from './modules/app-insights';
import { PageContent, SiteContainer } from './styles/root-styles';
import LoadingContainer from 'components/Pages/Loading/LoadingContainer';
import adAuthProvider from './modules/auth/ad-auth-provider';
import { AzureAD } from 'react-aad-msal';
import { USE_AD_AUTHENTICATION, ACTIVATE_HOLDING_PAGE } from 'constants/env';
import { getLocalisedRoute } from 'constants/urls';
import AppContainer from './components/Pages/Loading/AppContainer';
import HoldingPage from './HoldingPage';
import authActionTypes from 'modules/auth/auth-actions';
import {
  getUser,
  isUserNameComplete,
  navigateToCIPLoginPage,
  safelyParseJson,
} from 'helpers/login';
import { errorSelector } from 'modules/app/app-selectors';
import ConfirmationDialog from 'components/Pages/Consumer/ConfirmationDialog';
import Spinner from 'components/Spinner/Spinner';
import { TranslatedText } from 'components/Translators';
import { navigateToEndSessionPageAPI } from 'modules/logout/logout-api';

interface ConnectedAppProps {
  useHoldingPage?: boolean;
}

type ErrorProps = {
  callback: Function;
};
const Error = ({ callback }: ErrorProps): JSX.Element => {
  const error = useSelector(errorSelector);
  useEffect(() => {
    if (error?.statusCode == '401') {
      navigateToEndSessionPageAPI();
      store.dispatch(authActionTypes.userLogoutCIP(false));
    }
  }, [error?.statusCode]);

  return (
    (error?.statusCode == '401' || error?.statusCode == '403') && (
      <AppContainer>
        <ConfirmationDialog
          title={<TranslatedText id="unauthorised-error-modal-title" />}
          content={<TranslatedText id="unauthorised-error-modal-content" />}
          okText={<TranslatedText id="unauthorised-error-modal-confirmation" />}
          onClose={() => {
            callback(true);
            // navigateToSfLoginPage();
            // removeUser();
            navigateToCIPLoginPage();
          }}></ConfirmationDialog>
      </AppContainer>
    )
  );
};

function targetView(): void {
  let viewName = window.location.pathname;

  viewName = viewName || 'default';

  // Sanitize viewName
  if (viewName.startsWith('/')) {
    viewName = viewName.substring(1);
  }

  //Remove trailing / if present
  viewName = viewName.replace(/\/+$/, '');

  // Validate if the Target Libraries are available on your website
  if (
    typeof window.adobe != 'undefined' &&
    window.adobe.target &&
    typeof window.adobe.target.triggerView === 'function'
  ) {
    window.adobe.target.triggerView(viewName);
  }
}

class ConnectedApp extends Component<ConnectedAppProps, { showSpinner: boolean }> {
  unregisterHistoryListener: any;

  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
    };
  }

  componentDidMount(): void {
    this.unregisterHistoryListener = history.listen(targetView);
  }

  componentWillUnmount(): void {
    this.unregisterHistoryListener();
  }

  render(): React.ReactNode {
    const { useHoldingPage } = this.props;
    return this.state.showSpinner ? (
      <Spinner isLoading />
    ) : (
      <>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Error
              callback={(show) => {
                this.setState({ showSpinner: show });
              }}
            />
            {/* <SfLogoutIframe /> */}
            <ConnectedRouter history={history}>
              <SiteContainer>
                <GlobalStyle />
                {!useHoldingPage ? (
                  <PageContent>
                    <Route path="/" component={LoadingContainer} />
                  </PageContent>
                ) : (
                  <AppContainer>
                    <HoldingPage />
                  </AppContainer>
                )}
              </SiteContainer>
            </ConnectedRouter>
          </Provider>
        </ThemeProvider>
      </>
    );
  }
}

declare global {
  interface Window {
    onLogin: Function;
    onLogout: Function;
    SFIDWidget: any;
  }
}

const b64DecodeUnicode = (str: string): string => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

const isAfterSalesforceLogin = (): void => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sfResponse = urlParams.get('sfResponse');
  if (sfResponse) {
    const decodedIdentity = JSON.parse(atob(sfResponse));
    const firstName = b64DecodeUnicode(decodedIdentity.firstName);
    const lastName = b64DecodeUnicode(decodedIdentity.lastName);
    const secondUnwrappedSpecialCharsIdentity = {
      ...decodedIdentity,
      // eslint-disable-next-line @typescript-eslint/camelcase
      firstName: firstName,
      // eslint-disable-next-line @typescript-eslint/camelcase
      lastName: lastName,
      // eslint-disable-next-line @typescript-eslint/camelcase
      displayName: `${firstName} ${lastName}`,
      email: b64DecodeUnicode(decodedIdentity.email),
      phone: b64DecodeUnicode(decodedIdentity.phone),
      idToken: decodedIdentity.idToken,
    };
    const consumerIdentity = JSON.stringify(secondUnwrappedSpecialCharsIdentity);
    localStorage.setItem('consumerIdentity', consumerIdentity);
    store.dispatch(authActionTypes.loggedInUser(safelyParseJson(consumerIdentity)));
  } else {
    sessionStorage.setItem('queryParams', queryString);
  }
};

class Root extends Component {
  componentDidMount(): void {
    const consumerIdentity = getUser();
    if (consumerIdentity && consumerIdentity?.idToken && !isUserNameComplete(consumerIdentity)) {
      store.dispatch(authActionTypes.redirectToUpdateProfile());
    }
    if (!window.location.pathname.includes(getLocalisedRoute('logout'))) {
      if (consumerIdentity?.idToken) store.dispatch(authActionTypes.loggedInUser(consumerIdentity));
    }
  }

  render(): React.ReactNode {
    // TODO: this is just temporary for the holding page, once we get Carama back up we can remove this logic
    const { pathname } = window.location;
    isAfterSalesforceLogin();
    const isBasePath =
      pathname === '/' ||
      // the below conditions are fallbacks to ensure holding page is displayed for the home root
      pathname === getLocalisedRoute('consumer') ||
      pathname === `${getLocalisedRoute('consumer')}/` ||
      pathname === `${getLocalisedRoute('consumer')}${getLocalisedRoute('home')}` ||
      pathname === `${getLocalisedRoute('consumer')}${getLocalisedRoute('home')}/`;

    return USE_AD_AUTHENTICATION === 'true' && !isBasePath ? (
      <AzureAD provider={adAuthProvider} forceLogin={true}>
        <ConnectedApp />
      </AzureAD>
    ) : (
      <ConnectedApp useHoldingPage={ACTIVATE_HOLDING_PAGE === 'true'} />
    );
  }
}

export default withAppInsights(Root);
