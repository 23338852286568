import { useState, useLayoutEffect, useEffect } from 'react';

export const useClientRect = (ref, defaultValue = {}): any => {
  const [rect, setRect] = useState(defaultValue);
  useLayoutEffect(() => {
    ref.current && setRect(ref.current.getBoundingClientRect());
  }, [ref.current]);
  return rect;
};

export const useOutsideClick = (ref, callback): void => {
  const handleClick = (e): any => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
};
