import { ApiCaller } from 'helpers';
import get from 'lodash/get';
import { anchorMarkdownConversion, mapAemData } from 'modules/brochureware/helpers';
import { getTermsEndpoint } from 'modules/brochureware/url-helper';

const mapAemTermsData = (data: Record<string, string>): Record<string, string> => {
  const contentFragments = get(data, ':items.root.:items');

  if (!contentFragments) {
    return null;
  }

  const content = {};
  Object.values(contentFragments).forEach((fragments) => {
    const id = fragments['elements']['mainTitle'].value;
    content[id] = {
      ...fragments['elements'],
      sectionPara: anchorMarkdownConversion(fragments['elements'].sectionPara),
    };
  });
  return { ...mapAemData(data), ...content };
};

export const fetchTerms: any = async (languageCode) => {
  const data = await ApiCaller.get(getTermsEndpoint(languageCode));
  return mapAemTermsData(data);
};
