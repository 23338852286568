import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import HeaderLargeOld from './HeaderLargeOld';
import HeaderLarge from './HeaderLarge';
import HeaderMobile from './mobile/HeaderMobile';
import HeaderMobileOld from './mobile/HeaderMobileOld';
import { HeaderItem } from './types/header-types';
import { HeaderBackgroundOld, HeaderBackground } from './header-styles';
import { featureFlagsSelector } from 'modules/feature-flag';
import { useSelector } from 'react-redux';
export interface Props {
  headerItems: Array<HeaderItem>;
  fetchWebsiteHeader: Function;
}

const useStyles = makeStyles(() => ({
  menuButtonItem: {
    fontSize: '0.8em',
    fontFamily: 'MuseoSans,sans-serif',
  },
}));

const Header: React.FC<Props> = (props: Props) => {
  const { headerItems, fetchWebsiteHeader } = props;
  const classes = useStyles();
  const { homepageUpdateTextWithBrandPromise, enhancementsToConsumerHeaderMenu } =
    useSelector(featureFlagsSelector);

  useEffect(() => {
    fetchWebsiteHeader();
  }, [fetchWebsiteHeader]);

  return (
    <>
      {homepageUpdateTextWithBrandPromise && (
        <HeaderBackground>
          <Hidden lgUp>
            <HeaderMobile headerItems={headerItems} />
          </Hidden>
          <Hidden mdDown>
            <HeaderLarge
              headerItems={headerItems}
              classes={enhancementsToConsumerHeaderMenu ? classes : null}
            />
          </Hidden>
        </HeaderBackground>
      )}
      {!homepageUpdateTextWithBrandPromise && (
        <HeaderBackgroundOld>
          <Hidden lgUp>
            <HeaderMobileOld headerItems={headerItems} />
          </Hidden>
          <Hidden mdDown>
            <HeaderLargeOld headerItems={headerItems} />
          </Hidden>
        </HeaderBackgroundOld>
      )}
    </>
  );
};

export default withStyles({})(Header);
