export const searchWorkshopQuery = `
query FindWorkshops($searchParams: FindWorkshopsInputDto!) {
  findAvailableWorkshops(searchParams: $searchParams) {
    id
    name
    images {
      isTitleImage
      url
    }
    availableDates
    address {
      addressLine1
      addressLine2
      addressLine3
      city
      postcode
      state
      formattedAddress
      location
      placeId
    }
    specializations {
      type
      ref
      isCertified
    }
    distance
    allowReviews
    cas
  }
}
`;
