import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { actions } from './header-actions';
import { getIpDataForUserSelectedLocale } from 'modules/app/app-selectors';
import { fetchContentfulHeader } from './header-api';

export function* fetchHeaderContent(): SagaIterator {
  try {
    const state = yield select();
    const headerState = state.brochureWare.header;
    if (headerState.contentFragments && headerState.contentFragments.length > 0) return;

    const language = yield select(getIpDataForUserSelectedLocale);
    const contententfulResponse = yield call(fetchContentfulHeader, language);

    yield put(actions.fetchHeaderContentSuccess(contententfulResponse?.items));
  } catch (e) {
    console.error(e);
    yield put(actions.fetchHeaderContentFailure(e));
  }
}
