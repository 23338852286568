import { createSelector } from 'reselect';
import { AppState } from 'store/AppState';
import { Selector } from 'react-redux';
import { WorkshopsState } from 'modules/workshop/types/workshops-state';
import { SearchState } from 'modules/search/types/search-state';
import { servicesSelector, facilitiesSelector } from 'modules/loading/loading-selectors';
import { getCoordinates } from 'helpers';
import { Address, Specialization, Workshop } from 'common-types/workshop';
import { convertAndPadStart } from 'helpers/common';
import { ReadOnlyService } from 'modules/workshop/types/read-only-service';
import { groupBy, isEmpty } from 'lodash';
import { Reviews } from '../search/types/search';
import { differenceInWeeks } from 'date-fns';

const workshopStateSelector = (state: AppState): WorkshopsState => state.workshops;
const searchStateSelector = (state: AppState): SearchState => state.search;
const globalStateSelector = (state: AppState): AppState => state;

export const getWorkshop: Selector<AppState, Workshop> = createSelector(
  workshopStateSelector,
  (workshopsState) => {
    if (
      !workshopsState.activeWorkshopId ||
      !workshopsState.workshops[workshopsState.activeWorkshopId]
    )
      return undefined;

    return workshopsState.workshops[workshopsState.activeWorkshopId];
  }
);

export const getActiveWorkshopId: Selector<AppState, string> = createSelector(
  workshopStateSelector,
  (workshopsState) => workshopsState.activeWorkshopId
);

export const getWorkshopName: Selector<AppState, string> = createSelector(
  workshopStateSelector,
  (workshopsState) => {
    if (
      !workshopsState.activeWorkshopId ||
      !workshopsState.workshops[workshopsState.activeWorkshopId]
    )
      return undefined;

    return workshopsState.workshops[workshopsState.activeWorkshopId].name;
  }
);

export const getWorkshopCas: Selector<AppState, boolean> = createSelector(
  workshopStateSelector,
  (workshopsState) => {
    if (
      !workshopsState.activeWorkshopId ||
      !workshopsState.workshops[workshopsState.activeWorkshopId]
    )
      return undefined;

    return workshopsState.workshops[workshopsState.activeWorkshopId].cas;
  }
);

export const getWorkshopFetchInProgress: Selector<AppState, boolean> = createSelector(
  getWorkshop,
  (workshop) => workshop === undefined
);

export const getWorkshopPhotos: Selector<AppState, Array<Record<string, any>>> = createSelector(
  getWorkshop,
  (workshop) => {
    if (!workshop?.media) return [];

    return workshop.media.map(({ mediaContent, isTitleImage }) => {
      return {
        mediaContent: mediaContent.contentUrl,
        isTitleImage,
      };
    });
  }
);

export const getWorkshopDescription: Selector<AppState, string> = createSelector(
  getWorkshop,
  (workshop) => {
    if (!workshop) return '';

    return workshop.description;
  }
);

export const getWorkshopSpecializations: Selector<AppState, Array<Specialization>> = createSelector(
  getWorkshop,
  (workshop) => workshop?.specializations || []
);

export const getWorkshopLocation: Selector<AppState, Address> = createSelector(
  getWorkshop,
  (workshop) => {
    if (!workshop) return undefined;

    return {
      formattedAddress: workshop.address.formattedAddress,
      coordinates: getCoordinates(workshop.address.location),
      location: workshop.address.location,
    };
  }
);

export const getWorkshopOpeningHours: Selector<
  AppState,
  Array<{ day: string; hours: string }>
> = createSelector(getWorkshop, (workshop) => {
  if (!workshop) return [];

  const openingHours = workshop.openingHours || [];

  const sundayOpeningHour = openingHours?.filter((openingHour) => openingHour.day === 0) || [];

  const arrangedOpeningHours = [
    ...openingHours?.filter((openingHour) => openingHour.day !== 0),
    ...sundayOpeningHour,
  ];

  return arrangedOpeningHours.map((openingHour) => {
    return {
      day: `day${openingHour.day}`,
      hours: openingHour.openHours
        ? `${convertAndPadStart(openingHour.openHours)}:${convertAndPadStart(
            openingHour.openMinutes
          )} - ${convertAndPadStart(openingHour.closeHours)}:${convertAndPadStart(
            openingHour.closeMinutes
          )}`
        : 'openinghours-closed',
    };
  });
});

export const getWorkshopServices: Selector<AppState, Array<ReadOnlyService>> = createSelector(
  getWorkshop,
  servicesSelector,
  (workshop, servicesReferenceData) => {
    const result = [];

    const groupedServices = groupBy(workshop.services, 'repairTypeId');

    const repairTypes = servicesReferenceData.reduce((acc, repairType) => {
      return {
        ...acc,
        [repairType.id]: {
          translationKey: repairType.translationKey,
          jobTypes: repairType.jobTypes.reduce((resultantJobType, jobType) => {
            return { ...resultantJobType, [jobType.id]: jobType.translationKey };
          }, {}),
        },
      };
    }, {});

    if (isEmpty(repairTypes)) return result;

    for (const repairTypeId in groupedServices) {
      const readOnlyService = {
        translationKey: repairTypes[repairTypeId].translationKey,
        jobTypes: [],
      };

      groupedServices[repairTypeId].forEach((jobType) => {
        readOnlyService.jobTypes.push({
          translationKey: repairTypes[repairTypeId].jobTypes[jobType.jobTypeId],
        });
      });

      result.push(readOnlyService);
    }

    return result;
  }
);

export const getWorkshopFacilities: Selector<AppState, Array<string>> = createSelector(
  getWorkshop,
  facilitiesSelector,
  (workshop, facilitiesReferenceData) => {
    const selectedFacilities = workshop.facilities?.map((facility) => facility.facilityId);

    const selectedFacilitiesFromReferenceData = facilitiesReferenceData.filter(
      (referenceFacility) => selectedFacilities?.includes(referenceFacility.id)
    );
    return selectedFacilitiesFromReferenceData.map((s) => s.translationKey).sort();
  }
);

export const getSingleWorkshopReview: Selector<AppState, Reviews> = createSelector(
  globalStateSelector,
  (state) => {
    const workshopState = getWorkshop(state);
    const workshopId = workshopState && workshopState.id;
    const { workshopReviews } = searchStateSelector(state);

    if (workshopReviews && differenceInWeeks(workshopReviews.fetchDate, new Date()) >= 1) {
      return null;
    }

    const reviews = workshopReviews.reviews?.[workshopId];
    return reviews;
  }
);

export const getWorkshopAvailableDates: Selector<AppState, string[]> = createSelector(
  getWorkshop,
  (workshop) => {
    if (!workshop) return null;

    return workshop.availableDates;
  }
);
