import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { CaramaLogo } from './footer-styles';
import { Hidden } from '@material-ui/core';
import { FooterProps } from './Footer';
import RegionSelectorLink from 'components/RegionSelector/RegionSelectorLink';
import { getLocalisedRoute } from 'constants/urls';

const FooterMiddleRow: React.FC<Partial<FooterProps>> = ({ content }: FooterProps) => {
  return (
    //someone got columns and rows confused here, data-qa values are correct
    <Grid container justifyContent={'space-between'}>
      <Grid item key={1} xs={6} md={1}>
        <Grid container direction={'column'}>
          <Grid item>
            <h4>{get(content, 'columnTitle1.value')}</h4>
          </Grid>

          <Grid item>
            <Link
              to={get(content, 'column1itemLink1.value', '')}
              aria-label={get(content, 'column1itemLinkAriaLabel1.value')}
              data-qa="footer-column1item1">
              {get(content, 'column1itemTitle1.value')}
            </Link>
          </Grid>

          <Grid item>
            <Link
              to={get(content, 'column2itemLink1.value', '')}
              aria-label={get(content, 'column2itemLinkAriaLabel1.value')}
              data-qa="footer-column1item2">
              {get(content, 'column2itemTitle1.value')}
            </Link>
          </Grid>

          <Grid item>
            <Link
              to={get(content, 'column3itemLink1.value', '')}
              aria-label={get(content, 'column3itemLinkAriaLabel1.value')}
              data-qa="footer-column1item3">
              {get(content, 'column3itemTitle1.value')}
            </Link>
          </Grid>

          {/*Correctly named column/row AEM values*/}
          <Grid item>
            <h4>{get(content, 'column1title2.value')}</h4>
          </Grid>

          {/*Correctly named column/row AEM values*/}
          <Grid item>
            <a
              href={'/..' + getLocalisedRoute('workshop')}
              aria-label={get(content, 'column1title2itemLinkAriaLabel1.value')}
              data-qa="footer-column1title2item1">
              {get(content, 'column1title2itemTitle1.value')}
            </a>
          </Grid>
        </Grid>
      </Grid>

      <Grid item key={2} xs={6} md={2}>
        <Grid container direction={'column'}>
          <Grid item>
            <h4>{get(content, 'columnTitle2.value')}</h4>
          </Grid>

          <Grid item>
            <a
              href={'tel:' + get(content, 'column1itemParaLink2.value')}
              dangerouslySetInnerHTML={{
                __html: get(content, 'column1itemPara2.value'),
              }}
            />
          </Grid>

          <Grid item>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: get(content, 'column2itemPara2.value'),
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item key={3} xs={12} md={3}>
        <Grid item>
          <div style={{ marginTop: '1rem' }}>
            <RegionSelectorLink />
          </div>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={3} lg={2}>
          <CaramaLogo />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default FooterMiddleRow;
