// @flow
import React, { useRef, useState } from 'react';
import LanguageIcon from '@material-ui/icons/Language';
import { Button, Colors } from '@smp/carama-ui-components';
import RegionSelectorDialog from './RegionSelectorDialog';

import styled from 'styled-components';
import { TranslatedText } from 'components/Translators';
import { useOutsideClick } from 'helpers/react-helpers';
const { SLATE_GRAY } = Colors;
export const StyledButton = styled.div`
  color: ${SLATE_GRAY};
  button {
    color: ${SLATE_GRAY};
    padding-left: 0;
  }

  svg {
    padding-right: 0.2em;
  }
`;

const RegionSelectorLink = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setOpenModal(false));

  const onClick = (): void => {
    setOpenModal(true);
  };

  const onCloseClick = (): void => {
    setOpenModal(false);
  };

  return (
    <>
      {openModal && <RegionSelectorDialog open={openModal} onCloseClick={onCloseClick} />}
      <StyledButton>
        <Button
          transparent
          bgColor="none"
          foreGroundColor="none"
          hoverColor="none"
          id={'change-language-button'}
          text={<TranslatedText id="change-language-button" />}
          Icon={<LanguageIcon />}
          onClick={onClick}
        />
      </StyledButton>
    </>
  );
};

export default RegionSelectorLink;
