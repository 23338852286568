export const LOCALE_KEY = 'LOCALE';

export const DEFAULT_COUNTRY_CODE = 'TR';
export const DEFAULT_LANGUAGE = 'tr';

export const POPULAR_SERVICES = [
  'jobtype-interim-service',
  'jobtype-oil-change',
  'jobtype-mot',
  'jobtype-battery',
  'jobtype-diagnostics',
];

export const SearchSteps = {
  SEARCH_START: 'search-start',
  SEARCH_CALENDAR: 'search-calendar',
  SEARCH_LOCATION: 'search-location',
  SEARCH_VEHICLE: 'search-vehicle',
};

export const BookingSteps = {
  BOOKING_DATE: 'booking-date',
  PERSONAL_DETAILS: 'booking-personal-details',
  VEHICLE_LOOKUP: 'booking-vehicle-lookup',
  BOOKING_SUMMARY: 'booking-summary',
  BOOKING_CONFIRMATION: 'booking-confirmation',
};

export const REPAIRTYPE_UNKNOWN = 'repairtype-unknown';
export const REPAIRTYPE_UNKNOWN_CONSUMER = 'repairtype-unknown-consumer';

export const JOBTYPE_DIAGNOSTICS = 'jobtype-diagnostics';
export const JOBTYPE_UNKNOWN_CONSUMER = 'jobtype-unknown-consumer';

export const HSN_TSN_REGEX = /^\d{4}\/[a-zA-Z0-9.-]{3}$/;
export const PHONE_NUMBER_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const UPDATED_PHONE_NUMBER = /^\+\d{1,2}[0-9]\d{0,10}$/;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9 ]*$/;
export const NUMERIC_REGEX = /^\d+$/;
export const MAX_STRING_LENGTH = 26;

export const COOKIE_POLICY_KEY = 'COOKIE_POLICY';

export const COOKIE_POLICY_CONSTANTS = {
  policyAccepted: 'policyAccepted',
  performanceCookies: 'performanceCookies',
  marketingCookies: 'marketingCookies',
};

export const DEFAULT_COOKIE_POLICY = {
  [COOKIE_POLICY_CONSTANTS.policyAccepted]: false,
  [COOKIE_POLICY_CONSTANTS.performanceCookies]: false,
  [COOKIE_POLICY_CONSTANTS.marketingCookies]: false,
};

export const CONTACT_US_EMAIL = 'carama@castrol.com';

export const VEHICLE_CONSTANTS = {
  regMaxLength: 20,
  vinLength: 17,
  currentMileageMax: 999999,
  annualMileageMax: 999999,
};

export const DEFAULT_SEARCH_LOCATION = {
  lng: 28.9783589,
  lat: 41.0082376,
  formattedAddress: 'İstanbul, Turkey',
};

export const SEARCH_DAYS_IN_FUTURE = 30;

export const WORKSHOP_SORT_BY = [
  { name: 'workshop-sort-by-recommended', value: 'RECOMMENDED' },
  { name: 'workshop-sort-by-distance', value: 'DISTANCE' },
];
