import { Colors } from '@smp/carama-ui-components';
import styled from 'styled-components';
import logo from '../../assets/images/logoNew.svg';
import { media } from 'styles/theme';

const { SLATE_GRAY } = Colors;

export const SocialMediaContainer = styled.div`
  img {
    margin: 0 10px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 27px;
  }

  @media (max-width: 768px) {
    img:first-child {
      margin-left: 0;
    }
  }
`;

export const StyledFooter = styled.footer`
  max-width: 1440px;
  margin: 0 auto;
  font-size: 15px;
  padding: 2em 1em 1em;
  h4 {
    font-size: 1.1em;
    color: ${SLATE_GRAY};
    font-weight: 700;
  }

  a {
    color: ${SLATE_GRAY};
    font-family: MuseoSans;
    font-size: 1rem;
    line-height: 2rem;
    margin-bottom: 1rem;

    &:visited {
      color: ${SLATE_GRAY};
    }
  }

  form {
    width: 80%;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0;
    form {
      width: 100%;
      padding: 0;
    }
  }
`;

export const StyledFooterInnerContent = styled.div`
  ${media.tablet` {
        padding: 1rem;
    }`}
`;

export const CaramaLogo = styled.div`
  margin: 30px 0;
  background-size: contain;
  background-image: url(${logo});
  background-repeat: no-repeat;
  height: 64px;
  width: 185px;
`;

export const FooterDivider = styled.div`
  padding-top: 2em;
  padding-bottom: 2em;

  ${media.tablet` {
       padding-top: 1em;
       padding-bottom: 1em;
    }`}
`;
