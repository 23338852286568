import produce from 'immer';
import { WorkshopsState } from 'modules/workshop/types/workshops-state';
import { WorkshopActionTypes } from 'modules/workshop/workshop-actions';
import {
  FetchAvailableDatesSuccess,
  FetchWorkshopAction,
  FetchWorkshopSuccessfulAction,
} from 'modules/workshop/types/workshop-actions-types';

const initialState: WorkshopsState = { workshops: {}, activeWorkshopId: '', fetchInProgress: true };

export default (
  state = initialState,
  action: FetchWorkshopSuccessfulAction & FetchWorkshopAction & FetchAvailableDatesSuccess
): WorkshopsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case WorkshopActionTypes.FETCH_WORKSHOP: {
        const { workshopId } = action;
        draft.activeWorkshopId = workshopId;
        return;
      }

      case WorkshopActionTypes.SET_ACTIVE_WORKSHOP_ID: {
        const { workshop } = action;

        if (workshop?.id) {
          draft.activeWorkshopId = workshop.id;
          draft.workshops[workshop.id] = workshop;
        }
        return;
      }

      case WorkshopActionTypes.FETCH_WORKSHOP_SUCCESS: {
        const { workshop } = action;
        draft.workshops[workshop.id] = workshop;

        draft.fetchInProgress = false;
        return;
      }

      case WorkshopActionTypes.FETCH_AVAILABLE_DATES_SUCCESS: {
        const { dates } = action;
        draft.workshops[draft.activeWorkshopId].availableDates = dates;

        return;
      }

      default:
        return state;
    }
  });
