import { Workshop } from 'common-types/workshop';

export const WorkshopActionTypes = {
  SET_ACTIVE_WORKSHOP_ID: 'SET_ACTIVE_WORKSHOP_ID',
  FETCH_WORKSHOP: 'FETCH_WORKSHOP',
  FETCH_WORKSHOP_SUCCESS: 'FETCH_WORKSHOP_SUCCESS',
  FETCH_WORKSHOP_FAILURE: 'FETCH_WORKSHOP_FAILURE',
  FETCH_AVAILABLE_DATES: 'FETCH_AVAILABLE_DATES',
  FETCH_AVAILABLE_DATES_SUCCESS: 'FETCH_AVAILABLE_DATES_SUCCESS',
};

export const WorkshopActions = {
  setActiveWorkshopId: (workshop: Workshop) => ({
    type: WorkshopActionTypes.SET_ACTIVE_WORKSHOP_ID,
    workshop,
  }),

  fetchWorkshop: (workshopId: string) => ({
    type: WorkshopActionTypes.FETCH_WORKSHOP,
    workshopId,
  }),

  fetchWorkshopSuccessful: (workshop) => ({
    type: WorkshopActionTypes.FETCH_WORKSHOP_SUCCESS,
    workshop,
  }),

  fetchWorkshopFailure: (error) => ({
    type: WorkshopActionTypes.FETCH_WORKSHOP_FAILURE,
    error,
  }),

  fetchAvailableDates: () => ({
    type: WorkshopActionTypes.FETCH_AVAILABLE_DATES,
  }),

  fetchAvailableDatesSuccess: (dates: string[]) => ({
    type: WorkshopActionTypes.FETCH_AVAILABLE_DATES_SUCCESS,
    dates,
  }),
};
