import searchRoutes from 'modules/search/search.routes';
import workshopRoutes from 'modules/workshop/workshop-routes';
import brochurewareRoutes from 'modules/brochureware/brouchureware-routes';
import indexRoutes from './index-routes';
import fallbackRoutes from './fallback-route';
import bookingRoutes from 'modules/booking/booking.routes';
import consumerRoutes from 'modules/consumer/consumer.routes';

type Route = {
  path: string;
  ViewComponent: any;
  exact?: boolean;
  restricted?: boolean;
  displayLayout?: boolean;
};
const appRoutes: Route[] = [
  ...indexRoutes,
  ...searchRoutes,
  ...workshopRoutes,
  ...brochurewareRoutes,
  ...bookingRoutes,
  ...consumerRoutes,
  ...fallbackRoutes,
];
export default appRoutes;
