import { call, put, select } from 'redux-saga/effects';
import { ImpressumActions } from './impressum-actions';
import { fetchImpressum } from './impressum-api';
import { isEmpty } from 'lodash';
import { AppState } from 'store/AppState';
import { SagaIterator } from 'redux-saga';
import { getIpDataForUserSelectedLocale } from '../../app/app-selectors';

export function* fetchImpressumSaga(): SagaIterator {
  try {
    const state = (yield select()) as AppState;
    const impressumState = state.brochureWare.impressum;
    if (!isEmpty(impressumState.content)) return;
    const language = yield select(getIpDataForUserSelectedLocale);

    if (language) {
      const content = yield call(fetchImpressum, language);
      yield put(ImpressumActions.fetchImpressumSuccess(content));
    }
  } catch (e) {
    yield put(ImpressumActions.fetchImpressumFailure(e));
  }
}
