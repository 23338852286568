import { all, put, fork, takeEvery, call, select } from 'redux-saga/effects';

import { WorkshopActions, WorkshopActionTypes } from './workshop-actions';
import { SagaIterator } from 'redux-saga';
import { apiFetchAvailibleDates, apiFetchWorkshop } from 'modules/workshop/workshop-api';
import { FetchWorkshopAction } from './types/workshop-actions-types';
import { getActiveWorkshopId } from './workshop-selectors';
import { SEARCH_DAYS_IN_FUTURE } from 'constants/general';
import { addDays } from 'date-fns';

export function* watcherFetchWorkshop(): SagaIterator {
  yield takeEvery(WorkshopActionTypes.FETCH_WORKSHOP, function* (action: FetchWorkshopAction) {
    try {
      const { workshopId } = action;
      const workshop = yield call(apiFetchWorkshop, workshopId);
      yield put(WorkshopActions.setActiveWorkshopId(workshop));
      yield put(WorkshopActions.fetchWorkshopSuccessful(workshop));
    } catch (e) {
      yield put(WorkshopActions.fetchWorkshopFailure(e));
    }
  });
}

export function* watcherFetchAvailableDates(): SagaIterator {
  yield takeEvery(WorkshopActionTypes.FETCH_AVAILABLE_DATES, function* () {
    try {
      const workshopId = yield select(getActiveWorkshopId);
      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);

      const availableDates = yield call(
        apiFetchAvailibleDates,
        today,
        addDays(today, SEARCH_DAYS_IN_FUTURE),
        workshopId
      );

      yield put(WorkshopActions.fetchAvailableDatesSuccess(availableDates.availableDates));
    } catch (e) {
      yield put(WorkshopActions.fetchAvailableDatesSuccess([]));
    }
  });
}

export default function* WorkshopSaga(): SagaIterator {
  yield all([fork(watcherFetchWorkshop), fork(watcherFetchAvailableDates)]);
}
