import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
  id: string;
};

const HtmlSpan: React.FC<Props> = ({ id }: Props) => {
  const intl = useIntl();
  const message = intl.formatMessage({
    id: id,
    defaultMessage: id,
  });
  return id ? <span dangerouslySetInnerHTML={{ __html: message }} /> : null;
};

export default HtmlSpan;
