import React, { useEffect } from 'react';
import TranslatedText from 'components/Translators/TranslatedText';
import HtmlSpan from 'components/Translators/HtmlSpan';
import Grid from '@material-ui/core/Grid';
import logo from './assets/images/logo.svg';
import logoFooter from './assets/images/caramalogo-footer.png';
import styled from 'styled-components';
import holdingPage from 'assets/images/holding-page.jpg';
import RegionSelectorLink from 'components/RegionSelector/RegionSelectorLink';
import { media } from 'styles/theme';
import Hidden from '@material-ui/core/Hidden';
import { useDispatch } from 'react-redux';
import { LoadingActions } from 'modules/loading/loading-actions';

const HoldingWrapper = styled(Grid)`
  width: 100%;

  * {
    box-sizing: border-box;
  }
`;

const HoldingMain = styled(Grid)`
  max-height: 100vh;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    max-height: initial;

    @media (max-width: 1250px) {
      width: initial;
    }

    ${media.tablet`
      width: initial;
      max-height: 100vh;
  `};
  }
`;

const HoldingHeader = styled(Grid)`
  background: #009343;
  padding: 35px;
`;

const HoldingContentHero = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 40px;
  position: absolute;
  text-align: center;
  max-width: 530px;
  right: 5%;

  ${media.tablet`
    right: initial;
    width: 320px;
    padding: 15px 20px 20px 20px;
  `};

  h1 {
    font-size: 36px;
    margin-bottom: 1em;
    color: #000;

    ${media.tablet`
      font-size: 24px;
    `};
  }

  p {
    font-size: 18px;
    color: #000;
    line-height: 27px;

    ${media.tablet`
      font-size: 13px;
      line-height: 19px;
    `};
  }
`;

const HoldingFooter = styled(Grid)`
  background: #fff;
  padding: 35px 40px;

  img {
    height: 40px;
    margin-top: 20px;
  }

  ${media.tablet`
    font-size: 16px;
    line-height: 23px;
    flex-direction: column-reverse;
    padding: 0px 0px 40px 0px;
  `};

  .MuiGrid-root {
    display: flex;
    align-items: center;

    ${media.tablet`
      padding: 5px 20px;
  `};
  }
`;

const RegionSelectorItem = styled(Grid)`
  && {
    ${media.tablet`
      border-bottom: 1px solid #B3C6C4;
      margin-bottom: 20px;
      padding: 20px;
  `};
  }
`;

const FooterCopyright = styled.p`
  margin: 0px;
`;

const HoldingPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LoadingActions.fetchReferenceData());
  }, []);

  return (
    <HoldingWrapper container>
      <HoldingHeader item xs={12} container>
        <img src={logo} alt="Carama Logo" />
      </HoldingHeader>
      <HoldingMain item xs={12} container alignItems="center" justifyContent="center">
        <img src={holdingPage} alt="Carama Logo" />
        <HoldingContentHero data-qa="holding-page-content-section">
          <h1>
            <TranslatedText id="holding-page-header" />
          </h1>
          <p>
            <HtmlSpan id="holding-page-content" />
          </p>
          <p>
            <HtmlSpan id="holding-page-content-bottom" />
          </p>
        </HoldingContentHero>
      </HoldingMain>
      <HoldingFooter item xs={12} container justifyContent="space-between">
        <Hidden smUp>
          <Grid item xs={12}>
            <img src={logoFooter} alt="Carama Logo" />
          </Grid>
        </Hidden>
        <Grid data-qa="holding-page-footer" item xs={12} sm="auto">
          <FooterCopyright>
            <TranslatedText id="holding-page-footer-copyright" />
          </FooterCopyright>
        </Grid>
        <RegionSelectorItem item xs={12} sm="auto">
          <RegionSelectorLink />
        </RegionSelectorItem>
      </HoldingFooter>
    </HoldingWrapper>
  );
};

export default HoldingPage;
