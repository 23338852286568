import gql from 'graphql-tag';
import Config from '../../config';
import { ApiCaller } from 'helpers';
import { BookingForm } from './booking-types';
import { removeSpaces } from '../../helpers/common';
import { getSelectedLocale } from '../../helpers/locale';
import { BookingDto } from '../../common-types/booking-types';
import {
  cancelBookingQuery,
  createBookingQuery,
  getBookingQuery,
} from '../../constants/queries/booking-queries';

const { serviceEndpoints, appId } = Config.get;

export const apiBookWorkshop = (
  workshopId: string,
  booking: Record<keyof BookingForm, any>,
  jobs: string[],
  user: Record<string, any>
): Promise<BookingDto> => {
  const {
    title,
    bookingDate,
    firstName,
    lastName,
    email,
    phone,
    notes,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleVariant,
    vehicleRegistration,
    vehicleHsnTsn,
    bookingTermsAgreed,
    privacyPolicyAgreed,
    marketingOptInAgreed,
    recaptchaToken,
  } = booking;
  const headers =
    user && user.idToken
      ? { 'x-app-id': appId, authorization: `Bearer ${user.idToken}` }
      : { 'x-app-id': appId };
  return ApiCaller.graphQlQuery(
    serviceEndpoints.bookingGraphqlApi,
    gql`
      ${createBookingQuery}
    `,
    {
      booking: {
        workshopId: workshopId,
        scheduledDate: bookingDate,
        language: getSelectedLocale(),
        jobs: jobs,
        car: {
          registration: vehicleRegistration,
          make: vehicleMake,
          model: vehicleModel,
          year: parseInt(vehicleYear) || null,
          variant: vehicleVariant,
          hsnTsn: removeSpaces(vehicleHsnTsn),
        },
        customer: {
          title: title,
          firstName: firstName,
          lastName: lastName,
          email: email,
          telNo: phone,
          notes: notes,
        },
      },
      bookingTermsAgreed,
      privacyPolicyAgreed,
      marketingOptInAgreed,
      recaptchaToken,
    },
    'book',
    headers
  );
};

export const fetchBookingApi = (bookingId: string): Promise<any> => {
  return ApiCaller.graphQlQuery(
    serviceEndpoints.bookingGraphqlApi,
    gql`
      ${getBookingQuery}
    `,
    {
      bookingId,
    },
    'getBooking',
    { 'x-app-id': appId }
  );
};

export const cancelBookingApi = (bookingId: string): Promise<any> => {
  return ApiCaller.graphQlQuery(
    serviceEndpoints.bookingGraphqlApi,
    gql`
      ${cancelBookingQuery}
    `,
    {
      bookingId,
    },
    'cancelBooking',
    { 'x-app-id': appId }
  );
};
