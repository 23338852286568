import styled from 'styled-components';
import { Colors } from '@smp/carama-ui-components';
import { media } from '../../styles/theme';

const { ALMOST_BLACK } = Colors;

export const StyledCookieBanner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  font-size: 1em;
  padding: 1em;

  h2 {
    margin-top: 0;
    ${media.phone`
      font-size: 1.25em;
    `}
  }

  p.cookieBannerDescription {
    ${media.phone`
      margin-block-end: 0;
      font-size: 0.92rem;
      line-height: 18px;
    `}
  }

  a.manageCookiesLink {
    color: ${ALMOST_BLACK};
    padding-bottom: 0.3rem;
    text-decoration: underline;
    font-size: 0.8em;

    &:visited {
      color: ${ALMOST_BLACK};
      text-decoration-color: ${ALMOST_BLACK};
    }
  }
`;
