import { ContentState } from '../brochureware-types';

export const FaqActionTypes = {
  FETCH_FAQ_SUCCESS: 'FETCH_FAQ_SUCCESS',
  FETCH_FAQ_FAILURE: 'FETCH_FAQ_FAILURE',
};

export const FaqActions = {
  fetchFaqSuccess: (content: ContentState) => ({
    type: FaqActionTypes.FETCH_FAQ_SUCCESS,
    payload: { content },
  }),
  fetchFaqFailure: (error: ContentState) => ({
    type: FaqActionTypes.FETCH_FAQ_FAILURE,
    payload: { error },
  }),
};
