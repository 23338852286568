import AsyncComponent from 'helpers/AsyncComponent';
import { BOOKING, getLocalisedRoute } from '../../constants/urls';

const routes = [
  {
    path: `${BOOKING}/:bookingId`,
    restricted: false,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/BookForm/BookingConfirmationContainer')
    ),
  },
  {
    path: getLocalisedRoute('booking'),
    restricted: false,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/BookForm/BookingConfirmationContainer')
    ),
  },
  {
    path: getLocalisedRoute('bookingManagement'),
    restricted: false,
    exact: true,
    showCancelInfo: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/BookForm/BookingConfirmationContainer')
    ),
  },
  {
    path: getLocalisedRoute('bookingFailed'),
    restricted: false,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/BookForm/BookingConfirmationContainer')
    ),
  },
  {
    path: getLocalisedRoute('book'),
    restricted: false,
    exact: true,
    ViewComponent: AsyncComponent(() => import('../../components/Pages/Book/Book')),
  },
];

export default routes;
