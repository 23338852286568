import { createBrowserHistory } from 'history';
import { getLocalisedRoute } from 'constants/urls';

const history = createBrowserHistory({ basename: getLocalisedRoute('consumer') });

export const navigateToPage = (page: string): void => {
  history.push(page);
};

export const location = window.location.href;
export const origin = window.location.origin;

export const urlSoftPushState = (url: string): void => {
  window.history.pushState({}, null, url);
};

export default history;
