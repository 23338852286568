import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel, { ExpansionPanelProps } from '@material-ui/core/ExpansionPanel';
import * as React from 'react';

const defaultStyles = {
  root: {
    boxShadow: 'none',
  },
};

const ExpansionPanel = (styles = defaultStyles): React.ComponentType<ExpansionPanelProps> => {
  return withStyles(styles)(MuiExpansionPanel);
};

export default ExpansionPanel;
