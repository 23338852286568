import Config from '../../config';
import { ApiCaller } from 'helpers';

const { serviceEndpoints } = Config.get;

export const apiFetchServices = (): Promise<any> => {
  return ApiCaller.get(`${serviceEndpoints.refDataServicesApi}`);
};
export const apiFetchFacilities = (): Promise<any> => {
  return ApiCaller.get(`${serviceEndpoints.refDataFacilitiesApi}`);
};
export const apiFetchManufacturers = (): Promise<any> => {
  return ApiCaller.get(`${serviceEndpoints.refDataManufacturersApi}`);
};
export const apiFetchTranslations = (languageCode: string): Promise<any> => {
  return ApiCaller.get(`${serviceEndpoints.fetchTranslationsApi}/${languageCode}`);
};
export const apiFetchCarTypes = (): Promise<any> => {
  return ApiCaller.get(`${serviceEndpoints.refDataCarTypesApi}`);
};
