import { call, put, select } from 'redux-saga/effects';

import { apiFetchHomeContent } from 'modules/brochureware/home/home-api';
import { HomeActions } from 'modules/brochureware/home/home-actions';
import { getIpDataForUserSelectedLocale } from '../../app/app-selectors';
import { SagaIterator } from 'redux-saga';

export function* fetchHomeContentSaga(): SagaIterator {
  try {
    const language = yield select(getIpDataForUserSelectedLocale);

    if (language) {
      const content = yield call(apiFetchHomeContent, language);
      yield put(HomeActions.fetchHomeContentSuccess(content));
    }
  } catch (e) {
    yield put(HomeActions.fetchHomeContentFailure(e));
  }
}
