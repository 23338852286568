//@flow
import { getUser } from 'helpers/login';
import { AppActions } from 'modules/app/actions';
import { getAuth } from 'modules/auth/auth-selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import store from 'store/configureStore';

type AuthenticatedProps = {
  render: any;
  path?: string;
  exact?: boolean;
};

const AuthenticatedRoute = (props: AuthenticatedProps): JSX.Element => {
  const user = getUser();
  const isAuthenticated: boolean =
    useSelector(getAuth).isAuthenticated || (user?.idToken && user?.accessToken);
  if (!isAuthenticated) {
    store.dispatch(AppActions.forbidden());
    return <></>;
  }
  return <Route {...props} />;
};

export default AuthenticatedRoute;
