import { connect } from 'react-redux';

import { getBusinessFooterContent, getHistryState } from '../../modules/footer/footer-selectors';
import { actions } from '../../modules/footer/footer-actions';
import CaramaFooter, { FooterProps } from './Footer';
import { AppState } from 'store/AppState';

const mapStateToProps = (state: AppState): Partial<FooterProps> => {
  return {
    content: getBusinessFooterContent(state),
    historyState: getHistryState(state),
  };
};

export default connect(mapStateToProps, { fetchWebsiteFooter: actions.fetchBrochureFooter })(
  CaramaFooter
);
