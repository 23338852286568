import {
  initialize as ldInitialise,
  LDClient,
  LDUser,
  LDOptions,
  LDFlagSet,
} from 'launchdarkly-js-client-sdk';
import { AnyAction, Dispatch } from 'redux';
import camelCase from 'lodash.camelcase';
import { featureFlagActions } from './feature-flag-actions';

const ldUser: LDUser = { anonymous: true };
const ldOptions: LDOptions = { sendEventsOnlyForVariation: true };

class LaunchDarklyHelper {
  private ldClient: LDClient;
  private flagKeyMap: { [camelCaseKey: string]: string } = {};

  public initialise(clientId: string, dispatch: Dispatch<AnyAction>): void {
    this.ldClient = ldInitialise(clientId, ldUser, ldOptions);

    this.ldClient.on('ready', () => {
      const flags: LDFlagSet = this.ldClient.allFlags();
      const flagValues = {};

      for (const flag in flags) {
        const camelCaseFlag = camelCase(flag);
        this.flagKeyMap[camelCaseFlag] = flag;

        flagValues[camelCaseFlag] = flags[flag];

        this.ldClient.on(`change:${flag}`, (newValue) => {
          const newFlagValues = {
            [camelCaseFlag]: newValue,
          };

          dispatch(featureFlagActions.featureFlags(newFlagValues));
        });
      }

      dispatch(featureFlagActions.featureFlags(flagValues));
    });
  }

  public logEvaluation(camelCaseFlagKey: string): void {
    if (!this.ldClient || !Object.prototype.hasOwnProperty.call(this.flagKeyMap, camelCaseFlagKey))
      return;

    this.ldClient.variation(this.flagKeyMap[camelCaseFlagKey]);
  }
}

const launchDarklyHelper: LaunchDarklyHelper = new LaunchDarklyHelper();

export default launchDarklyHelper;
