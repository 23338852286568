import React, { useState, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { getAemMediaUrl } from '../../modules/brochureware/helpers';
import { Link } from 'react-router-dom';
import { useOutsideClick } from '../../helpers/react-helpers';
import {
  detailsStyle,
  HeaderItemChildContainer,
  HeaderItemDetails,
  HeaderLinkOld,
  HeaderLinksContainer,
  CaramaLogoOld,
  StyledHeader,
} from './header-styles';
import { HeaderItem } from './types/header-types';
import { getLocalisedRoute } from '../../constants/urls';
import { navigateToPage } from '../../helpers/history';
import { TranslatedText } from 'components/Translators';

interface Props {
  headerItems: Array<HeaderItem>;
}

const HeaderLargeOld: React.FC<Props> = ({ headerItems }: Props) => {
  const headerNode = useRef();
  const selectedHeaderItemGrid = useRef<HTMLDivElement>();
  const [selectedHeaderItem, setSelectedHeaderItem] = useState(undefined);
  useOutsideClick(headerNode, () => setSelectedHeaderItem(undefined));

  const handleHeaderItemMouseLeave = function (e): void {
    const selectHeaderItem = selectedHeaderItemGrid?.current;
    if (
      e.relatedTarget?.contains &&
      !e.relatedTarget.contains(e.currentTarget) &&
      selectHeaderItem
    ) {
      if (!selectHeaderItem.contains(e.relatedTarget)) {
        setSelectedHeaderItem(undefined);
      }
    }
  };

  // @ts-ignore
  return (
    <>
      <StyledHeader
        style={{ display: 'flex', flexDirection: 'column' }}
        ref={headerNode}
        onMouseLeave={handleHeaderItemMouseLeave}>
        <HeaderLinksContainer>
          <Grid item md={3}>
            <Link to={getLocalisedRoute('home')}>
              <CaramaLogoOld />
            </Link>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              width: '75%',
              marginLeft: '16px',
            }}>
            <Grid item md={3}>
              <HeaderLinkOld>
                <Link to={getLocalisedRoute('services')} data-qa="menuItem-servicesOffered">
                  <TranslatedText id="services-offered-tab" />
                </Link>
              </HeaderLinkOld>
            </Grid>
            <Grid item md={3}>
              <HeaderLinkOld>
                <Link to={getLocalisedRoute('articles')} data-qa="menuItem-articles">
                  <TranslatedText id="magazine-title-tab" />
                </Link>
              </HeaderLinkOld>
            </Grid>

            {headerItems.map((headerItem, index) => (
              <Grid data-qa={`menuItem-${index}`} key={headerItem.itemText} item md={3}>
                <HeaderLinkOld
                  onMouseEnter={() => {
                    setSelectedHeaderItem(headerItem);
                  }}
                  onMouseLeave={handleHeaderItemMouseLeave}
                  // @ts-ignore
                  itemText={headerItem.itemText}
                  showDownArrow={
                    selectedHeaderItem && selectedHeaderItem.itemText === headerItem.itemText
                  }>
                  <a>{headerItem.itemText}</a>
                  <ArrowDropDown />
                </HeaderLinkOld>
              </Grid>
            ))}
          </Grid>
        </HeaderLinksContainer>
      </StyledHeader>
      {selectedHeaderItem && (
        // @ts-ignore
        <Grid
          ref={selectedHeaderItemGrid}
          onMouseLeave={handleHeaderItemMouseLeave}
          container
          // @ts-ignore
          style={detailsStyle}>
          <Grid container item style={{ margin: '0 auto', width: 1440, marginTop: '1.5em' }}>
            <Grid item md={4}>
              <HeaderItemDetails>
                <h1>{selectedHeaderItem.sectionTitle}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: selectedHeaderItem.sectionDescription,
                  }}
                />

                <Link to={selectedHeaderItem.sectionHref || ''}>
                  {selectedHeaderItem.sectionHrefText}
                </Link>
              </HeaderItemDetails>
            </Grid>
            <Grid item md={2} />

            {selectedHeaderItem.children
              .filter((headerChild) => !isEmpty(headerChild.href))
              .map((headerChild, index) => (
                <Grid
                  data-qa="header-child-container"
                  data-gtm-tag={headerChild.altText.toLowerCase().replace(/\s/g, '-')}
                  key={index}
                  item
                  md={2}>
                  <div>
                    <HeaderItemChildContainer
                      onClick={() => {
                        navigateToPage(headerChild.href);
                      }}
                      data-qa={`subMenuItem-${index}`}>
                      <img src={getAemMediaUrl(headerChild.imagePath)} alt={'header-detail-icon'} />
                      <span>{headerChild.hrefText}</span>
                    </HeaderItemChildContainer>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default HeaderLargeOld;
