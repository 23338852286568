export const ServicesActionTypes = {
  FETCH_SERVICES_CONTENT: 'FETCH_SERVICES_CONTENT',
  FETCH_SERVICES_CONTENT_SUCCESS: 'FETCH_SERVICES_CONTENT_SUCCESS',
  FETCH_SERVICES_CONTENT_FAILURE: 'FETCH_SERVICES_CONTENT_FAILURE',
};
export const ServicesActions = {
  fetchServices: () => ({
    type: ServicesActionTypes.FETCH_SERVICES_CONTENT,
  }),
  fetchServicesSuccess: (content) => ({
    type: ServicesActionTypes.FETCH_SERVICES_CONTENT_SUCCESS,
    payload: { content },
  }),
  fetchServicesFailure: (error) => ({
    type: ServicesActionTypes.FETCH_SERVICES_CONTENT_FAILURE,
    payload: { error },
  }),
};

export const ServiceTypeActionTypes = {
  FETCH_SERVICE_TYPE_CONTENT: 'FETCH_SERVICE_TYPE_CONTENT',
  FETCH_SERVICE_TYPE_CONTENT_SUCCESS: 'FETCH_SERVICE_TYPE_CONTENT_SUCCESS',
  FETCH_SERVICE_TYPE_CONTENT_FAILURE: 'FETCH_SERVICE_TYPE_CONTENT_FAILURE',
};

export const ServiceTypeActions = {
  fetchServiceType: (serviceType: string) => ({
    type: ServiceTypeActionTypes.FETCH_SERVICE_TYPE_CONTENT,
    serviceType,
  }),
  fetchServiceTypeSuccess: (content) => ({
    type: ServiceTypeActionTypes.FETCH_SERVICE_TYPE_CONTENT_SUCCESS,
    payload: { content },
  }),
  fetchServiceTypeFailure: (error) => ({
    type: ServiceTypeActionTypes.FETCH_SERVICE_TYPE_CONTENT_FAILURE,
    payload: { error },
  }),
};
