import { BookingForm } from '../booking-types';

export const bookingFormActionTypes = {
  UPDATE_BOOKING_FROM: 'UPDATE_BOOKING_FROM',
  UPDATE_BOOKING_DATE_INDEX: 'UPDATE_BOOKING_DATE_INDEX',
  RESET_BOOKING_FORM: 'RESET_BOOKING_FORM',
  RESET_BOOKING_DATE: 'RESET_BOOKING_DATE',
};

export const BookingFormActions = {
  updateBookingForm: (payload: Record<keyof BookingForm, any>) => ({
    type: bookingFormActionTypes.UPDATE_BOOKING_FROM,
    payload,
  }),
  resetBookingForm: () => ({
    type: bookingFormActionTypes.RESET_BOOKING_FORM,
  }),
  resetBookingDate: () => ({
    type: bookingFormActionTypes.RESET_BOOKING_DATE,
  }),
  updateBookingDateIndex: (payload: number) => ({
    type: bookingFormActionTypes.UPDATE_BOOKING_DATE_INDEX,
    payload,
  }),
};
