import { combineReducers } from 'redux';

import header from '../header/header-reducer';
import footer from '../footer/footer-reducer';
import stickyBookBanner from '../sticky-book-banner/sticky-book-banner-reducer';
import privacy from './privacy/privacy-reducer';
import home from './home/home-reducer';
import servicesContent from './services/services-reducer';
import serviceTypeContent from './services/service-type-reducer';
import contactUs from './contact-us/contact-us-reducer';
import impressum from './impressum/impressum-reducer';
import terms from './terms/terms-reducer';
import faq from './faq/faq-reducer';
import magazine from './magazine/magazine-reducer';
import registerInterest from './register-interest/reducer';
import consumerPromotion from './consumer-promotion/consumer-promotion-reducer';

export default combineReducers({
  header,
  footer,
  stickyBookBanner,
  privacy,
  home,
  servicesContent,
  serviceTypeContent,
  contactUs,
  impressum,
  terms,
  faq,
  magazine,
  registerInterest,
  consumerPromotion,
});
