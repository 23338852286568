import { connect } from 'react-redux';

import { AppState } from 'store/AppState';
import { LoadingActions } from 'modules/loading/loading-actions';
import { LoadingComponentProps } from 'modules/loading/types/base-types';
import Index from 'components/Pages/Loading/index';
import { getIsLoading } from 'modules/loading/loading-selectors';

const mapStateToProps = (state: AppState): Partial<LoadingComponentProps> => {
  return {
    isLoading: getIsLoading(state),
  };
};

export default connect(mapStateToProps, { fetchReferenceData: LoadingActions.fetchReferenceData })(
  Index
);
