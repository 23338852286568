import { call, put, select } from 'redux-saga/effects';
import { fetchBusinessFooter } from './sticky-book-banner-api';
import { actions } from './sticky-book-banner-actions';
import { SagaIterator } from 'redux-saga';
import { getIpDataForUserSelectedLocale } from '../app/app-selectors';

export function* fetchStickyBookBannerContent(): SagaIterator {
  try {
    const language = yield select(getIpDataForUserSelectedLocale);

    const contentResponse = yield call(fetchBusinessFooter, language);

    yield put(actions.fetchStickyBookBannerContentSuccess(contentResponse));
  } catch (e) {
    yield put(actions.fetchStickyBookBannerContentFailure(e));
  }
}
