import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { WORKSHOP_REGISTER_INTEREST_ENDPOINT } from '../../../constants/urls';
import { SagaIterator } from 'redux-saga';

export function registerInterestApi(interest): Promise<any> {
  return axios.post(`${WORKSHOP_REGISTER_INTEREST_ENDPOINT}`, interest);
}

export function* registerInterest(): SagaIterator {
  yield takeEvery(actions.REGISTER_INTEREST, function* (interestData: any) {
    const { payload } = interestData;

    try {
      yield call(registerInterestApi, payload.interest);
      payload.formActions.resetForm();

      // TODO: need to define success, e.g. status 200. For now will leave it
      yield put(actions.registerInterestSuccess());
    } catch (error) {
      payload.formActions.setSubmitting(false);
      yield put(actions.registerInterestFailure(error.response.data));
    }
  });
}

export function* registerInterestSaga(): SagaIterator {
  yield all([fork(registerInterest)]);
}
