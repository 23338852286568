import produce from 'immer';
import {
  FetchCarTypesSuccessfulAction,
  FetchFacilitiesSuccessfulAction,
  FetchManufacturersSuccessfulAction,
  FetchServicesSuccessfulAction,
  FetchTranslationsSuccessAction,
} from './types/loading-action-types';
import { ReferenceDataState } from 'modules/loading/types/ReferenceDataState';
import { LoadingActionTypes } from 'modules/loading/loading-actions';

const initialState: ReferenceDataState = {
  facilities: [],
  services: [],
  manufacturers: [],
  isLoading: true,
  translations: [],
  translationsLoaded: false,
  carTypes: [],
};

export default (
  state = initialState,
  action: FetchFacilitiesSuccessfulAction &
    FetchServicesSuccessfulAction &
    FetchManufacturersSuccessfulAction &
    FetchTranslationsSuccessAction &
    FetchCarTypesSuccessfulAction
): ReferenceDataState =>
  produce(state, (draft) => {
    switch (action.type) {
      case LoadingActionTypes.FETCH_REFERENCE_DATA: {
        draft.isLoading = true;
        return;
      }
      case LoadingActionTypes.FETCH_SERVICES_SUCCESS: {
        const { services } = action;
        draft.services = services;
        return;
      }
      case LoadingActionTypes.FETCH_FACILITIES_SUCCESS: {
        const { facilities } = action;
        draft.facilities = facilities;
        return;
      }
      case LoadingActionTypes.FETCH_MANUFACTURERS_SUCCESS: {
        const { manufacturers } = action;
        draft.manufacturers = manufacturers;
        return;
      }
      case LoadingActionTypes.FETCH_CAR_TYPES_SUCCESS: {
        const { carTypes } = action;
        draft.carTypes = carTypes;
        return;
      }
      case LoadingActionTypes.FETCH_TRANSLATIONS_SUCCESS: {
        const { translations } = action;
        draft.translations = translations;
        draft.translationsLoaded = true;
        return;
      }
      default:
        return state;
    }
  });
