import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  FetchMagazineArticleAction,
  MagazineActions,
  MagazineActionTypes,
} from './magazine-actions';
import { fetchMagazineArticle, fetchMagazineHub } from './magazine-api';
import { isEmpty } from 'lodash';
import { AppState } from 'store/AppState';
import { SagaIterator } from 'redux-saga';
import { getIpDataForUserSelectedLocale } from '../../app/app-selectors';

export function* fetchMagazineHubSaga(): SagaIterator {
  try {
    const state = (yield select()) as AppState;
    const magazineHubState = state.brochureWare.magazine;
    if (!isEmpty(magazineHubState.articleCards.content)) return;
    const language = yield select(getIpDataForUserSelectedLocale);

    if (language) {
      const content = yield call(fetchMagazineHub, language);
      yield put(MagazineActions.fetchMagazineHubSuccess(content));
    }
  } catch (e) {
    yield put(MagazineActions.fetchMagazineHubFailure(e));
  }
}

export function* fetchMagazineArticleSaga(): SagaIterator {
  yield takeEvery(
    MagazineActionTypes.FETCH_MAGAZINE_ARTICLE,
    function* ({ articleName }: FetchMagazineArticleAction) {
      try {
        const language = yield select(getIpDataForUserSelectedLocale);

        if (language) {
          const content = yield call(fetchMagazineArticle, language, articleName);
          yield put(MagazineActions.fetchMagazineArticleSuccess(content));
        }
      } catch (e) {
        yield put(MagazineActions.fetchMagazineArticleFailure(e));
      }
    }
  );
}
