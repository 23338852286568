export const ContactUsActionTypes = {
  CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
  CONTACT_US_FAILURE: 'CONTACT_US_FAILURE',
};

export const ContactUsActions = {
  fetchContactUsSuccess: (content) => ({
    type: ContactUsActionTypes.CONTACT_US_SUCCESS,
    payload: { content },
  }),
  fetchContactUsFailure: (error) => ({
    type: ContactUsActionTypes.CONTACT_US_FAILURE,
    payload: { error },
  }),
};
