// load raw env vars
export const APP_ID = process.env.CARAMA_PUBLIC_APP_ID || 'carama';
export const PUBLIC_URL = process.env.PUBLIC_URL || '';
export const WORKSHOP_SVC_URL = process.env.CARAMA_PUBLIC_WORKSHOP_SVC_URL;
export const USER_SVC_URL = process.env.CARAMA_PUBLIC_USER_SVC_URL;
export const AEM_BASE_URL = process.env.CARAMA_PUBLIC_AEM_BASE_URL;
export const BOOKING_SVC_URL = process.env.CARAMA_PUBLIC_BOOKING_SVC_URL;
export const ADMIN_SVC_URL = process.env.CARAMA_PUBLIC_ADMIN_SVC_URL;
export const PRICING_SVC_URL = process.env.CARAMA_PUBLIC_PRICING_SVC_URL;
export const PRICING_SVC_URL_CASTROL_CONNECT =
  process.env.CARAMA_PUBLIC_CASTROL_CONNECT_PRICING_SVC_URL;
export const APPINSIGHTS_CONNECTION_STRING =
  process.env.CARAMA_PUBLIC_APPINSIGHTS_CONNECTION_STRING;
export const AAD_TENANT_ID = process.env.CARAMA_PUBLIC_AAD_TENANT_ID;
export const AAD_CLIENT_ID = process.env.CARAMA_PUBLIC_AAD_CLIENT_ID;
export const USE_AD_AUTHENTICATION = process.env.CARAMA_PUBLIC_USE_AD_AUTHENTICATION;
export const ENVIRONMENT_NAME = process.env.CARAMA_PUBLIC_ENVIRONMENT_NAME || 'local';
export const RELEASE_NAME = process.env.CARAMA_PUBLIC_RELEASE_NAME || 'locally-run';
export const ACTIVATE_HOLDING_PAGE = process.env.CARAMA_PUBLIC_ACTIVATE_HOLDING_PAGE;
export const GOOGLE_API_KEY = process.env.CARAMA_PUBLIC_GOOGLE_API_KEY;
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.CARAMA_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY;
export const GOOGLE_RECAPTCHA_SITE_KEY_V3 = process.env.CARAMA_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY_V3;
export const CARAMA_PUBLIC_FORCE_WORKSHOP_REVIEWS =
  process.env.CARAMA_PUBLIC_FORCE_WORKSHOP_REVIEWS;
export const CARAMA_PUBLIC_SUPPRESS_WORKSHOP_REVIEWS =
  process.env.CARAMA_PUBLIC_SUPPRESS_WORKSHOP_REVIEWS;
export const CARAMA_CONTENTFUL_ACCESS_TOKEN = process.env.CARAMA_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
export const CARAMA_CONTENTFUL_SPACE_ID = process.env.CARAMA_PUBLIC_CONTENTFUL_SPACE_ID;
export const CARAMA_CONTENTFUL_ENV = process.env.CARAMA_PUBLIC_CONTENTFUL_ENV;

export const CARAMA_PUBLIC_SF_COMMUNITY_URL = process.env.CARAMA_PUBLIC_SF_COMMUNITY_URL;
export const CARAMA_PUBLIC_SF_REGISTER_URL = process.env.CARAMA_PUBLIC_SF_REGISTER_URL;
export const CARAMA_PUBLIC_SF_EXPERIENCE_ID = process.env.CARAMA_PUBLIC_SF_EXP_ID;
export const CARAMA_PUBLIC_SF_CLIENT_ID = process.env.CARAMA_PUBLIC_SF_CLIENT_ID;
export const CARAMA_PUBLIC_SF_REDIRECT_CALLBACK = process.env.CARAMA_PUBLIC_SF_REDIRECT_CALLBACK;
export const AVAILABLE_COUNTRIES = process.env.CARAMA_PUBLIC_AVAILABLE_COUNTRIES || [];
export const AVAILABLE_LOCALES = process.env.CARAMA_PUBLIC_AVAILABLE_LOCALES || [];
export const LAUNCHDARKLY_CLIENT_ID = process.env.CARAMA_PUBLIC_LAUNCHDARKLY_CLIENT_ID;
export const ENABLE_REDUX_DEVTOOLS = process.env.CARAMA_PUBLIC_ENABLE_REDUX_DEVTOOLS;

export const CIP_END_SESSION_URL = `${process.env.CARAMA_PUBLIC_CIP_COMMUNITY_URL}am/oauth2/connect/endSession`;
export const CIP_CLIENT_ID = `${process.env.CARAMA_PUBLIC_CIP_B2C_CLIENT_ID}`;
export const CIP_REVOKE_TOKEN = `${process.env.CARAMA_PUBLIC_CIP_COMMUNITY_URL}am/oauth2/token/revoke`;
