function getCanonicalURL(url: string): string {
  const regexPattern = /\w+:\/\/(?<site>.+)/;

  let canonicalURL;
  canonicalURL = url.endsWith('/') ? url.slice(0, -1) : url;
  const found = canonicalURL.match(regexPattern);
  const wwwPrependedSite = found.groups.site.startsWith('www')
    ? found.groups.site
    : `www.${found.groups.site}`;
  canonicalURL = `https://${wwwPrependedSite}`;

  return canonicalURL;
}

export { getCanonicalURL };
