import { bookingActionTypes } from '../actions/booking-actions';
import { BookingStatus } from '../booking-types';

export const initialState: BookingStatus = {
  success: null,
  errors: null,
  booking: null,
  isSubmitting: null,
  isCancelError: null,
  isNewBooking: false,
};

const status = (state = initialState, action: any): BookingStatus => {
  switch (action.type) {
    case bookingActionTypes.FETCH_BOOKING: {
      return { ...state, booking: null, success: false, isSubmitting: true, isNewBooking: false };
    }

    case bookingActionTypes.SUBMIT_BOOKING: {
      return { ...state, success: false, isSubmitting: true, isNewBooking: true };
    }

    case bookingActionTypes.SUBMIT_BOOKING_ISNEWBOOKING: {
      return { ...state, isNewBooking: action.payload };
    }

    case bookingActionTypes.FETCH_BOOKING_SUCCESS:
    case bookingActionTypes.SUBMIT_BOOKING_SUCCESS: {
      return { ...state, success: true, booking: action.payload, isSubmitting: false };
    }

    case bookingActionTypes.FETCH_BOOKING_ERROR:
    case bookingActionTypes.SUBMIT_BOOKING_FAILURE: {
      return { ...state, success: false, errors: action.error, isSubmitting: false };
    }

    case bookingActionTypes.CANCEL_BOOKING: {
      return { ...state, isCancelError: false };
    }

    case bookingActionTypes.CANCEL_BOOKING_ERROR: {
      return { ...state, isCancelError: true };
    }

    case bookingActionTypes.RESET_BOOKING: {
      return initialState;
    }
    default:
      return state;
  }
};

export default status;
