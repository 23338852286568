import { UpdateConsumerRequest } from 'common-types/consumer';

export const consumerActionTypes = {
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
  UPDATE_CAR: 'UPDATE_CAR',
  UPDATE_CAR_SUCCESS: 'UPDATE_CAR_SUCCESS',
  UPDATE_CAR_FAILURE: 'UPDATE_CAR_FAILURE',

  updateProfile: (updateConsumerRequest: UpdateConsumerRequest, props) => {
    return {
      type: consumerActionTypes.UPDATE_PROFILE,
      payload: { updateConsumerRequest, props },
    };
  },

  updateCar: (updateConsumerRequest: UpdateConsumerRequest, props) => {
    return {
      type: consumerActionTypes.UPDATE_CAR,
      payload: { updateConsumerRequest, props },
    };
  },
};
