import devConfigureStore from './configureStore.dev';
import prodConfigureStore from './configureStore.prod';
import { saveState } from './localStorage';
import { LAUNCHDARKLY_CLIENT_ID } from '../constants/env';
import launchDarklyHelper from 'modules/feature-flag/launch-darkly-helper';

const store = process.env.NODE_ENV === 'production' ? prodConfigureStore() : devConfigureStore();

launchDarklyHelper.initialise(LAUNCHDARKLY_CLIENT_ID, store.dispatch);

store.subscribe(() => {
  const { search, workshops, booking } = store.getState();
  saveState({
    search,
    workshops,
    booking,
  });
});

export default store;
