import AsyncComponent from 'helpers/AsyncComponent';
import { getLocalisedRoute } from 'constants/urls';

const routes = [
  {
    path: `${getLocalisedRoute('workshopResult')}`,
    restricted: false,
    exact: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Workshop/WorkshopContainer')
    ),
  },
  {
    path: `${getLocalisedRoute('workshop')}`,
    restricted: false,
    exact: true,
    hideBackButtons: true,
    ViewComponent: AsyncComponent(
      () => import('../../components/Pages/Workshop/WorkshopContainer')
    ),
  },
];
export default routes;
