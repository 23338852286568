import { MagazineState } from 'modules/brochureware/brochureware-types';
import { MagazineActionTypes } from './magazine-actions';

const initialState = {
  articleCards: {
    content: [],
    error: {},
  },
  selectedArticle: {
    content: {},
    error: {},
  },
};

export default (state = initialState, action: any): MagazineState => {
  switch (action.type) {
    case MagazineActionTypes.FETCH_MAGAZINE_HUB_SUCCESS: {
      return {
        ...state,
        articleCards: { ...state.articleCards, content: action.payload.content },
      };
    }
    case MagazineActionTypes.FETCH_MAGAZINE_HUB_FAILURE: {
      return {
        ...state,
        articleCards: { ...state.articleCards, error: action.payload.error },
      };
    }

    case MagazineActionTypes.FETCH_MAGAZINE_ARTICLE: {
      return {
        ...state,
        selectedArticle: { content: {}, error: {} },
      };
    }

    case MagazineActionTypes.FETCH_MAGAZINE_ARTICLE_SUCCESS: {
      return {
        ...state,
        selectedArticle: { ...state.selectedArticle, content: action.payload.content },
      };
    }
    case MagazineActionTypes.FETCH_MAGAZINE_ARTICLE_FAILURE: {
      return {
        ...state,
        selectedArticle: { ...state.selectedArticle, error: action.payload.error },
      };
    }

    default:
      return state;
  }
};
