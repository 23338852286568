import { call, put, select } from 'redux-saga/effects';
import { TermsActions } from './terms-actions';
import { fetchTerms } from './terms-api';
import { isEmpty } from 'lodash';
import { AppState } from 'store/AppState';
import { SagaIterator } from 'redux-saga';
import { getIpDataForUserSelectedLocale } from '../../app/app-selectors';

export function* fetchTermsSaga(): SagaIterator {
  try {
    const state = (yield select()) as AppState;
    const termsState = state.brochureWare.terms;
    if (!isEmpty(termsState.content)) return;
    const language = yield select(getIpDataForUserSelectedLocale);

    if (language) {
      const content = yield call(fetchTerms, language);
      yield put(TermsActions.fetchTermsSuccess(content));
    }
  } catch (e) {
    yield put(TermsActions.fetchTermsFailure(e));
  }
}
