import styled, { createGlobalStyle } from 'styled-components';
import MuseoSans from 'assets/fonts/MuseoSansRounded-500.otf';
import MuseoSansLight from 'assets/fonts/MuseoSansRounded-300.otf';
import { media, theme } from 'styles/theme';

export const VideoWrapper = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
`;

export const FulLWidthVideo = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const FulLWidthImage = styled.img`
  max-width: 100%;
`;

export const MobileNoMargin = styled.div`
  ${media.desktop`
    margin-left: -1rem;
    margin-right: -1rem;
  `}
  ${media.phone`
    margin-left: -1rem;
    margin-right: -1rem;
  `};
`;

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: MuseoSans;
        src: url(${MuseoSans});
    }
      
    @font-face {
        font-family: MuseoSansLight;
        src: url(${MuseoSansLight});
    }
    
    html {
      box-sizing: border-box;
    }
    
    *, *::before, *::after {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        font-family: MuseoSans,MuseoSansLight,serif;
    }
    
    p {
        font-size: 1rem;
        font-family: MuseoSansLight,serif;
        line-height: 1.5rem;
        color: ${theme.palette.text.primary};
        margin-block-start: 0;
        margin-block-end: 1rem;
    }
    
    a {
      text-decoration:none;
      font-family: MuseoSansLight,serif;
      color: ${theme.palette.primary.main};
      text-decoration-color: ${theme.palette.primary.main};
      &:visited {
        color: ${theme.palette.primary.main};
         text-decoration-color: ${theme.palette.primary.main};
      }
    }
    .cookie-page-disable {
      .page-wrapper {
        > * {
          opacity: 0.2;
          pointer-events: none;
        }
        .cookie-banner {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
    .enable-cookie-preferences {
      .page-wrapper {
        > * {
          opacity: 0.2;
          pointer-events: none;
        }
        .cookie-banner,
        .body-page-wrapper {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
    .cookie-banner-fixed {
      position: fixed;
      z-index: 99;
      width: 100%;
      top: 0;
    }
`;
