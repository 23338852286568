import produce from 'immer';
import { featureFlagActionTypes } from './feature-flag-actions';
import { featureFlagOverrides } from './feature-flag-overrides';
import { FeatureFlagsState } from './feature-flag-types';

const initialState: FeatureFlagsState = {
  ld: {},
  overrides: featureFlagOverrides,
};

export default (state = initialState, action: any): FeatureFlagsState =>
  produce(state, (draft) => {
    switch (action.type) {
      case featureFlagActionTypes.FEATURE_FLAGS:
        draft.ld = {
          ...draft.ld,
          ...action.payload,
        };
        return;

      case featureFlagActionTypes.FEATURE_FLAGS_OVERRIDDEN:
        draft.overrides = {
          ...draft.overrides,
          ...action.payload,
        };
        return;

      default:
        return state;
    }
  });
