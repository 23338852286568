// @ts-nocheck
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../Accordion';
import { Colors } from '@smp/carama-ui-components';

const { DARK_GREEN, PALE_GREY } = Colors;

export const getCustomSummary = (enhancementsToConsumerHeaderMenu?: boolean): any =>
  ExpansionPanelSummary({
    root: {
      backgroundColor: 'transparent !important',
      border: '0 !important',
      borderTopLeftRadius: '10px !important',
      borderTopRightRadius: '10px !important',
      padding: '0 !important',
      borderBottom: `solid 1px ${PALE_GREY} !important`,
      '&$expanded': {
        minHeight: '56px !important',
        marginTop: '0 !important',
        padding: '0 !important',
      },
    },
    content: {
      margin: enhancementsToConsumerHeaderMenu ? '20px 0 !important' : '12px 0',
    },
    expanded: {},
  });

export const getCustomPanelDetails = (): any =>
  ExpansionPanelDetails({
    root: {
      marginTop: 12,
      paddingLeft: 10,
      paddingRight: 10,
      flexDirection: 'column',
      borderTop: 0,
    },
  });

export const getCustomPanel = (): any =>
  ExpansionPanel({
    root: {
      marginTop: '0 !important',
      marginLeft: '16px !important',
      marginRight: '16px !important',
      boxShadow: 'none !important',
      '&:first-child': {
        marginTop: '0 !important',
      },
      '&:not(:last-child)': {
        borderBottom: '0 !important',
        margin: '0 16px !important',
      },
      '&:before': {
        display: 'none',
      },
    },
  });

export const getCustomServiceSummary = (): any =>
  ExpansionPanelSummary({
    root: {
      backgroundColor: 'transparent !important',
      border: '0 !important',
      borderTopLeftRadius: '10px !important',
      borderTopRightRadius: '10px !important',
      padding: '0 !important',
      borderBottom: `solid 1px ${PALE_GREY} !important`,
      marginTop: '0!important',
      marginLeft: '16px !important',
      marginRight: '16px !important',
      minHeight: '65px !important',
      boxShadow: 'none !important',
      '& span': {
        color: DARK_GREEN,
        fontFamily: 'MuseoSans,serif',
      },
    },
  });
