import { SagaIterator } from 'redux-saga';
import isEmpty from 'lodash/isEmpty';
import { all, put, fork, takeEvery, call, select } from 'redux-saga/effects';
import {
  bookingActionTypes,
  BookingActions,
  BookingAction,
} from 'modules/booking/actions/booking-actions';
import { apiBookWorkshop, cancelBookingApi, fetchBookingApi } from 'modules/booking/booking-api';
import { getSelectedServiceIds } from '../search/search-results-selectors';
import { getBookingForm, getBookingStatus } from './booking-form-selector';
import { getActiveWorkshopId } from 'modules/workshop/workshop-selectors';
import { SearchActions } from '../search/search-actions';
import { BOOKING, getLocalisedRoute } from '../../constants/urls';
import { LOCATION_CHANGE } from 'react-router-redux';
import history from '../../helpers/history';
import { getUser } from 'modules/auth/auth-selectors';
import authActionTypes from 'modules/auth/auth-actions';

declare const window: any;

export function* watcherBookingWorkshop(): SagaIterator {
  yield takeEvery(bookingActionTypes.SUBMIT_BOOKING, function* () {
    try {
      const services = yield select(getSelectedServiceIds);
      const workshopId = yield select(getActiveWorkshopId);
      const booking = yield select(getBookingForm);
      const user = yield select(getUser);
      const response = yield call(apiBookWorkshop, workshopId, booking, services, user);

      if (response && isEmpty(response?.errors)) {
        if (user) {
          yield put({
            type: authActionTypes.USER_PROFILE_UPDATE_SUCCESS,
            payload: { ...user, marketingDoubleOptIn: booking.marketingOptInAgreed },
          });
        }
        history.push(`${getLocalisedRoute('booking')}?id=${response?.id}`);
        yield put(BookingActions.submitBookingSuccess(response));
      } else if (response?.errors) {
        history.push(getLocalisedRoute('bookingFailed'));
        yield put(BookingActions.submitBookingFailure(response?.errors.message));
      }
    } catch (e) {
      history.push(getLocalisedRoute('bookingFailed'));
      yield put(BookingActions.submitBookingFailure(e.message));
    }
  });
}

export function* clearBookingStateWatcher(): SagaIterator {
  yield takeEvery(LOCATION_CHANGE, function* () {
    try {
      const status = yield select(getBookingStatus);
      if (
        status.success &&
        !window.location.pathname.includes(BOOKING) &&
        !window.location.pathname.includes(getLocalisedRoute('book'))
      ) {
        const user = yield select(getUser);
        yield put(BookingActions.resetBooking(user));
        yield put(SearchActions.resetSearch());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  });
}

export function* watcherGetBooking(): SagaIterator {
  yield takeEvery(bookingActionTypes.FETCH_BOOKING, function* (action: BookingAction) {
    try {
      const { bookingId } = action;
      const response = yield call(fetchBookingApi, bookingId);

      if (response && isEmpty(response?.errors)) {
        yield put(BookingActions.fetchBookingSuccess(response));
      } else if (response?.errors) {
        yield put(BookingActions.fetchBookingError(response?.errors.message));
      }
    } catch (e) {
      yield put(BookingActions.fetchBookingError(e.message));
    }
  });
}

export function* watcherCancelBooking(): SagaIterator {
  yield takeEvery(bookingActionTypes.CANCEL_BOOKING, function* (action: BookingAction) {
    try {
      const { bookingId } = action;
      const response = yield call(cancelBookingApi, bookingId);

      if (isEmpty(response?.errors)) {
        yield put(BookingActions.cancelBookingSuccess());
        yield put(BookingActions.fetchBooking(bookingId));
      } else if (response?.errors) {
        yield put(BookingActions.cancelBookingError(response?.errors.message));
      }
    } catch (e) {
      yield put(BookingActions.cancelBookingError(e.message));
    }
  });
}

export function* watcherBookingSuccess(): SagaIterator {
  yield takeEvery(bookingActionTypes.SUBMIT_BOOKING_SUCCESS, function* () {
    const status = yield select(getBookingStatus);
    const brand = status.booking.car.make;
    const brandName = !isEmpty(brand) ? brand : 'not selected';

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'new_consumer_booking',
      outcome: 'successful_booking',
      brand: brandName,
      success: status.success,
    });
  });
}

export function* watcherBookingFailure(): SagaIterator {
  yield takeEvery(bookingActionTypes.SUBMIT_BOOKING_FAILURE, function* () {
    const status = yield select(getBookingStatus);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'new_consumer_booking',
      outcome: 'unsuccessful_booking',
      success: status.success,
      reason: status.errors,
    });
  });
}

export default function* BookingSaga(): SagaIterator {
  yield all([
    fork(watcherBookingWorkshop),
    fork(clearBookingStateWatcher),
    fork(watcherGetBooking),
    fork(watcherCancelBooking),
    fork(watcherBookingSuccess),
    fork(watcherBookingFailure),
  ]);
}
