import { createSelector } from 'reselect';
import { AppState } from 'store/AppState';
import { Selector } from 'react-redux';
import { Facility, Manufacturer, Service } from 'modules/loading/types/base-types';
import { getAuth } from 'modules/auth/auth-selectors';

export const servicesSelector = (state: AppState): Array<Service> => state.referenceData.services;

export const facilitiesSelector = (state: AppState): Array<Facility> =>
  state.referenceData.facilities;

export const translationsLoadedSelector = (state: AppState): boolean =>
  state.referenceData.translationsLoaded;

export const manufacturersSelector = (state: AppState): Array<Manufacturer> =>
  state.referenceData.manufacturers;

export const getTranslations = (state: AppState): Record<string, string> => {
  if (!state.referenceData?.translations?.length) return {};

  return state.referenceData.translations.reduce((accumulator, translation) => {
    return { ...accumulator, [translation.key]: translation.value };
  }, {});
};

export const getIsLoading: Selector<AppState, boolean> = createSelector(
  facilitiesSelector,
  manufacturersSelector,
  translationsLoadedSelector,
  getAuth,
  (facilities, manufacturers, translationsLoadComplete, auth) => {
    return (
      facilities.length === 0 ||
      manufacturers.length === 0 ||
      !translationsLoadComplete ||
      (auth.isAuthenticated && !auth.user?.idToken)
    );
  }
);
