import { SagaIterator } from 'redux-saga';
import { all, put, fork, takeEvery, call, select, take } from 'redux-saga/effects';
import { getPlaceIdsFromWorkshops } from '../../helpers';
import { SearchActions, SearchActionTypes, SearchAction } from 'modules/search/search-actions';
import { apiStartSearch, getGoogleReviews, resolveOverLimitQuery } from 'modules/search/search-api';
import {
  getSearchCoordinates,
  //getSelectedServiceIds,
  getSearchDates,
  getSearchResults,
  getSearchVehicle,
} from './search-results-selectors';
import { addDays } from 'date-fns';
import { DEFAULT_SEARCH_LOCATION, SEARCH_DAYS_IN_FUTURE } from 'constants/general';
import { getCars } from 'modules/auth/auth-selectors';
import { mapReviews } from 'helpers/google-reviews';
import { WorkshopActionTypes } from 'modules/workshop/workshop-actions';
import { getWorkshop } from 'modules/workshop/workshop-selectors';

export function* watcherStartSearch(): SagaIterator {
  yield takeEvery(SearchActionTypes.START_SEARCH, function* (action: SearchAction) {
    try {
      let coords = yield select(getSearchCoordinates);
      let dates = yield select(getSearchDates);
      //const services = yield select(getSelectedServiceIds);
      let vehicle = yield select(getSearchVehicle);
      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      dates = {
        start: today,
        end: addDays(today, SEARCH_DAYS_IN_FUTURE),
      };
      const { sortBy, jobs, specializations, manufacturer } = action;

      if (!coords.lat && !coords.lng) {
        coords = DEFAULT_SEARCH_LOCATION;
        yield put(SearchActions.changeSearchCoordinates(coords));
      }

      if (!vehicle.brandId) {
        const userCars = yield select(getCars);

        if (userCars?.length > 0) {
          vehicle = {
            brandId: userCars[0].model,
            brandName: userCars[0].model,
            vehicleRegistration: userCars[0].registration,
          };
          yield put(SearchActions.setSearchVehicle(vehicle));
        }
      }

      const workshops = yield call(
        apiStartSearch,
        coords,
        jobs,
        dates,
        manufacturer,
        sortBy,
        specializations
      );
      if (workshops) {
        yield put(SearchActions.searchSuccessful(workshops));
      } else {
        yield put(SearchActions.searchFailure(new Error('No results found')));
      }
    } catch (e) {
      yield put(SearchActions.searchFailure(e));
    }
  });
}

export function* watcherGetWorkshopReviews(): SagaIterator {
  while (true) {
    yield take(SearchActionTypes.GET_WORKSHOP_REVIEWS);
    yield take(SearchActionTypes.SEARCH_SUCCESSFUL);
    const searchResults = yield select(getSearchResults);
    const placeIds = getPlaceIdsFromWorkshops(searchResults);

    //hacky way of handling the OVER_QUERY_LIMIT when getting reviews
    let isError, reviewsAndErrors;
    let reviewsResult = yield call(getGoogleReviews, placeIds);
    let reviewsMap = mapReviews(reviewsResult.reviews, searchResults);
    yield put(SearchActions.getWorkshopReviewsSuccess(reviewsMap));
    isError = reviewsResult.isError;
    reviewsAndErrors = reviewsResult.reviewsAndErrors;
    let count = 5;
    while (isError && count-- > 0) {
      reviewsResult = yield call(resolveOverLimitQuery, reviewsAndErrors);
      reviewsMap = mapReviews(reviewsResult.reviews, searchResults);
      yield put(SearchActions.getWorkshopReviewsSuccess(reviewsMap));
      isError = reviewsResult.isError;
      reviewsAndErrors = reviewsResult.reviewsAndErrors;
    }
  }
}

export function* watcherGetSelectedWorkshopReviews(): SagaIterator {
  yield takeEvery(SearchActionTypes.GET_SELECTED_WORKSHOP_REVIEWS, function* () {
    yield take(WorkshopActionTypes.FETCH_WORKSHOP_SUCCESS);
    const workshop = yield select(getWorkshop);

    const reviewsResult = yield call(getGoogleReviews, [workshop.address.placeId]);

    yield put(
      SearchActions.getSelectedWorkshopReviewsSuccess(workshop.id, reviewsResult.reviews[0])
    );
  });
}

export default function* SearchSaga(): SagaIterator {
  yield all([
    fork(watcherStartSearch),
    fork(watcherGetWorkshopReviews),
    fork(watcherGetSelectedWorkshopReviews),
  ]);
}
